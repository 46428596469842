import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Modal,
  TextField,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { Button } from './common/Button';
import { Note } from './common/Note';

import StudentResponse from './StudentResponse';
import IdeaSpace from './IdeaSpace';
import store from '../../store';
import { FullClassContext } from '../../reducers/fullClassContext';
import socket from '../../utils/socket';
import { pushMapping } from './constants/PushMapping';
import { SideBar } from './common/SideBar';
import NoteManager from '../notebook/elements/Notes/NoteManager';
import StudentResponseForm from './StudentResponseForm';
import { Backpack } from './common/Backpack';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  box: {
    boxSizing: 'border-box',
    background: '#fff',
    border: '10px solid #999',
    borderRadius: '3px',
    width: '180px',
    height: '180px',
    margin: '10px',
    padding: '10px',
    float: 'left'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '700px',
    height: '400px',
    padding: '45px',
    backgroundColor: '#ffffff',
    border: '27px solid #e3a920',
    borderRadius: '94px',
    boxShadow: '0px 3.23px 3.23px #00000040, 0px 3.23px 3.23px #00000040'
  },
  textField: {
    width: '400px',
    height: '155px',
  },
  gridFixedBottom: {
    position: 'fixed',
    bottom: '20px',
  },
  dragHandle: {
    position: 'absolute',
    left: '36%',
    bottom:'1%',
    width: '69.819px',
    height: '9.987px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    opacity: 0.5,
  },
  filterContainer: {
    left: '1100px',
    top: '32px',
    height: '43px',
    width: '130px',
    // borderRadius: '40.43px',
    position: 'fixed',
    cursor: 'pointer',
    border: 'none',
    // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    // backgroundColor: '#E3A920',
    letterSpacing: 0,
    lineHeight: 'normal',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  filterSelect: {
    height: '43px',
    width: '130px',
    borderRadius: '40.43px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    backgroundColor: '#E3A920'
  }
}));

const StyledFullDiscussionBackground = styled.div`
  height: 100%;
  position: relative;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  & .blob {
    position: absolute;
    top: 0;
    transform: translate(-125px, 0px);
  }

  & .blob-right {
    right: 0;
    position: absolute;
    top: 0;
    transform: translate(100px, 0px);
  }

  & .group-discussion {
    color: #000;
    font-family: "Jua", Helvetica;
    font-size: 32.3px;
    font-weight: 400;
    left: 100px;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    top: 30px;
    margin: 0 auto;
    width: 716px;
    z-index: 1;
  }
`;

const room = "/discussion/full";

const colorList = ['#EF9971', '#EFDA71', '#71EFB6', '#71C7EF', '#FFB8D2'];

export default function FullDiscussion({ match }) {
  const discussionId = match.params.id;
  const [discussion, setDiscussion] = useState({});
  const [classInfo, setClassInfo] = useState([]);
  const [navigation, setNavigation] = useState('');
  const [loadingDiscussion, setLoadingDiscussion] = useState(true);
  const [loadingNavigation, setLoadingNavigation] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(true);
  const [loadingIdeas, setLoadingIdeas] = useState(true);
  const [loadingClassInfo, setLoadingClassInfo] = useState(true);
  const [, dispatch] = useContext(FullClassContext);
  const userAccess = store.getState().auth.user.access;
  const userId = store.getState().auth.user.id;
  const history = useHistory();


  useEffect(() => {
    // 403 and refuse socket connection
    if (userId === undefined) {
      history.push(`/login`);
    } else {
      socket.connect();
    }

    (async () => {
      try {
        // loading student info
        if (userAccess === 1) {
          try {
            axios.post("/api/navigation/getnavigation",
              { studentId: userId }
            ).then((res) => {
              setNavigation(res.data[0]);
              setLoadingNavigation(false);
            });
          } catch(err) {
            dispatch({
              type: 'SET_ERROR',
              payload: err
            });
          }
        } else {
          setLoadingNavigation(false);
        }
        const res1 = await axios.get('/api/discussion', {
          params: { data: discussionId }
        });
        setDiscussion(res1.data);
        setLoadingDiscussion(false);
        const res2 = await axios.get('/api/notePositions/lastPositions', {
          params: { discussionId }
        });
        dispatch({
          type: 'SET_NOTES',
          payload: res2.data
        });
        setLoadingNotes(false);
        const res3 = await axios.get('/api/ideaPositions/lastPositions', {
          params: { discussionId }
        });
        dispatch({
          type: 'SET_IDEAS',
          payload: res3.data
        });
        setLoadingIdeas(false);
        const res4 = await axios.get('/api/analytics/getroster2', {
          params: { classId: res1.data.classId }
        });
        setLoadingClassInfo(false);
        setClassInfo(res4.data);
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          payload: err.response.data
        });
      }
    })();

    // eslint-disable-next-line
  }, []);
  if (loadingDiscussion || loadingNotes || loadingIdeas || loadingClassInfo || loadingNavigation) return (<div><p>Please wait...</p></div>)
  return (
    <>
      <FullDiscussionComponent
        key={discussionId}
        discussionId={discussionId}
        discussion={discussion}
        classInfo={classInfo}
        navigation={navigation}
      />
    </>
  )
}


function FullDiscussionComponent({ discussionId, discussion, classInfo, navigation }) {
  const classes = useStyles();

  const [tags, setTags] = useState([]);
  const [filterTag, setFilterTag] = useState('');
  const [name, setName] = useState('');

  const [open, setOpen] = useState(false);
  const [openBackpack, setOpenBackpack] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal student response state
  const [openResponse, setOpenResponse] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [noteOriginId, setNoteOriginId] = useState(null);
  const [newTag, setNewTag] = useState('');

  const history = useHistory();

  const [{ ideas, notes }, dispatch] = useContext(FullClassContext);

  const userId = store.getState().auth.user.id;
  const isStudentUser = store.getState().auth.user.access === 1;

  // NoteManager state and functions for students
  const [showNotes, setShowNotes] = useState(false);
  const closeNotes = () => setShowNotes(false);

  const handleFilter = e => {
    e.preventDefault();
    let filter = e.target.value;
    if (e.target.value === 'all') {
      filter = '';
    }
    setFilterTag(filter);
  };

  const handleNewIdea = async() => {
    try {
      const res1 = await axios.post('/api/ideas/save', {
        content: name
      });
      const idea = res1.data;
      const res2 = await axios.post('/api/ideaPositions/save', {
        userId,
        ideaId: idea._id,
        discussionId,
        content: idea.content,
        width: '289px',
        height: '400px'
      });
      dispatch({
        type: 'ADD_IDEA',
        payload: res2.data
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: err
      });
    }

    handleClose();
    setName('');
  };

  const handleFinish = async(e) => {
    e.preventDefault();
    let tmpNotes = [];
    for (let note of notes) {
      if (note.ideaId) {
        // prepping for notebook
        let dateNow = new Date();
        let noteIdea = ideas.find(obj => obj._id === note.ideaId);
        tmpNotes.push({
          title: noteIdea?.content,
          body: note.content,
          background: colorList[Math.floor(Math.random() * colorList.length)],
          date: dateNow.getMonth() + 1 + "/" + dateNow.getDate() + "/" + dateNow.getFullYear(),
          originId: note.noteId
        });
      }
      try {
        await axios.put('/api/notes/ideaId/update', {
          noteId: note.noteId,
          ideaId: note.ideaId
        });
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          error: err.response.data
        });
      }
    }

    // pushing bulk notes
    try {
      if (classInfo.students && classInfo.students.length && tmpNotes && tmpNotes.length) {
        for (let classUserId of classInfo.students) {
          await axios.post('/api/answers/savebulknotes', {
            id: classUserId,
            labKey: `${discussion.name} (full class)`,
            newNotes: tmpNotes,
            navId: classInfo.lessons
          });
        }
      }
    } catch(err) {
      dispatch({
        type: 'SET_ERROR',
        error: err.response.data
      })
    } finally {
      setTimeout(window.close(), 1000);
    }
  }

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const handleAway = async () => {
    socket.emit(`${room}/leave`, userId);
  }

  const handleResponseOpen = ({body, originId}) => {
    setOpenResponse(true);
    // Populating text from notemanager reponseModal
    if (typeof body === 'string' || body instanceof String) setNoteText(body);
    if (typeof originId === 'string' || originId instanceof String) setNoteOriginId(originId);
  };

  const handleResponseClose = () => {
    setOpenResponse(false)
    // cleanup
    setNoteText('');
    setNoteOriginId(null);
  };

  const handleNoteTextChange = (e) => {
    setNoteText(e.target.value);
  }

  const handleNewTag = (e) => {
    e.preventDefault();
    if (newTag) {
      try {
        axios.put('/api/unit/tag', {
          order: discussion.unit, newTag
        }).then((res) => {
          // pushing tag through socket.on for ALL users
          // TODO::update state locally then push to other users
          socket.emit(`${room}/tags/update`, newTag);
          socket.emit('/discussion/full/tags/update', newTag);
        });
      } catch (err) {
        dispatch({
          type: 'SET_ERROR',
          error: err.response.data
        })
      } finally {
        setNewTag('');
      }
    }
  };

  const handlePopNote = ({x, y, noteId, combined, activeDrags}) => {
    socket.emit(room, {x, y, localId: noteId, vote: '', combined, activeDrags});
    dispatch({
      type: 'UPDATE_NOTE_IDEAID',
      id: noteId,
      payload: ''
    });
    axios.post('/api/notePositions/save', {
      userId,
      noteId,
      discussionId,
      x,
      y,
      ideaId: ''
    });
  }

  const StoredNotes = () => {
    let contents = [];
    async function handlePopNote(note) {
      dispatch({
        type: 'UPDATE_NOTE_IDEAID',
        id: note.noteId,
        payload: ''
      });
      // TODO::save x/y, need to capture from chip click?
      await axios.post('/api/notePositions/save', {
        userId,
        noteId: note.noteId,
        discussionId,
        ideaId: '',
        x: note.x,
        y: note.y
      });
    }
    for (let note of notes) {
      if (note.ideaId === 'backpack') {
        contents.push(
          <Chip
            onClick={() => handlePopNote(note)}
            label={note.content.substring(0, 40)} // truncate small chips
          />
        )
      }
    }
    return <div style={{margin:30}}>{contents}</div>
  }

  useEffect(() => {
    document.title = `Full Class Discussion - ${discussion.name}`;

    axios.get('/api/unit/retrieve/order',
      { params: { order: discussion.unit } }
    ).then(res => {
      setTags(res.data.tags);
    });

    socket.on(`${room}/new`, (note) => {
      dispatch({
        type: 'ADD_NOTE',
        payload: note
      });
    });

    socket.on(`${room}/tags/update`, (arg) => {
      setTags(tags => [...tags, arg]);
    });

    socket.on(`${room}/push`, (note) => {
      dispatch({
        type: 'ADD_NOTE',
        payload: note
      });
    });

    socket.on(`${room}/move`, (targetUserId, targetDiscussionId, unit) => {
      if (targetUserId === userId) {
        window.removeEventListener('beforeunload', alertUser);
        window.removeEventListener('unload', handleAway);

        // no target discussion will move to waiting room, intentional
        if (targetDiscussionId === undefined) {
          history.push(`/discussion`)
        } else if (unit) {
          history.push(`/fulldiscussion/${targetDiscussionId}`)
        } else {
          history.push(`/discussion/${targetDiscussionId}`)
        }
        // reload hack
        window.location.reload();
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
  <StyledFullDiscussionBackground>
    <div className='group-discussion'>
        Topic: {discussion.name}
        <br />
        Question Prompt: {discussion.prompt}
      </div>
    <svg className="blob" xmlns="http://www.w3.org/2000/svg" width="1699" height="194" viewBox="0 0 1699 194" fill="none">
      <path d="M101.833 -298.237C721.068 -296.926 1272.91 -497.485 1502.78 -451.233C1732.65 -404.981 1775 -231.417 1551.34 -6.63004C1327.68 218.157 420.036 229.035 150.388 146.366C-119.26 63.6975 -517.402 -299.548 101.833 -298.237Z" fill="#E0E0E0"/>
    </svg>
    <svg className="blob-right" xmlns="http://www.w3.org/2000/svg" width="865" height="193" viewBox="0 0 865 193" fill="none">
      <path d="M864.424 178.195C529.46 93.7237 204.263 168.451 85.9725 103.062C-32.3178 37.6731 -32.2729 -97.0992 118.514 -234.093C269.3 -371.088 761.965 -256.792 896.966 -158.96C1031.97 -61.1281 1199.39 262.667 864.424 178.195Z" fill="#F0CB74"/>
    </svg>
    <Button
        primary
        small
        style={{left: '950px', top: '32px'}}
        onClick={handleOpen}
        disabled={isStudentUser}
      >
        <div className="text-wrapper">Add Theme</div>
      </Button>
      <Box className={classes.filterContainer}>
        {tags && <FormControl variant="filled" fullWidth sx={{backgroundColor: isStudentUser && 'dimgrey'}} className={classes.filterSelect}>
          <InputLabel sx={{ color: "#FFF", fontFamily: "Inter", fontSize: "12px", top: "-3px" }} id="tags-select-label">Filter Tags</InputLabel>
            <Select
              variant='standard'
              labelId="tags-select-label"
              id="tags-select"
              value={filterTag}
              label="Tags"
              disableUnderline={true}
              onChange={handleFilter}
              disabled={isStudentUser}
            >
              <MenuItem key='-1' value='all'>Show All</MenuItem>
              {tags.map((tag, index) => <MenuItem key={index} value={tag}>{tag}</MenuItem>)}
            </Select>
        </FormControl> }
      </Box>
      <Button
        primary
        small
        style={{left: '1250px', top: '32px'}}
        onClick={handleFinish}
        disabled={isStudentUser}
      >
        <div className="text-wrapper">Finish Discussion</div>
      </Button>
      { isStudentUser && <><SideBar
        handleGoHome={() => history.push('/notebook')}
        handleShowNotes={() => setShowNotes(true)}
        handleExit={() => history.push(`/discussion`)}
      />
      <NoteManager
        show={showNotes}
        close={closeNotes}
        navigation={navigation}
        ideaWall={true}
        responseModal={handleResponseOpen}
      /></> }
      <Backpack open={openBackpack} className="backpack-target">
        <div className="sub-title">Backpack</div>
        { openBackpack && <Box sx={{height: '80%', position: 'absolute', overflow: 'scroll'}}>
          <Grid container spacing={1}>
            { notes.filter(note => note.ideaId === "backpack").map((note, index) =>
              <Grid key={index} item xs={12} md={12} xl={12}>
                <Note key={index} onClick={() => handlePopNote(note)}>
                  <div className="text-wrapper">{note.content}</div>
                </Note>
              </Grid>
            )}
          </Grid>
        </Box>}
        <Button
          style={{ position: 'absolute', right: '0', left: '0', margin: '0 auto', bottom: '50px'}}
          small
          onClick={() => setOpenBackpack(!openBackpack)}
          >
          <div className="text-wrapper">{openBackpack ? 'Close' : `${notes.filter(f => f.ideaId === 'backpack').length} notes`}</div>
        </Button>
      </Backpack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-idea"
        aria-describedby="modal-modal-idea-form"
      >
        <Box className={classes.modal}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" component="h2" style={{textAlign: 'center'}}>
                Add a New Idea
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                id="idea-name"
                variant="standard"
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="example: photosynthesis"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Button
                primary
                style={{left: '0', right: '0', margin: '0 auto', bottom: '55px'}}
                onClick={handleNewIdea}
                disabled={isStudentUser}
              >
                <div className="text-wrapper">Create Idea</div>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openResponse}
        // onClose={handleResponseClose}
        aria-labelledby="modal-modal-response"
        aria-describedby="modal-modal-form-response"
      >
        <Box className={classes.modal}>
          <StudentResponseForm
            userId={userId}
            room={room}
            noteText={noteText}
            discussionId={discussionId}
            originId={noteOriginId}
            handleNoteTextChange={handleNoteTextChange}
            handleClose={handleResponseClose}
            tags={tags}
            newTag={newTag}
            setNewTag={setNewTag}
            handleNewTag={handleNewTag}
          />
        </Box>
      </Modal>
      { notes.map((note, index) => <StudentResponse
        key={index}
        classes={classes}
        discussionId={discussionId}
        note={note}
        filter={filterTag}
        room={room}
        groupBorderColor={pushMapping[note.originRoom]?.groupBorderColor}
        isStudentUserInFullDiscussion={isStudentUser}
      />)}
      { ideas.map((idea, index) => <IdeaSpace
        key={index}
        classes={classes}
        idea={idea}
        room={room}
        discussionId={discussionId}
        isStudentUserInFullDiscussion={isStudentUser}
      />)}
    </StyledFullDiscussionBackground>
  )
}