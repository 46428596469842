import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Paper,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Badge
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import FaceIcon from '@mui/icons-material/Face';
import socket from '../../utils/socket';
import axios from 'axios';
import store from '../../store';
import RoomViewTable from './RoomViewTable';
import './animate-character.css';
import NavAppBar from './common/NavAppBar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 'auto',
    padding: 4,
  },
  cardHeader: {
    textAlign: 'center',
  },
  noOverLay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

export default function RoomView() {
  const classes = useStyles();
  const history = useHistory();

  const [userIds, setUserIds] = useState([]);
  const [units, setUnits] = useState([]);
  const [unitDiscussions, setUnitDiscussions] = useState([]);
  const [error, setError] = useState(null);
  const [unit, setUnit] = useState('');
  const [targetDiscussionId, setTargetDiscussionId] = useState('');
  const [configurations, setConfigurations] = useState([]);
  const [selectedConfigurationId, setSelectedConfigurationId] = useState('');
  const [selectedConfigurationStudents, setSelectedConfigurationStudents] = useState([])
  const [fullDiscussion, setFullDiscussion] = useState('');

  // move to room modal
  const [openMoveModal, setMoveModalOpen] = useState(false);
  const handleMoveModalOpen = () => setMoveModalOpen(true);
  const handleMoveModalClose = () => setMoveModalOpen(false);

  // seat all modal
  const [openSeatAllModal, setSeatAllModalOpen] = useState(false);
  const handleSeatAllModalOpen = () => setSeatAllModalOpen(true);
  const handleSeatAllModalClose = () => setSeatAllModalOpen(false);

  // selection model
  const [selectionModel, setSelectionModel] = useState([]);
  const handleDeselectAll = () => setSelectionModel([]);

  const [notifications, setNotifications] = useState([]);

  // notifications
  const handleApprove = room => {
    setNotifications(notifications.filter(item => item !== room));
    socket.emit(`${room}/approved`, room);
  }

  const handleRework = room => {
    setNotifications(notifications.filter(item => item !== room));
    socket.emit(`${room}/rework`, room);
  }

  const handleChangeUnit = (e) => {
    setUnit(e.target.value);
    // zeroing out notifications on unit change
    socket.emit('/teacher/notifications/clear');
    setNotifications([]);
  }

  // waiting
  const handleKickAll = () => {
    socket.emit(`/teacher/kickAll`);
  }


  useEffect(() => {
    document.title = `Room View`;
    socket.connect();

    (async () => {
      try {
        const res1 = await axios.get('/api/unit');
        setUnits(res1.data);
        const res3 = await axios.get('/api/discussionGroups');
        setConfigurations(res3.data);
        if (unit) {
          const res2 = await axios.get('/api/discussion/unit', {
            params: { unit }
          });
          setUnitDiscussions(res2.data);
          const res4 = await axios.get('/api/discussion/retrieve', {
            params: { unit, groupNumber: 'full' }
          });
          setFullDiscussion(res4.data);
        }
      } catch (err) { setError(err.response.data) };
    })();

    // notifications
    // add to roomview table and check what info comes from it
    socket.on('/teacher/renunciate', data => {
      setNotifications(notifications.filter(item => item !== data));
    });

    socket.on('/teacher/notifyTeacher', data => {
      setNotifications(data);
    });

    socket.on('/teacher/notifications', data => {
      setNotifications(data);
    });

    // movement
    socket.on('/teacher/notifyMovement', () => {
      socket.emit('/teacher/users');
    });

    socket.on('/teacher/users', data => {
      // potential memory leak here on unit update
      setUserIds(data);
    });

    socket.emit('/teacher/users');
    socket.emit('/teacher/notifications');
    // eslint-disable-next-line
  }, [unit]);

  const handleSeatAllAssigned = () => {
    for (let student of selectedConfigurationStudents) {
      const discussion = unitDiscussions.find(f => f.groupNumber === student.group)
      const currentStudent = userIds.find(f => f.userId === student.id)
      if (currentStudent && discussion) {
        // avoid moving a student if already in the proper room
        if (currentStudent.room.split('/')[1] !== student.group)
          socket.emit(`${currentStudent.room}/move`, student.id, discussion._id);
      }
    }
    // cleanup
    setSelectedConfigurationId('');
    setSelectedConfigurationStudents([]);
    handleSeatAllModalClose();
  }

  //Changes display on room card when no students are in room
  function CustomNoRowsOverlay() {
    return (
        <Typography component='div' className={classes.noOverLay}>No students in room</Typography>
    );
  }

  const handleMove = () => {
    for (let selectedUserId of selectionModel) {
      let selectedUser = userIds.filter(f => f.userId === selectedUserId).pop();
      if (targetDiscussionId === 'full') {
        socket.emit(`${selectedUser.room}/move`, selectedUserId, fullDiscussion._id, unit);
      } else {
        socket.emit(`${selectedUser.room}/move`, selectedUserId, targetDiscussionId);
      }
    }
    // cleanup
    setTargetDiscussionId('');
    setSelectionModel([]);
    handleMoveModalClose();
  }

  const handleMoveAlltoFullClass = () => {
    for (let userId of userIds) {
      socket.emit(`${userId.room}/move`, userId.userId, fullDiscussion._id, unit);
    }
  }

  const handleViewFullDiscussion = () => {
    const win = window.open(`/fulldiscussion/${fullDiscussion._id}`, '_blank');
    win.focus();
  }

  const handleSelectConfiguration = e => {
    let configurationId = e.target.value;
    setSelectedConfigurationId(configurationId)
    if (configurationId) {
      let configuration = configurations.find(obj => {
        return obj._id === configurationId
      });
      setSelectedConfigurationStudents(configuration.students);
    }
  }

  return (
    <Container maxWidth="xl">
      <NavAppBar
        to='/authoring'
        toLabel='Go To Discussion Authoring'
      >
        { selectionModel.length > 0 && <>
          <Tooltip title="Move Selected">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleMoveModalOpen()}
            >
                <TransferWithinAStationIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Deselect All">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={() => handleDeselectAll()}
            >
                <RemoveDoneIcon />
            </IconButton>
          </Tooltip>
        </> }
        <Tooltip title="Number of Selected Students" sx={{mr:2}}>
          <Badge color="secondary" badgeContent={selectionModel.length} showZero>
            <FaceIcon />
          </Badge>
        </Tooltip>
        <Tooltip title="Move All to Assigned Rooms">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => handleSeatAllModalOpen()}
          >
            <DirectionsBusIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Move All to Full Class Authoring">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => handleMoveAlltoFullClass()}
          >
            <EditRoadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Move All to Waiting Room">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => handleKickAll()}
          >
            <PlaylistRemoveIcon />
          </IconButton>
        </Tooltip>
      </NavAppBar>
      <Grid container spacing={1} style = {{ marginTop: 75 }}>
        <Grid container item spacing={2} sm={12} lg={3} xl={3} sx={{ pr: 2, height: '75%'}}>
            <Grid item xs={12} md={12} xl={12} sx = {{ height: '10%', mb: { sm: 0, xl: 10}}}>
                <Card sx={{  border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
                    <FormControl fullWidth variant="filled" >
                      <InputLabel id="unit-select-label">Select Unit</InputLabel>
                      <Select
                          labelId="unit-select-label"
                          id="unit-select"
                          value={unit}
                          onChange={(e) => handleChangeUnit(e)}
                      >
                        {units && (units).map(({order, name}, index) => (
                          <MenuItem key={index} value={order}>
                            <Typography variant='inherit' noWrap>{order} | {name}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Card>
            </Grid>
            <Grid item xs={12} md={12} xl={12} sx={{ height: '65%'}} >
              <Card sx={{ border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
                <CardHeader sx={{  borderBottom : 3, borderColor:'primary.main', borderRadius: '5px'}}
                    className={classes.cardHeader}
                    component={'div'}
                    title={'Unassigned Students'}
                />
                <Typography component="div" style={{ height: 500, width: "100%" }}>
                  { userIds && <DataGrid
                    rows={userIds.filter(f => f.room === '/waiting')}
                    columns={[{ field: 'name', headerName: 'Name', width: 250 }]}
                    checkboxSelection
                    getRowId={(r) => r.userId}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    keepNonExistentRowsSelected
                    disableSelectionOnClick
                    hideFooterRowCount
                    hideFooter
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay
                    }}
                  /> }
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3} item sm={12} lg={9} xl={9}>
            {fullDiscussion && <Grid item sm={12} lg={12} xl={12}>
              <Card sx={{ border: 3, borderColor:'primary.main', borderRadius: '10px', padding: '5px'}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Typography variant='h5'>
                    <div className="animate-character">Full Discussion - Topic: {fullDiscussion.name} | Prompt: {fullDiscussion.prompt}</div>
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => handleViewFullDiscussion()}
                  >View Full Discussion</Button>
                </Box>
              </Card>
            </Grid> }
            { userIds && unitDiscussions.map(({_id, groupNumber, name, prompt}, index) => <RoomViewTable
              key={index}
              discussionId={_id}
              name={name}
              prompt={prompt}
              group={groupNumber}
              notifications={notifications}
              userIds={userIds && userIds.filter(user => user.discussionId === _id)}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
              handleApprove={handleApprove}
              handleRework={handleRework}
            />) }
        </Grid>
      </Grid>
      <Modal
        open={openMoveModal}
        onClose={handleMoveModalClose}
        aria-labelledby="move-modal-title"
        aria-describedby="move-modal-description"
      >
        <div className={classes.modal}>
          <Paper className={classes.paper}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h4'>
                  Move student(s) into groups
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='subtitle1'>
                  {selectionModel.length} student(s) currently selected
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="move-select-label">select group</InputLabel>
                  <Select
                    labelId="move-select-label"
                    id="move-select"
                    label="Move Rooms"
                    value={targetDiscussionId}
                    onChange={(e) => setTargetDiscussionId(e.target.value)}
                  >
                    { fullDiscussion &&
                      <MenuItem key={-1} value={'full'} className={classes.menuItem}>
                        Full Class
                      </MenuItem>
                    }
                    { unitDiscussions.map(({_id, groupNumber}, index) => {
                      return groupNumber !== 'full' &&
                        <MenuItem key={index} value={_id} className={classes.menuItem}>
                          {groupNumber}
                        </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleMove()}
                >send to group</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>
      <Modal
        open={openSeatAllModal}
        onClose={handleSeatAllModalClose}
        aria-labelledby="config-move-modal-title"
        aria-describedby="config-move-modal-description"
      >
        <div className={classes.modal}>
          <Paper className={classes.paper}>
            <Grid container spacing={5}>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant='h4'>
                  Seat All Students
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="config-move-select-label">select class configuration</InputLabel>
                  <Select
                    labelId="config-move-select-label"
                    id="config-move-select"
                    label="Select Class Configuration"
                    value={selectedConfigurationId}
                    onChange={handleSelectConfiguration}
                  >
                    { configurations.map(({name, _id}, index) => (
                      <MenuItem key={index} value={_id} className={classes.menuItem}>
                        {name}
                      </MenuItem>
                    )) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSeatAllAssigned()}
                >move all students</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Modal>
    </Container>
  )
}