import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import axios from "axios";

import ComponentCombiner from "../../utils/ComponentCombiner";

const useStyles = makeStyles({
  pageTitle: {
    paddingTop: "15px",
    paddingBottom: "20px",
  },
  bodyDiv: {
    backgroundColor: "white",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingTop: "40px",
    paddingLeft: "40px",
    paddingBottom: "40px",
    marginBottom: "20px",
    overflowY: "hidden",
  },
  // content
});

const mapPropsToComponent = (component, props) => {
  return { ...(component.props || props), type: component.type };
};

const IntroPage = (props) => {
  const {
    userid,
    navigation,
    pageId,
    answersPageId,
    activeStep,
    activeTab,
    activeLab,
    tableHeaders,
    studentRequest,
    unlockLab,
    baseUrl,
  } = props;

  const [pageContents, setPageContents] = useState({});
  const [pageAnswers, setPageAnswers] = useState({});
  const [answerUpdates, setAnswerUpdates] = useState({});
  const titleDict = {
    intro1: "Introduction",
    hypo1: "Hypothesis",
    exp1: "Experiment",
    analysis1: "Analysis",
    var1: "Variables",
  };

  const saveAnswers = async (answerUpdates) => {
    // If no updates to answers since last save, don't update anything
    if (Object.keys(answerUpdates).length === 0) {
      console.log("Nothing new to save");
      return;
    }
    console.log("Saving answers");
    try {
      await studentRequest("/api/answers/setAnswer", {
        studentId: userid,
        answerUpdates,
      });
      console.log("answers updated");
      // After saving answers, clear the answerUpdates variable since they are now up to date
      return setAnswerUpdates({});
    } catch (error) {
      return console.error(error);
    }
  };

  //When a new page is selected, write to the database.
  const saveNav = async (page) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    var hours = String(today.getHours()).padStart(2, "0");
    var mins = String(today.getMinutes()).padStart(2, "0");
    var secs = String(today.getSeconds()).padStart(2, "0");
    today = mm + "/" + dd + "/" + yyyy;
    var time = hours + ":" + mins + ":" + secs;
    var date = today;
    var timeStamp = date + " " + time;
    studentRequest("/api/logging/savenav", {
      studentId: userid,
      page: page,
      time: timeStamp,
    });
  };

  // Sets up interval for saving entered answers using answerUpdates state hook
  // Dependency is the answerUpdates variable, so the interval always uses the most up to date answer data
  // Currently will save 5 seconds after user stops editing
  useEffect(() => {
    const saveInterval = setInterval(
      async () => saveAnswers(answerUpdates),
      3000
    );

    // cleanup function clears the interval
    return () => {
      clearInterval(saveInterval);
    };
  }, [answerUpdates]);

  const loadPageContent = async (pid) => {
    let pcres = {}

    try {
      pcres = await axios.post("/api/getPage/page", {
        pageId: pid,
        studentId: userid,
        lessonId: navigation.Lesson_Id,
        classroom: navigation.Classroom,
      });
    } catch (err) {
      console.log(err)
    }

    // Add new page to pageContent & pageAnswers
    setPageAnswers((pageAnswers) => ({
      ...pageAnswers,
      [pid]: pcres.data[1],
    }));
    setPageContents((pageContents) => ({
      ...pageContents,
      [pid]: pcres.data[0],
    }));
  };

  // gets page content and answers from database only when it doesn't already have them in state
  useEffect(async () => {
    // Log student's page and timestamp
    saveNav(pageId);

    // If state doesn't already have page contents, load them from database
    if (!pageContents[pageId]) {
      loadPageContent(pageId);
    }

    // If answers page different from content page and not loaded yet, fetch it
    if (pageId !== answersPageId && !pageContents[answersPageId]) {
      await loadPageContent(answersPageId);
    }
  }, [pageId, answersPageId]);

  // Function passed as callback to components to update the pageAnswers and answerUpdate
  // state hooks with new user input
  const updatePageAnswer = (answer, objectID) => {
    setAnswerUpdates((answerUpdates) => ({
      ...answerUpdates,
      [answersPageId]: {
        ...answerUpdates[answersPageId],
        [objectID]: answer,
      },
    }));
    setPageAnswers((pageAnswers) => ({
      ...pageAnswers,
      [answersPageId]: {
        ...pageAnswers[answersPageId],
        [objectID]: answer,
      },
    }));
  };

  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Typography className={classes.pageTitle} variant="subtitle1">
        {" "}
        {titleDict[pageId]}{" "}
      </Typography>
      <div className={classes.bodyDiv}>
        <ComponentCombiner
          navigation={navigation}
          activeLab={activeLab}
          activeTab={activeTab}
          activeStep={activeStep}
          tableHeaders={tableHeaders}
          updateAnswer={updatePageAnswer}
          pageAnswers={pageAnswers[answersPageId] || {}}
          collection={pageContents[pageId] || []}
          mapPropsToComponent={mapPropsToComponent}
          studentRequest={studentRequest}
          setRender={props.setRender}
          unlockLab={unlockLab}
          baseUrl={baseUrl}
        />
      </div>
    </div>
  );
};

export default IntroPage;
