import { useState } from 'react';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SchoolIcon from '@mui/icons-material/School';
import PushPinIcon from '@mui/icons-material/PushPin';
import {
  Typography,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Button
} from '@mui/material';
import EditUnitModal from './EditUnitModal';

export default function Preview ({ units, discussions }) {
  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // edit
  const [name, setName] = useState('')
  const [prompt, setPrompt] = useState('')
  const [order, setOrder] = useState('')
  const [unitId, setUnitId] = useState('')

  const handleOpenEditModal = (order, name, prompt, unitId) => {
    setName(name)
    setPrompt(prompt)
    setOrder(order)
    setUnitId(unitId)
    handleOpen()
  }

  const handleSubmit = async (e) => {
    try {
      await axios.put('/api/unit', {
        unitId,
        prompt,
        name
      });
    } catch (err) { console.log(err.response.data) }
    try {
      const res = await axios.get('/api/discussion/retrieve/unit/all', {
        params: { order }
      });
      for (let discussion of res.data ) {
        await axios.put('/api/discussion', {
          discussionId: discussion._id,
          prompt,
          name
        });
      }
    } catch (err) { console.log(err.response.data) }
    // better to update state, but not really worth it here
    // window.location.reload()
  }

  return (
    <>
    <Box sx={{ p: 2, mt: 2 }}>
      <Card sx={{ maxWidth: 540 }}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Unit X | Example Discussion Name
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Example Discussion Prompt
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
              <Typography variant='body1'>Possible Components: </Typography>
              <Chip icon={<TagFacesIcon />} label="Small Group" variant="outlined" color="primary" />
              <Chip icon={<SchoolIcon />} label="Full Class" variant="outlined" color="secondary" />
              <Chip icon={<PushPinIcon />} label="Push to Full" variant="outlined" color="info" />
            </Stack>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary">
            Edit
          </Button>
        </CardActions>
      </Card>
      <Divider sx={{ mt: 2 }} />
      {(units).map(({order, name, prompt, _id}, index) => (
        <Card key={index} sx={{ maxWidth: 540, mt: 2 }}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Unit {order} | { name }
              </Typography>
              <Typography variant="body2" color="text.secondary">
                { prompt }
              </Typography>
              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                { discussions.filter(d => d.groupNumber !== 'full' & d.unit === order).length > 0 && <Chip icon={<TagFacesIcon />} label="Small Group" variant="outlined" color="primary" /> }
                { discussions.filter(d => d.groupNumber === 'full' & d.unit === order).length > 0 && <Chip icon={<SchoolIcon />} label="Full Class" variant="outlined" color="secondary" /> }
                { discussions.filter(d => d.groupNumber !== 'full' & d.unit === order & d.pushToFullClass).length > 0 && <Chip icon={<PushPinIcon />} label="Push to Full" variant="outlined" color="info" /> }
              </Stack>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={() => handleOpenEditModal(order, name, prompt, _id)}>
              Edit
            </Button>
          </CardActions>
        </Card>
      ))}
    </Box>
    <EditUnitModal
      order={order}
      name={name}
      setName={setName}
      prompt={prompt}
      setPrompt={setPrompt}
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
    </>
  )
}