import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Analysis Summary
      </Typography>
      {props.buttons}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "AnalysisSummary",
      props: {
        fieldKey:
          authorId + "-anasum-" + Math.random().toString(36).substr(2, 6),
        answers: [],
      },
    },
  };
}

export const AnalysisSummary = { createNew, Form, name: "Analysis Summary" };

export default AnalysisSummary;
