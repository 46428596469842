import axios from "axios";

import { SET_CURRENT_LESSON, GET_ERRORS, SET_CURRENT_NAVIGATION, SET_ACTIVE_LAB, SET_ACTIVE_TABLE, SET_TABLE_KEY } from "./types";

export const setCurrentLesson = (lessonData) => {
    return {
        type: SET_CURRENT_LESSON,
        payload: lessonData
    };
};

export const setCurrentNavigation = (navigationData) => {
    return {
        type: SET_CURRENT_NAVIGATION,
        payload: navigationData
    };
}

export const getLesson = userData => dispatch => {
    axios
        .post('/api/navigation/getlessons', userData)
        .then(res => {
            //Set navigation data in local storage
            localStorage.setItem("navData", JSON.stringify(res.data[0]));
            //Set the current lesson
            dispatch(setCurrentLesson(res.data[0]));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload:  err.response.data
            })
        );
};

export const getNavigation = userData => dispatch => {
    axios
        .post('/api/navigation/getnavigation', userData)
        .then(res => {
            localStorage.setItem("navigation", JSON.stringify(res.data[0]));
            dispatch(setCurrentNavigation(res.data[0]));
            dispatch(setActiveLab(res.data[0]?.Furthest_Lab));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const getTeacherNavigation = userData => dispatch => {
    axios
        .post('/api/navigation/getteachernavigation', userData)
        .then(res => {
            localStorage.setItem("navigation", JSON.stringify(res.data[0]));
            dispatch(setCurrentNavigation(res.data[0]));
            dispatch(setActiveLab(res.data[0].Furthest_Lab));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const setActiveLab = (activeLab) => {
    return {
        type: SET_ACTIVE_LAB,
        payload: activeLab
    }
};

export const setActiveTable = (activeTable) => {
    return {
        type: SET_ACTIVE_TABLE,
        payload: activeTable
    }
};

export const setTableKey = (tableKey) => {
    return {
        type: SET_TABLE_KEY,
        payload: tableKey
    }
}