import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Embedded Website
      </Typography>
      {props.buttons}
      <TextField
        id="url"
        label="URL"
        value={props.data.url}
        onChange={(event) =>
          props.updateComponent("url", event.target.value)
        }
        placeholder=""
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: null,
    },
    comp: {
      type: "PageEmbeddedWebsite",
      props: {
        fieldKey:
          authorId + "-embedurl-" + Math.random().toString(36).substr(2, 6),
        url: "",
      },
    },
  };
}

export const PageEmbeddedWebsite = { createNew, Form, name: "Embedded Website" };

export default PageEmbeddedWebsite;
