import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Slider
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <TextField
        required
        id="minval"
        label="Minimum Value"
        type="number"
        value={props.data.smin}
        onChange={(event) =>
          props.updateComponent("smin", parseInt(event.target.value))
        }
        variant="outlined"
        style={{ width: '50%' }}
      />
      <TextField
        required
        id="maxval"
        label="Maximum Value"
        type="number"
        value={props.data.smax}
        onChange={(event) =>
          props.updateComponent("smax", parseInt(event.target.value))
        }
        variant="outlined"
        style={{ width: '50%' }}
      />
      <br />
      <br />
      <TextField
        required
        id="stepsize"
        label="Step Size"
        type="number"
        value={props.data.sstep}
        onChange={(event) =>
          props.updateComponent("sstep", parseInt(event.target.value))
        }
        variant="outlined"
        style={{ width: '50%' }}
      />
      <TextField
        required
        id="defaultval"
        label="Default Value"
        type="number"
        value={props.data.defaultVal}
        onChange={(event) => {
          props.updateComponent("defaultVal", parseInt(event.target.value));
          props.updateAnswer({ answer: parseInt(event.target.value) });
        }}
        variant="outlined"
        style={{ width: '50%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: 50,
    },
    comp: {
      type: "PageSlider",
      props: {
        defaultVal: 50,
        fieldKey:
          authorId + "-slider-" + Math.random().toString(36).substr(2, 6),
        question: "",
        smin: 0,
        smax: 100,
        sstep: 5,
      },
    },
  };
}

export const PageSlider = { createNew, Form, name: "Slider" };

export default PageSlider;
