/*

    This is a page component for checkboxes.

    Note: I tried to do this in a lot of different ways, but it had many issues
    in making it work. The best solution I found and was able to use makes use
    of react-hook-form. I'm hesistant to have a component be so different than
    the rest so far, but this seems like a good solution for now. It is also
    a potentially useful feature for storing all of the states since the form
    the checkboxes are contained in allows for an easy submission which will give
    an array of the selected items.

    When this component is called, it is expected that props will be passed
    to it. The props are necessary to populate the element.

    *Required Props*

    question: The text body of the question, or what is being asked.
    checkoptions: (Array -- []) The various values of the checkboxes, and how they
                  will labeled on the page.
    answer: (Array -- []) The selected options from the question, if none have been
            selected an empty array should be passed.

    Example props:

    question="Which of these is not important?"
    checkoptions={checkName}
    answer={checkSelect}

    where checkName = ['Weight', 'Speed', 'Height', 'Time of Day'];
    and   checkSelect = ['Speed'];


    **For reference, this code was heavily influenced by the discussion
    and code samples on the following links:

    https://github.com/react-hook-form/react-hook-form/issues/1517
    https://codesandbox.io/s/material-demo-54nvi?file=/demo.js

*/

import { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

export function PageCheckbox(props) {
  const [checkedValues, setCheckedValues] = useState(
    props.answers.answer || []
  );

  //Function to handle the newly checked item
  //Readability of this is horrible, but it works for now
  const handleSelect = (checkedVal) => {
    const newValue = checkedValues?.includes(checkedVal)
      ? checkedValues?.filter((value) => value !== checkedVal)
      : [...(checkedValues ?? []), checkedVal];
    setCheckedValues(newValue);
    props.returnData({ answer: newValue }, props.fieldKey);
  };

  return (
    <div>
      <Typography>{props.question}</Typography>
      {props.checkoptions.map((option) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedValues.includes(option)}
              onChange={() => handleSelect(option)}
            />
          }
          key={option}
          label={option}
        />
      ))}
      <br />
    </div>
  );
}

export default PageCheckbox;
