import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Redirect Button
      </Typography>
      {props.buttons}
      <TextField
        id="buttonText"
        label="Button Text"
        value={props.data.buttonText}
        onChange={(event) =>
          props.updateComponent("buttonText", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
      <br/>
      <br/>
      <TextField
        id="url"
        label="URL"
        value={props.data.url}
        onChange={(event) =>
          props.updateComponent("url", event.target.value)
        }
        placeholder=""
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: null,
    },
    comp: {
      type: "PageRedirectButton",
      props: {
        fieldKey:
          authorId + "-redirbut-" + Math.random().toString(36).substr(2, 6),
        url: "",
        buttonText: ""
      },
    },
  };
}

export const PageRedirectButton = { createNew, Form, name: "Redirect Button" };

export default PageRedirectButton;
