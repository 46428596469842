import { combineReducers } from "redux";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import lessonReducer from "./lessonReducer";
import navigationReducer from "./navigationReducer";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    lesson: lessonReducer,
    navigation: navigationReducer
});