import React from "react";
import TextField from "@mui/material/TextField";
//lab select
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import store from "../../../../store";

export function Note(props) {

    let lNames = props.nav.Labs.map(lab => lab.labName);

    const [title, setTitle] = React.useState(props.title);
    const [body, setBody] = React.useState(props.body);
    const [selectedLab, setSelectedLab] = React.useState(lNames.indexOf(props.lab))

    function onChangeTitle(event) {
        setTitle(event.target.value);
        props.updateValue("title", event.target.value, props.id);
    }

    function onChangeBody(event) {
        setBody(event.target.value);
        props.updateValue("body", event.target.value, props.id);
    }

    function onChangeLab(event) {
        setSelectedLab(event.target.value);
        props.updateValue("lab", lNames[event.target.value], props.id);
    }

    return (
        <div>
            <TextField id="title" label="Title" variant="outlined" value={title} onChange={event => onChangeTitle(event)} />
            <FormControl sx={{ minWidth: 240, marginLeft: 40 }}>
                    <InputLabel id="lab-filter-small-note">Lab</InputLabel>
                    <Select
                        labelId="lab-filter-small-note"
                        id="lab-filter-note"
                        label="Lab"
                        value={selectedLab}
                        onChange={(event) => onChangeLab(event)}
                    >
                        {lNames.map((name, index) => {
                            return(<MenuItem value={index}>{name}</MenuItem>)
                        })}
                </Select>
            </FormControl>
            <br/>
            <br/>
            <br/>
            <TextField 
                id="Note" 
                label="Note" 
                variant="outlined" 
                value={body} 
                onChange={event => onChangeBody(event)}
                multiline
                rows={8}
                style={{ width: 800 }} 
            />
        </div>
    )
}

export default Note;