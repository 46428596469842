import react, { useEffect, useState, createRef } from "react";
import axios from "axios";
import { TreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Paper, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import EditIcon from "@mui/icons-material/Edit";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

//For page unique code at the end to avoid duplicate pages
function generateCode(length) {
    var code = '';
    var characters = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var charLength = length;

    //Iterate through the length, select randomly from characters array
    for ( var i = 0; i < length; i++ ) {
        code += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return code;
}

export default function UnitTree(props) {

    const [nav, setNav] = useState({});
    const lessonName = props.lesson;
    const [navUpdated, setNavUpdate] = useState(false);
    //Delete dialog
    const [deleteDialog, setOpenDeleteDialog] = useState(false);
    const [labTitle, setLabTitle] = useState("");
    const [deleteIndex, setDeleteIndex] = useState(-1);
    //Edit lab dialog
    const [editLabDialog, setEditLabDialog] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [labLockStatus, setLabLockStatus] = useState("");
    const [initialLockStatus, setInitialLock] = useState(false);
    const title = createRef();
    const [labTitleError, setLabError] = useState(false);
    const [labTitleErrorText, setLabTitleError] = useState("");
    const [disableLock, setDisableLock] = useState(false);
    //New lab/essay
    const [newLabDialog, setNewLabDialog] = useState(false);
    const [labType, setNewLabType] = useState("");
    const newLabTitle = createRef();
    const [newLabTitleError, setNewLabTitleError] = useState(false);
    const [newLabTitleErrorText, setNewLabTitleErrorText] = useState("");
    const [newLabTypeError, setNewLabTypeError] = useState(false);
    const [newLabTypeErrorText, setNewLabTypeErrorText] = useState("");
    //Edit page title
    const newTabName = createRef();
    const [tabDialog, setTabDialog] = useState(false);
    const [tabTitleErrorText, setTabTitleErrorText] = useState("");
    const [tabTitleError, setTabTitleError] = useState(false);
    const [currentTab, setCurrentTab] = useState("");
    const [selectedTabType, setSelectedTab] = useState("");


    useEffect(() => {
        axios
        .post("/api/pagecreate/getunit", {
            lessonName: lessonName,
        })
        .then(result => {
            if (result.data == "Empty") {
                console.log("Something went wrong, no lesson was found.")
            } else {
                setNav(result.data);
            }
        })
        .catch(error => console.log(error))
    }, [navUpdated])

    useEffect(() => {
        setNavUpdate(!navUpdated);
    }, [props.refresh]);

    function generateTree() {
        if (nav.Labs === undefined) {
            return (<div><p>Please wait...</p></div>)
        }
        let treeValues = [];
        let nodeCount = 1;
        let labs = nav.Labs;
        labs.forEach((labInfo, index) => {
                let symbol = checkCompletion(labInfo, labInfo.type);
                if (index == 0) {
                    let pageValues = [
                        <TreeItem key={index} nodeId={(nodeCount + 1).toString()} label={
                            <div style={{display: "flex"}}>
                              {symbol}
                              <Typography key={index+1} style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setEditIndex(index); setDisableLock(true); setEditLabDialog(true); lockStatus(labInfo.unlocked); setInitialLock(labInfo.unlocked); }}>
                                <EditIcon style={{ fontSize: "95%"}} />
                              </Typography>
                              <Typography key={index+2} style={{marginRight: 7}} onClick={event => {event.stopPropagation(); shiftLabDown(index)}}>
                                <ArrowDownwardIcon style={{ fontSize: "95%"}} />
                              </Typography>
                              <Typography key={index+3} style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setOpenDeleteDialog(true); setDeleteIndex(index)}}>
                                <DeleteIcon style={{ fontSize: "95%"}} />
                              </Typography>
                            </div>
                          }>
                            {getPages(labInfo, labInfo.type, nodeCount + 1, index)}
                        </TreeItem>
                        ]
                    treeValues.push(pageValues);
                } else if (index == labs.length - 1) {
                    let pageValues = [
                        <TreeItem nodeId={(nodeCount + 1).toString()} label={
                            <div style={{display: "flex"}}>
                              {symbol}
                              <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setEditIndex(index); setDisableLock(false); setEditLabDialog(true); lockStatus(labInfo.unlocked); setInitialLock(labInfo.unlocked); }}>
                                <EditIcon style={{ fontSize: "95%"}} />
                              </Typography>
                              <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); shiftLabUp(index)}}>
                                <ArrowUpwardIcon style={{ fontSize: "95%"}} />
                              </Typography>
                              <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setOpenDeleteDialog(true); setDeleteIndex(index)}}>
                                <DeleteIcon style={{ fontSize: "95%"}} />
                              </Typography>
                            </div>
                          }>
                            {getPages(labInfo, labInfo.type, nodeCount + 1, index)}
                        </TreeItem>
                    ]
                    treeValues.push(pageValues);
                } else {
                    let pageValues = [
                    <TreeItem nodeId={(nodeCount + 1).toString()} label={
                        <div style={{display: "flex"}}>
                        {symbol}
                        <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setEditIndex(index); setDisableLock(false); setEditLabDialog(true); lockStatus(labInfo.unlocked); setInitialLock(labInfo.unlocked); }}>
                            <EditIcon style={{ fontSize: "95%"}} />
                        </Typography>
                        <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); shiftLabUp(index)}}>
                            <ArrowUpwardIcon style={{ fontSize: "95%"}} />
                        </Typography>
                        <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); shiftLabDown(index)}}>
                            <ArrowDownwardIcon style={{ fontSize: "95%"}} />
                        </Typography>
                        <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setLabTitle(labInfo.labName); setOpenDeleteDialog(true); setDeleteIndex(index)}}>
                            <DeleteIcon style={{ fontSize: "95%"}} />
                        </Typography>
                        </div>
                    }>
                        {getPages(labInfo, labInfo.type, nodeCount + 1, index)}
                    </TreeItem>
                    ]
                    treeValues.push(pageValues);
                }
                if (labInfo.type == "lab") {
                    nodeCount = nodeCount + 6;
                } else {
                    nodeCount = nodeCount + 3;
                }
        });
        return(treeValues);
    }

    function generateHome() {
        if (nav.Labs === undefined) {
            return (<div><p>Please wait...</p></div>)
        } else {
            let symbol;
            if (nav.Side_Navigation.home.substr(-4, 4) == "temp") {
                symbol = [
                    <Typography key={1}>
                        <PriorityHighIcon style={{ color: "red", fontSize: "95%", marginRight: 5 }} />
                    </Typography>
                ];
                return (<TreeItem key={1} nodeId="1" label={<div style={{display: "flex"}}>{symbol} Home/Instructions</div>} onClick={() => {props.setPage(nav.Side_Navigation.home);}} />);
            } else {
                symbol = [
                    <Typography key={2}>
                        <CheckIcon style={{ color: "green", fontSize: "95%", marginRight: 5}} />
                    </Typography>
                ];
                return (<TreeItem key={2} nodeId="1" label={<div style={{display: "flex"}}>{symbol} Home/Instructions</div>} onClick={() => {props.setPage(nav.Side_Navigation.home);}} />);
            }
        }
    }

    const lockStatus = (unlocked) => {
        if (unlocked) {
            setLabLockStatus("unlocked");
        } else {
            setLabLockStatus("locked");
        }
    }

    function checkCompletion(lab, pageType) {
        let lockStatus;
        if (!lab.unlocked) {
            lockStatus = <LockIcon style={{fontSize: "95%", marginRight: 2 }} />;
        } else {
            lockStatus = "";
        }
        let pages = lab.pages;
        if (pageType == "lab") {
            if (pages.overview.slice(-4) == "temp" || pages.experiment.slice(-4) == "temp" || pages.data.slice(-4) == "temp" || pages.analysis.slice(-4) == "temp" || lab.simulation.slice(-4) == "temp") {
                return [
                    <Typography style={{marginRight: 10}} >
                        <PriorityHighIcon style={{ color: "red", fontSize: "95%", marginRight: 5 }} />
                        {lockStatus}
                        {lab.labName}
                    </Typography>
                ];
            } else {
                return [
                    <Typography style={{marginRight: 10}} >
                        <CheckIcon style={{ color: "green", fontSize: "95%", marginRight: 5 }} />
                        {lockStatus}
                        {lab.labName}
                    </Typography>
                ];
            }
        } else if (pageType == "essay") {
            if (pages.overview.slice(-4) == "temp" || lab.simulation.slice(-4) == "temp") {
                return [
                    <Typography style={{marginRight: 10}}>
                        <PriorityHighIcon style={{ color: "red", fontSize: "95%", marginRight: 5 }} />
                        {lockStatus}
                        {lab.labName}
                    </Typography>
                ];
            } else {
                return [
                    <Typography style={{marginRight: 10}}>
                        <CheckIcon style={{ color: "green", fontSize: "95%", marginRight: 5 }} />
                        {lockStatus}
                        {lab.labName}
                    </Typography>
                ];
            }
        }
    }

    function getPages(lab, pageType, nodeCount, index) {
        let pagesValues = [];
        let pages = lab.pages;
        if (pageType == "lab") {
            pagesValues.push(createItem(pages.overview, "Overview", (nodeCount + 1).toString(), index))
            pagesValues.push(createItem(pages.experiment, lab.tabTitles.experiment, (nodeCount + 2).toString(), index, "Experiment"))
            pagesValues.push(createItem(pages.data, lab.tabTitles.data, (nodeCount + 3).toString(), index, "Data"))
            pagesValues.push(createItem(pages.analysis, lab.tabTitles.analysis, (nodeCount + 4).toString(), index, "Analysis"))
            pagesValues.push(createItem(lab.simulation, "Simulation", (nodeCount + 5).toString(), index))
        } else {
            pagesValues.push(createItem(pages.overview, "Essay", (nodeCount + 1).toString(), index))
            pagesValues.push(createItem(lab.simulation, "Simulation", (nodeCount + 2).toString(), index))
        }
        return pagesValues;
    }

    function createItem(page, pageType, nodeValue, labIndex, dataType) {
        //unfinished pages have temp on the end
        let symbol;
        let splitPage = page.split("-");
        if (page.slice(-4) == "temp" && splitPage[2] != "") {
            symbol = [
                <Typography>
                    <PriorityHighIcon style={{ color: "red", fontSize: "95%", marginRight: 5 }} />
                </Typography>
            ];
        } else {
            if (splitPage[2] == "") {
                symbol = [
                    <Typography>
                        <CheckIcon style={{ color: "green", fontSize: "95%", marginRight: 5}} />
                    </Typography>
                ];
            } else {
                symbol = [
                    <Typography>
                        <CheckIcon style={{ color: "green", fontSize: "95%", marginRight: 5}} />
                    </Typography>
                ];
            }
        }
        let opacityPageType;
        let vizIcon;
        //currently hiding the tab
        if (splitPage[2] == "") {
            let tempPageType;
            if (pageType == "") {
                tempPageType = dataType;
            } else {
                tempPageType = pageType
            }
            opacityPageType = [
                <Typography onClick={() => console.log(page)} style={{opacity: 0.5, marginRight: 5}}>
                    {tempPageType}
                </Typography>
            ]
            vizIcon = [
                <Typography onClick={event => {event.stopPropagation(); unhidePage(labIndex, pageType, dataType, page);}} style={{opacity: 0.5}}>
                    <VisibilityIcon style={{ fontSize: "95%", marginRight: 5}} />
                </Typography>
            ]
        } else {
            opacityPageType = [
                <Typography style={{marginRight: 5}} onClick={() => {props.setPage(page); props.setLabIndex(labIndex); props.setTabType(pageType);}}>
                    {pageType}
                </Typography>
            ]
            vizIcon = [
                <Typography onClick={event => {event.stopPropagation(); hidePage(labIndex, pageType, dataType, page);}}>
                    <VisibilityOffIcon style={{ fontSize: "95%", marginRight: 5}} />
                </Typography>
            ]
        }
        if (pageType == "Overview" || pageType == "Simulation" || pageType == "Essay") {
            return(<TreeItem nodeId={nodeValue} label={
                <div style={{display: "flex"}}>
                {symbol}
                <Typography onClick={() => {props.setPage(page); props.setLabIndex(labIndex); props.setTabType(pageType);}}>
                    {pageType}
                </Typography>
                </div>
                } />)
        }
        let editCheck;
        if (splitPage[2] == "") {
            editCheck = [];
        } else {
            editCheck = [
                <Typography style={{marginRight: 7}} onClick={event => {event.stopPropagation(); setEditIndex(labIndex); setLabTitle(nav.Labs[labIndex].labName); setCurrentTab(dataType); setSelectedTab(pageType); setTabDialog(true);}}>
                    <EditIcon style={{ fontSize: "95%"}} />
                </Typography>
            ]
        }
        return(<TreeItem nodeId={nodeValue} label={
            <div style={{display: "flex"}}>
            {symbol}
            {opacityPageType}
            {editCheck}
            {vizIcon}
            </div>
            } />)
    }

    //Lab Functionalities
    function editLab() {
        //check if title has hyphen in it
        if (title.current.value.includes('-')) {
            setLabError(true);
            setLabTitleError("Your title cannot contain a hyphen.");
        } else {
            //check if duplicate
            let labTitles = nav.Labs.map(a => a.labName);
            labTitles.splice(editIndex, 1);
            if (labTitles.includes(title.current.value)) {
                setLabError(true);
                setLabTitleError("Your title cannot be the same as another lab or essay.")
            } else {
                setLabError(false);
                setLabTitleError("");
            }
        }
        if (!labTitleError) {
            //check if lab lock is different
            let lockDiff;
            if ((initialLockStatus && labLockStatus == "unlocked") || (!initialLockStatus && labLockStatus == "locked")) {
                //console.log("no change");
                //lock status is unchanged
                lockDiff = false;
            } else {
                //lock status is changed
                lockDiff = true;

            }
            //check if title is different
            let titleDiff;
            if (title.current.value != labTitle) {
                titleDiff = true;
            } else {
                titleDiff = false;
            }
            //title and lock status are different
            if (titleDiff && lockDiff) {
                //get underscored lab title for renaming pages
                let underscoreLabTitle = title.current.value.split(" ").join('_');
                let newLabs = nav.Labs;
                let oldLabName = nav.Labs[editIndex].labName;
                let pageKeys = Object.keys(nav.Labs[editIndex].pages);
                //store all previous pages
                let pages = [];
                //store all new pages
                let newPages = [];
                pageKeys.forEach(key => {
                    let labPage = nav.Labs[editIndex].pages[key];
                    pages.push(labPage);
                    let splitPage = labPage.split("-");
                    splitPage[1] = underscoreLabTitle;
                    let newPage = splitPage.join("-");
                    newPages.push(newPage);
                    //update lab's page in newLab
                    newLabs[editIndex].pages[key] = newPage;
                });
                //Update sim too
                let simPage = nav.Labs[editIndex].simulation;
                pages.push(simPage);
                let splitSim = simPage.split("-");
                splitSim[1] = underscoreLabTitle;
                let newSim = splitSim.join("-");
                newPages.push(newSim);
                newLabs[editIndex].simulation = newSim;
                newLabs[editIndex].labName = title.current.value;
                newLabs[editIndex].unlocked = !initialLockStatus;
                //update lab title
                //update the pages in the navigation template
                axios
                .post("/api/pagecreate/updatelab", {
                    lesson: lessonName,
                    labs: newLabs,
                    pages: pages,
                    newPages: newPages,
                    newLabName: title.current.value,
                    oldLabName: oldLabName
                })
                .then(result => {
                    //console.log(result);
                    setLabTitle("");
                    setEditLabDialog(false);
                    setEditIndex(-1);
                    setLabLockStatus("");
                    setInitialLock(false);
                    setNavUpdate(!navUpdated);
                })
                .catch(error => {
                    console.log(error);
                })
            } else if (titleDiff && !lockDiff) {
                //only title is different
                //get underscored lab title for renaming pages
                let underscoreLabTitle = title.current.value.split(" ").join('_');
                let newLabs = nav.Labs;
                let oldLabName = nav.Labs[editIndex].labName;
                let pageKeys = Object.keys(nav.Labs[editIndex].pages);
                //store all previous pages
                let pages = [];
                //store all new pages
                let newPages = [];
                pageKeys.forEach(key => {
                    let labPage = nav.Labs[editIndex].pages[key];
                    pages.push(labPage);
                    let splitPage = labPage.split("-");
                    splitPage[1] = underscoreLabTitle;
                    let newPage = splitPage.join("-");
                    newPages.push(newPage);
                    //update lab's page in newLab
                    newLabs[editIndex].pages[key] = newPage;
                });
                //Update sim too
                let simPage = nav.Labs[editIndex].simulation;
                pages.push(simPage);
                let splitSim = simPage.split("-");
                splitSim[1] = underscoreLabTitle;
                let newSim = splitSim.join("-");
                newPages.push(newSim);
                newLabs[editIndex].simulation = newSim;
                newLabs[editIndex].labName = title.current.value;
                //update lab title
                //update the pages in the navigation template
                axios
                .post("/api/pagecreate/updatelab", {
                    lesson: lessonName,
                    labs: newLabs,
                    pages: pages,
                    newPages: newPages,
                    newLabName: title.current.value,
                    oldLabName: oldLabName
                })
                .then(result => {
                    //console.log(result);
                    setLabTitle("");
                    setEditLabDialog(false);
                    setEditIndex(-1);
                    setLabLockStatus("");
                    setInitialLock(false);
                    setNavUpdate(!navUpdated);
                })
                .catch(error => {
                    console.log(error);
                })
            } else if (!titleDiff && !lockDiff) {
                //console.log("nothing changed");
                setLabTitle("");
                setEditLabDialog(false);
                setEditIndex(-1);
                setLabLockStatus("");
                setInitialLock(false);
            } else {
                //just lock status changed
                let newLabs = nav.Labs;
                newLabs[editIndex].unlocked = !initialLockStatus;
                axios
                .post("/api/pagecreate/changelablock", {
                    lesson: lessonName,
                    labs: newLabs,
                })
                .then(result => {
                    //console.log(result);
                    setLabTitle("");
                    setEditLabDialog(false);
                    setEditIndex(-1);
                    setLabLockStatus("");
                    setInitialLock(false);
                    setNavUpdate(!navUpdated);
                })
                .catch(error => {
                    console.log(error);
                })
            }
        }
    }

    function shiftLabUp(labIndex) {
        //shift up
        let labs = nav.Labs;
        const temp = labs[labIndex - 1];
        labs[labIndex -1] = labs[labIndex];
        labs[labIndex] = temp;
        //if index is 1,  it's the new first lab and needs to be unlocked
        //unless it was already unlocked, then leave them both unlocked
        if (labIndex == 1) {
            let lockStatus = labs[0].unlocked;
            labs[0].unlocked = true;
            labs[1].unlocked = lockStatus;
        }
        //save the new labs
        axios
        .post("/api/pagecreate/swaplabs", {
            labs: labs,
            lesson: lessonName
        })
        .then(result => {
            //console.log(result);
            setNavUpdate(!navUpdated);
        })
        .catch(error => {
            console.log(error);
        })
    }

    function shiftLabDown(labIndex) {
        //shift down
        let labs = nav.Labs;
        const temp = labs[labIndex + 1];
        labs[labIndex + 1] = labs[labIndex];
        labs[labIndex] = temp;
        //if shifted index is 0, it means the new 0 needs to be unlocked
        if (labIndex == 0) {
            let lockStatus = labs[labIndex].unlocked;
            labs[labIndex].unlocked = true;
            //if previous item was locked, lock the item in position 1 now
            labs[labIndex + 1].unlocked = lockStatus;
        }
        //save the new labs
        axios
        .post("/api/pagecreate/swaplabs", {
            labs: labs,
            lesson: lessonName
        })
        .then(result => {
            //console.log(result);
            setNavUpdate(!navUpdated);
        })
        .catch(error => {
            console.log(error);
        })
    }

    function deleteLab() {
        //delete
        let newNav = nav;
        let labNameToRemove = nav.Labs[deleteIndex].labName;
        newNav.Labs.splice(deleteIndex, 1);
        axios
        .post("/api/pagecreate/deletelab", {
            lesson: lessonName,
            labs: newNav.Labs,
            labNameToRemove: labNameToRemove
        })
        .then(result => {
            //console.log(result);
            setNavUpdate(!navUpdated);
        })
        .catch(error => {
            console.log(error);
        })
        //reset fields
        setDeleteIndex(-1);
        setLabTitle("");
        setOpenDeleteDialog(false);
    }

    function createNewLab() {
        //When a new lab is created, pages will be created for all relevant pages
        //Lab: overview, experiment, data, analysis, simulation
        //Essay: overview, simulation
        let labTitles = nav.Labs.map(a => a.labName);
        //Check that lab title isn't empty
        let titleCheck;
        if (newLabTitle.current.value == "") {
            setNewLabTitleErrorText("New lab or essay cannot be blank.")
            setNewLabTitleError(true);
            titleCheck = false;
        } else if (labTitles.includes(newLabTitle.current.value)) {
            //Check that lab title isn't a duplicate
            setNewLabTitleErrorText("New lab or essay cannot have the same name as another lab or essay.");
            setNewLabTitleError(true);
            titleCheck = false;
        } else {
            setNewLabTitleErrorText("");
            setNewLabTitleError(false);
            titleCheck = true;
        }
        //Check that a labType has been selected
        let typeCheck;
        //check if lab type has been selected
        if (labType == "") {
            setNewLabTypeErrorText("You must select lab or essay.");
            setNewLabTypeError(true);
            typeCheck = false;
        } else {
            setNewLabTypeErrorText("");
            setNewLabTypeError(false);
            typeCheck = true;
        }
        //Check that there are no errors
        if (titleCheck && typeCheck) {
            //No errors, generate new lab or essay
            let labTemplate = {}
            let pages = [];
            let pageTypes = [];
            if (labType == "lab") {
                //New Lab Template
                //I think 'lab' is a dead field in labs but not entirely sure...
                let labCount = nav.Labs.length;
                labTemplate = {lab: 'lab' + labCount.toString(), labName: newLabTitle.current.value, type: 'lab', pages: {}, tabTitles: {experiment: 'Experiment', data: 'Data', analysis: 'Analysis'}, simulation: '', completed: false, unlocked: false}
                let underscoreLessonName = lessonName.split(" ").join("_");
                let underscoreLabTitle = newLabTitle.current.value.split(" ").join("_");
                let newPages = {overview: underscoreLessonName + '-' + underscoreLabTitle + '-Overview-' + generateCode(6) + "temp", experiment: underscoreLessonName + '-' + underscoreLabTitle + '-Experiment-' + generateCode(6) + "temp", data: underscoreLessonName + '-' + underscoreLabTitle + '-Data-' + generateCode(6) + "temp", analysis: underscoreLessonName + '-' + underscoreLabTitle + '-Analysis-' + generateCode(6) + "temp"};
                labTemplate.pages = newPages;
                labTemplate.simulation = underscoreLessonName + "-" + underscoreLabTitle + "-Sim-" + generateCode(6) + "temp";
                //pages to create
                //tabs
                pages.push(labTemplate.pages['overview']);
                pages.push(labTemplate.pages['experiment']);
                pages.push(labTemplate.pages['data']);
                pages.push(labTemplate.pages['analysis']);
                //sim
                pages.push(labTemplate.simulation);
                pageTypes = ['overview', 'experiment', 'data', 'analysis', 'sim']
            } else {
                //New Essay Template
                let underscoreLessonName = lessonName.split(" ").join("_");
                let underscoreLabTitle = newLabTitle.current.value.split(" ").join("_");
                labTemplate = {type: "essay", labName: newLabTitle.current.value, pages: {overview: underscoreLessonName + '-' + underscoreLabTitle + '-Overview-' + generateCode(6) + "temp"}, simulation: underscoreLessonName + "-" + underscoreLabTitle + "-Sim-" + generateCode(6) + "temp", completed: false, unlocked: false}
                pages.push(labTemplate.pages['overview']);
                pages.push(labTemplate.simulation);
                pageTypes = ['essay', 'sim'];
            }
            let newLabs = nav.Labs;
            if (newLabs.length == 0) {
                labTemplate.unlocked = true;
            }
            newLabs.push(labTemplate);
            axios
            .post("/api/pagecreate/createnewlab", {
                lesson: lessonName,
                labs: newLabs,
                pages: pages,
                pageTypes: pageTypes,
                newLabName: newLabTitle.current.value
            })
            .then(result => {
                //console.log(result);
                setNewLabDialog(false);
                setNewLabType("");
                setNewLabTitleErrorText("");
                setNewLabTitleError(false);
                setNewLabTypeErrorText("");
                setNewLabTypeError(false);
            })
            .catch(error => {
                console.log(error);
            })
        } else {
            //console.log("errors");
        }
    }

    function hidePage(labIndex, pageType, dataType, page) {
        let labs = nav.Labs;
        let pageSplit = page.split("-");
        //blank out the tab name to hide it
        pageSplit[2] = "";
        let newPage = pageSplit.join("-");
        //update the lab's corresponding page
        let pageProperty = dataType.toLowerCase();
        labs[labIndex].pages[pageProperty] = newPage;
        //update the corresponding tabTitle too
        labs[labIndex].tabTitles[pageProperty] = "";
        axios
        .post("/api/pagecreate/hidepage", {
            lesson: lessonName,
            labs: labs,
            page: page,
            newPage: newPage,
        })
        .then(result => {
            //console.log(result);
            setNavUpdate(!navUpdated);
        })
        .catch(error => {
            console.log(error);
        })
    }

    function unhidePage(labIndex, pageType, dataType, page) {
        let labs = nav.Labs;
        let pageSplit = page.split("-");
        //replace '' in page with pageType
        pageSplit[2] = dataType;
        let newPage = pageSplit.join("-");
        //update the lab's corresponding page
        let pageProperty = dataType.toLowerCase();
        labs[labIndex].pages[pageProperty] = newPage;
        //update the lab's corresponding tabTitle
        labs[labIndex].tabTitles[pageProperty] = dataType;
        axios
        .post("/api/pagecreate/unhidepage", {
            lesson: lessonName,
            labs: labs,
            page: page,
            newPage: newPage,
        })
        .then(result => {
            //console.log(result);
            setNavUpdate(!navUpdated);
        })
        .catch(error => {
            console.log(error);
        })
    }

    function editTab() {
        let newTab = newTabName.current.value;
        if (newTab == "") {
            setTabTitleErrorText("The tab title cannot be blank. If you want to hide the tab, click the eye.")
            setTabTitleError(true);
        } else if (newTab.includes("-")) {
            setTabTitleErrorText("The tab title cannot contain hyphens.");
            setTabTitleError(true);
        } else {
            setTabTitleErrorText("");
            setTabTitleError(false)
            //update the tabTitles
            let labs = nav.Labs;
            //tab type being edited
            let tabProperty = currentTab.toLowerCase();
            //update tab name
            labs[editIndex].tabTitles[tabProperty] = newTab;
            //update the page
            let page = labs[editIndex].pages[tabProperty];
            let splitPage = page.split("-");
            splitPage[2] = newTab;
            let newPage = splitPage.join("-");
            //update navigation with new page
            labs[editIndex].pages[tabProperty] = newPage;
            axios
            .post("/api/pagecreate/updatetabtitles", {
                lesson: lessonName,
                labs: labs,
                oldPage: page,
                newPage: newPage
            })
            .then(result => {
                //console.log(result);
                setNavUpdate(!navUpdated);
                setLabTitle("");
                setCurrentTab("");
                setEditIndex(-1);
                setSelectedTab("");
                setTabDialog(false);
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    return (
        <div>
            <TreeView
                aria-label="lesson navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{ height: 600, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
            >
                <TreeItem nodeId="0" label={
                    <div style={{display: "flex"}}>
                    {lessonName}
                    <Typography style={{marginLeft: 7, marginTop: 2}} onClick={event => {event.stopPropagation(); setNewLabDialog(true)}}>
                      <AddIcon style={{ fontSize: "95%"}} />
                    </Typography>
                    <Typography style={{marginLeft: 7, marginTop: 2}} onClick={event => {event.stopPropagation(); props.setPage("")}}>
                        <DoDisturbIcon style={{ fontSize: "95%"}} />
                    </Typography>
                  </div>
                }>
                {generateHome()}
                {generateTree()}
                </TreeItem>
            </TreeView>
            <Dialog
                open={newLabDialog}
                onClose={() => {}}
                aria-labelledby="alert-new-lab-title"
                aria-describedby="alert-new-lab-dialog"
            >
                <DialogTitle id="alert-new-lab-title">
                    New Lab or Essay
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-new-lab-dialog">
                        Please enter a unique name for the lab or essay.
                        <br/>
                        <br/>
                        <TextField
                            error={newLabTitleError}
                            id="outlined-lab-title"
                            label="Lab Title"
                            defaultValue=""
                            inputRef={newLabTitle}
                            helperText={newLabTitleErrorText}
                        />
                        <br/>
                        <br/>
                        <FormControl>
                            <FormLabel id="row-radio-essay-or-lab">Lab or Essay</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-essay-or-lab"
                                name="row-radio-essay-lab"
                                value={labType}
                                onChange={(event) => {setNewLabType(event.target.value);}}
                            >
                                <FormControlLabel value="lab" control={<Radio />} label="Lab" />
                                <FormControlLabel value="essay" control={<Radio />} label="Essay" />
                            </RadioGroup>
                            <div style={{fontSize: '12px', color: '#d63e3e'}}>{newLabTypeErrorText}</div>
                        </FormControl>
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => {setNewLabDialog(false); setNewLabType(""); setNewLabTitleErrorText(""); setNewLabTitleError(false); }} variant="contained">Cancel</Button>
                        <Button variant="contained" onClick={() => createNewLab()}>Save</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                open={deleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="alert-delete-title"
                aria-describedby="alert-delete-dialog"
            >
                <DialogTitle id="alert-delete-title">
                    Deleting -- {labTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-delete-description">
                        Are you sure you want to delete this lab? All of the pages will be lost and cannot be recovered. If you are sure you want to delete this lab, please click delete.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ marginRight: "70%" }} onClick={() => {setLabTitle(""); setOpenDeleteDialog(false); setDeleteIndex(-1)}}>Close</Button>
                    <Button variant="contained" style={{ backgroundColor: "red" }} onClick={() => deleteLab()}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={editLabDialog}
                onClose={() => setEditLabDialog(false)}
                aria-labelledby="alert-edit-lab-title"
                aria-describedby="alert-edit-lab-dialog"
            >
                <DialogTitle id="alert-edit-lab-title">
                    Edit Lab -- {labTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-edit-lab-description">
                        <br/>
                        <TextField
                            error={labTitleError}
                            id="outlined-lab-title"
                            label="Lab Title"
                            defaultValue={labTitle}
                            inputRef={title}
                            helperText={labTitleErrorText}
                            />
                        <br/>
                        <br/>
                        <FormControl>
                            <FormLabel id="row-radio-lock-status">Locked/Unlocked</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-lock-status"
                                name="row-radio-lock"
                                value={labLockStatus}
                                onChange={(event) => setLabLockStatus(event.target.value)}
                            >
                                <FormControlLabel disabled={disableLock} value="locked" control={<Radio />} label="Locked" />
                                <FormControlLabel disabled={disableLock} value="unlocked" control={<Radio />} label="Unlocked" />
                            </RadioGroup>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setLabTitle(""); setEditLabDialog(false); setEditIndex(-1); setLabLockStatus(""); setInitialLock(false); }}>Cancel</Button>
                    <Button variant="contained" onClick={() => editLab()}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={tabDialog}
                onClose={() => setTabDialog(false)}
                aria-labelledby="alert-edit-tab-title"
                aria-describedby="alert-edit-tab-dialog"
            >
                <DialogTitle id="alert-edit-tab-title">
                    Edit '{currentTab}' Tab in {labTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-edit-tab-description">
                        <br/>
                        <TextField
                            error={tabTitleError}
                            id="outlined-lab-title"
                            label="Lab Title"
                            defaultValue={selectedTabType}
                            inputRef={newTabName}
                            helperText={tabTitleErrorText}
                            />
                        <br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setLabTitle(""); setCurrentTab(""); setEditIndex(-1); setSelectedTab(""); setTabDialog(false);}}>Cancel</Button>
                    <Button variant="contained" onClick={() => editTab()}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}