import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

export function QualtricsLinker(props) {
    console.log(props);
    console.log('qual');
    return (
        <Box textAlign="center">
            <Button key={props.fieldKey} component={Link} to={{ pathname: props.answers.urlLoc }} target="_blank">
                Click here for the survey
            </Button>
        </Box>
    )
}

export default QualtricsLinker;