import { createContext, useReducer } from "react";

export const FullClassContext = createContext();

const initialState = {
  notes: [],
  ideas: [],
  loading: false,
  error: null
};

const reducer = (state, action) => {
  const index = state.notes.findIndex(element => element.noteId === action.id )
  const newArray = [...state.notes];

  switch (action.type) {
    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload
      };
    case 'ADD_NOTE':
      return {
        ...state,
        notes: state.notes.filter(f => f._id !== action.payload._id).concat([action.payload])
      };
    case 'UPDATE_NOTE_IDEAID':
      newArray[index] = {...newArray[index], ideaId: action.payload};
      return {
        ...state,
        notes: newArray
      };
    case 'SET_IDEAS':
      return {
        ...state,
        ideas: action.payload
      };
    case 'ADD_IDEA':
      return {
        ...state,
        ideas: state.ideas.concat(action.payload)
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export const FullClassContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <FullClassContext.Provider value={[state, dispatch]}>
    {children}
  </FullClassContext.Provider>
};