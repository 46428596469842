import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Radio, RadioGroup, FormControl, FormLabel } from "@mui/material";

const Form = (props) => {
    const updateCount = (event) => {
        const newLength = parseInt(event.target.value);
        const length = props.data.options.length;
        let newArr;
        let newInputs;
        if (newLength < length) {
            newArr = props.data.options.slice(0, length - 1);
            newInputs = props.data.inputType.slice(0, length - 1);
        } else {
            newArr = [...props.data.options];
            newInputs = [...props.data.inputType];
            newArr[length] = { name: "", label: "", options: { filter: false, sort: false } };
            newInputs[length] = "alpha";
        }
        props.updateComponent("options", newArr);
        props.updateComponent("inputType", newInputs);
    }

    const deepCopy = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    }

    const updateColumn = (index, columnData) => {
        const columns = deepCopy(props.data.options);
        columns[index] = columnData;
        props.updateComponent("options", columns);
    }

    const updateInputType = (index, inputType) => {
        const inputs = deepCopy(props.data.inputType);
        inputs[index] = inputType;
        props.updateComponent("inputType", inputs);
    }

    return (
        <section>
            <Typography variant="h6" style={{ display: "inline-block" }}>
                Result Table
            </Typography>
            {props.buttons}
            <br />
            <TextField label="Title" value={props.data.title}
                onChange={(event) =>
                    props.updateComponent("title", event.target.value)} />
            <Typography style={{ display: "inline-block" }}>
                How many columns in the table?
            </Typography>
            <TextField
                id="count"
                type="number"
                value={props.data.options.length}
                style={{ width: 50, marginTop: -6, marginLeft: 10 }}
                onChange={updateCount}
            />

            {props.data.options.map((column, index) => (
                <section key={index}>
                    <TextField
                        id={`col${index}`}
                        label="Column Name"
                        value={column.label}
                        onChange={(event) =>
                            updateColumn(index, { ...column, label: event.target.value, name: event.target.value.toLowerCase()})
                        }
                    />
                    <FormControlLabel control={<Checkbox />} 
                    label="Filter" style={{ display: "inline-block", paddingTop: 10, paddingLeft: 5 }} checked={column.options.filter} 
                    onChange={(event) => updateColumn(index, {...column, options: {...column.options, filter: event.target.checked}})}
                    />
                    <FormControlLabel control={<Checkbox />} 
                    label="Sort" style={{ display: "inline-block", paddingTop: 10, marginRight: 20 }} checked={column.options.sort} 
                    onChange={(event) => updateColumn(index, {...column, options: {...column.options, sort: event.target.checked}})}
                    />
                    <FormControl>
                        <FormLabel id={"result-table-inputtype" + index}>Input Type</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby={"result-table-inputtype" + index}
                            name={"result-table-radio-group" + index}
                            value={props.data.inputType[index]}
                            onChange={(event) => updateInputType(index, event.target.value)}
                        >
                            <FormControlLabel value="alpha" control={<Radio />} label="Alphanumeric" />
                            <FormControlLabel value="num" control={<Radio />} label="Numeric" />
                            <FormControlLabel value="yn" control={<Radio />} label="Yes/No" />
                            <FormControlLabel value="long" control={<Radio />} label="Long Text" />
                        </RadioGroup>
                    </FormControl>
                </section>
            ))}

        </section>

    )
}


const createNew = (authorId) => {
    return {
        answer: {
            answer: [ ],
        },
        comp: {
            type: "PageResultTable",
            props: {
                title: "",
                fieldKey:
                    authorId + "-resulttable-" + Math.random().toString(36).substr(2, 6),
                options: [],
                inputType: []
            },
        },
    };
}

export const PageResultTable = { createNew, Form, name: "Result Table" };

export default PageResultTable;
