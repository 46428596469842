/*

    This is a page component for a slider bar.

    When this component is called, it is expected that props will be passed
    to it. The props are necessary to populate the element.


    *Required Props*

    question: The text body of the question, or what is being asked.
    defaultval: The starting value on the slider.
    sstep: The size of the step when sliding the bar.
    smin: The minimum value on the slider.
    smax: The maximum value on the slider.


    *Additional Props*

    smarks: Tic marks along the slider to give more information.
        -> If no mark array is passed, a blank one is used with no tics


    Example props.

    question="How sure are you?"
    defaultval={50}
    sstep={10}
    smin={0}
    smax={100}

*/

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

// Within root, width is the length of the slider bar.
const useStyles = makeStyles({
  root: {
    width: 250,
  },
});

export function PageSlider(props) {
  //Call the styling from above
  const classes = useStyles();

  const valueUpdate = (__event, val) => {
    props.returnData({ answer: val }, props.fieldKey);
  };

  return (
    <div className={classes.root}>
      <Typography gutterBottom>{props.question}</Typography>
      <Slider
        defaultValue={props.answers.answer}
        getAriaValueText={(value) => `${value}%`}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={props.sstep}
        min={props.smin}
        max={props.smax}
        marks={props.smarks}
        onChangeCommitted={valueUpdate}
      />
      <br />
    </div>
  );
}

export default PageSlider;
