import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SlateEditor from "../../../slate/Editor";

// required props
// props - contains all component data
// updateComponent - callback to update component with new data
// updateAnswer - callback to update answers with new data

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Text
      </Typography>
      {props.buttons}
      <SlateEditor 
        text={props.data.text}
        onChange={value => {
          props.updateComponent("text", value);
        }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "PageRichText",
      props: {
        fieldKey: authorId + "-richtext-" + Math.random().toString(36).substr(2, 6),
        text: [
          {
            type: "paragraph",
            children: [{ text: "" }]
          }
        ]
      },
    },
  };
}

export const PageRichText = { createNew, Form, name: "RichText" };

export default PageRichText;
