import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { DeleteForever, ArrowUpward, ArrowDownward } from "@material-ui/icons";

// imports all componnt editor forms
import * as ComponentForms from "../componentforms";

//For page preview
//import ComponentCombiner from "../../../utils/ComponentCombiner";

//To get author id for key creation
import store from "../../../store";

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginRight: 15,
    marginTop: 20,
  },
  compForm: {
    padding: theme.spacing(3),
    width: 452,
    marginTop: 10,
  },
  step: {
    marginTop: 25,
  },
  stepLabel: {
    marginTop: -10,
  },
  selectStyle: {
    margin: theme.spacing(1),
    marginRight: 15,
    marginTop: 20,
    minWidth: 150,
  },
}));

// required props
// data - contains all component data
// updateComponent - callback to update component with new data
// updateAnswer - callback to update answers with new data

function Form(props) {
  const classes = useStyles();

  const updateCount = (event) => {
    const newLength = parseInt(event.target.value);
    const length = props.data.steps.length;
    let newArr;
    if (newLength < length) {
      newArr = props.data.steps.slice(0, newLength);
    } else {
      newArr = [...props.data.steps];
      newArr[length] = { label: "", description: [] };
    }
    props.updateComponent("steps", newArr);
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateStep(index, stepData) {
    const steps = deepCopy(props.data.steps);
    steps[index] = stepData;
    props.updateComponent("steps", steps);
  }

  const swapComponents = (stepNum, i, j) => {
    const step = { ...props.data.steps[stepNum] };
    //storing the swap otherwise it overwrites one of them
    let tempItem = props.data.steps[stepNum].description[j];
    let otherItem = props.data.steps[stepNum].description[i];
    step.description[i] = tempItem;
    step.description[j] = otherItem;
    updateStep(stepNum, step);
  };

  const handleComponentEdit = (stepNum, propName, value, fieldKey) => {
    const step = { ...props.data.steps[stepNum] };
    const index = step.description.findIndex(
      (comp) => comp.props.fieldKey === fieldKey
    );
    step.description[index].props = {
      ...step.description[index].props,
      [propName]: value,
    };
    updateStep(stepNum, step);
  };

  const addComponent = (stepNum, comp) => {
    const step = { ...props.data.steps[stepNum] };
    const newComp = ComponentForms[comp].createNew(
      store.getState().auth.user.authorId
    );
    step.description.push(newComp.comp);
    props.updateAnswer(newComp.answer, newComp.comp.props.fieldKey);
    updateStep(stepNum, step);
  };

  /* 
  
  Label is not really being used, and actually causes bad behavior when used.
  The code to add it back would need to be added to the steps generation below,
  if it is ever used.

  <TextField
            className={classes.stepLabel}
            id={`label${stepIndex}`}
            label="Label"
            value={step.label}
            onChange={(event) =>
              updateStep(stepIndex, { ...step, label: event.target.value })
            }
            placeholder=""
          />

  */

  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Content Slider
      </Typography>
      {props.buttons}
      <br />
      <Typography style={{ display: "inline-block" }}>
        How many slider pages?
      </Typography>
      <TextField
        id="count"
        type="number"
        value={props.data.steps.length}
        style={{ width: 50, marginTop: -6, marginLeft: 10 }}
        onChange={updateCount}
      />
      <br />
      {props.data.steps.map((step, stepIndex) => (
        <div key={stepIndex} className={classes.step}>
          <hr />
          <Typography style={{ fontSize: 16, fontWeight: 500, marginBottom: 7 }}>
            Page {stepIndex + 1}
          </Typography>
          <div>
            {step.description.map((comp, index) => {
              // Get form for component
              const Form =
                ComponentForms[comp.type] && ComponentForms[comp.type].Form;
              const answer = props.answerObjects[comp.props.fieldKey];
              if (!Form)
                return console.log(`No edit form for ${comp.type} found.`);

              const buttons = (
                <div style={{ float: "right" }}>
                  <IconButton
                    disabled={index === 0}
                    onClick={() => swapComponents(stepIndex, index, index - 1)}
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    disabled={index === step.description.length - 1}
                    onClick={() => swapComponents(stepIndex, index, index + 1)}
                  >
                    <ArrowDownward />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      const newDescription = deepCopy(step.description);
                      newDescription.splice(index, 1);
                      updateStep(stepIndex, {
                        ...step,
                        description: newDescription,
                      });
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </div>
              );

              // Render component edit form with callbacks for updating data
              return (
                <Paper
                  key={comp.props.fieldKey}
                  elevation={2}
                  className={classes.compForm}
                >
                  <Form
                    updateComponent={(propName, value) =>
                      handleComponentEdit(
                        stepIndex,
                        propName,
                        value,
                        comp.props.fieldKey
                      )
                    }
                    updateAnswer={(newAnswer) =>
                      props.updateAnswer(newAnswer, comp.props.fieldKey)
                    }
                    buttons={buttons}
                    data={comp.props}
                    answer={answer}
                  />
                </Paper>
              );
            })}
            <Select
              className={classes.selectStyle}
              value="+ Add Component"
              onChange={(event) => addComponent(stepIndex, event.target.value)}
            >
              <MenuItem disabled value="+ Add Component">
                + Add Component
              </MenuItem>
              {Object.keys(ComponentForms)
                .filter((comp) => comp !== "PageContentSlider")
                .map((comp) => (
                  <MenuItem key={comp} value={comp}>
                    {ComponentForms[comp].name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      ))}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "PageContentSlider",
      props: {
        fieldKey:
          authorId +
          "-contentslider-" +
          Math.random().toString(36).substr(2, 6),
        steps: [],
      },
    },
  };
}

export const PageContentSlider = { createNew, Form, name: "Content Slider" };

export default PageContentSlider;
