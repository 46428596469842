import AnalyticDashboard from "../../dashboard/teacher/AnalyticDashboard"
import { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function TeacherAnalyticDashboard() {

    //teacher list
    const [teachers, setTeachers] = useState([]);
    //selected teacher
    const [teacher, setTeacher] = useState("");

    useEffect(() => {
        axios
        .get("/api/users/getAllTeachers")
        .then(result => {
            setTeachers(result.data);
        })
        .catch(error => {
            console.log(error);
        })
    }, []);

    const handleChange = (event) => {
        setTeacher(event.target.value);
    }

    function renderDashboard() {
        if (teacher != "") {
            return (<AnalyticDashboard selectedTeacher={teacher} leftMargin="none" />);
        } else {
            return null;
        }
    }

    return(
        <div style={{marginLeft: 250, marginTop: 12}}>
            <FormControl style={{width: 350}}>
                <InputLabel id="simple-select-label">Teachers</InputLabel>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={teacher}
                    label="Teacher"
                    onChange={handleChange}
                >
                    {teachers.map(user => {
                        return <MenuItem key={user._id} value={user._id}>{user.first_name} {user.last_name}</MenuItem>
                    })}
                </Select>
            </FormControl>
            {renderDashboard()}
        </div>
    )
}