import { createTheme, styled } from '@material-ui/core/styles';
import { SpeedDial } from '@material-ui/lab';
import { createTheme as createMuiTheme } from '@mui/material/styles';

// Overall theme
const theme = {
    typography: {
      fontFamily: ['"Open Sans"', '"Segoe UI"', '"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif',].join(','),
      h1: {
          fontWeight: 300,
          fontSize: 32,
          color: "#282728",
      },
      h2: {
          fontWeight: 300,
          fontSize: 24,
          color: "#646569",
      },
      h3: {
          fontWeight: 300,
          fontSize: 19,
      },
      subtitle1: {
          fontWeight: 600,
          fontSize: 24,
      },
      subtitle2: {
          fontWeight: 600,
          fontSize: 19,
      },
    },
    palette: {
      type: "light",
      mode: "light",
      primary: {
        main: "#0479a8",
        dark: "#004d79",
      },
      secondary: {
          main: "#004d79",
      },
      text: {
          primary: "#494949",
          secondary: "#646569",
      },
      action: {
          active: "#a80479",
      },
      info: {
          main: "#adadad",
          contrastText: "#000",
      }
    },
    overrides: {
      //Grid - fixes horizontal scroll bar
      MuiGrid: {
          container: {
              maxWidth: "100%",
          },
      },
      MuiButton: {
          outlinedPrimary: {
              border: "1px solid #0479a8",
          },
      },
      MuiLink: {
          root: {
              color: "#a80479",
              '&:hover': {
                  color: "#004d79",
              },
          },
      },
    },
  };
export const adminTheme = createTheme(theme);
export const adminMuiTheme = createMuiTheme(theme);

// Styled components
// <StyledSpeedDial> Add Users, Add Schools, etc.
export const StyledSpeedDial = styled(({ ...other }) => <SpeedDial {...other} />)({
    alignItems: "flex-end",
    '& .MuiSpeedDial-actions': {
        paddingTop: "40px !important",
        marginRight: "3px",
        backgroundColor: "rgb(250,250,250)",
    },
    '& .MuiSpeedDial-actionsClosed': {
        background: "inherit"
    },
    '& .MuiSpeedDial-actions.': {
        margin: "5px",
        '& a': {
            color: adminTheme.palette.text.secondary,
            display: "flex",
        },
    },
    '& .MuiSpeedDialAction-staticTooltipLabel': {
        fontSize: 14,
        lineHeight: "inherit",
        color: adminTheme.palette.text.secondary,
    },
});
