import { createContext, useReducer } from "react";

export const NoteContext = createContext();

const initialState = {
  notes: [],
  roomUsers: [],
  doneUsers: [],
  noteZindex: 3,
  loading: false,
  error: null
};

const reducer = (state, action) => {
  const index = state.notes.findIndex(element => element.noteId === action.id )
  const newArray = [...state.notes];

  switch (action.type) {
    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload
      };
    case 'ADD_NOTE':
      return {
        ...state,
        notes: state.notes.concat(action.payload)
      };
    case 'UPDATE_NOTE_VOTE':
      newArray[index] = {...newArray[index], vote: action.payload};
      return {
        ...state,
        notes: newArray
      };
    case 'UPDATE_NOTE_COMBINED':
      newArray[index] = {...newArray[index], combined: true};
      return {
        ...state,
        notes: newArray
      };
    case 'REMOVE_NOTE':
      return {
        ...state,
        notes: state.notes.filter(note => note.noteId !== action.payload)
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };
    case 'SET_ROOM_USERS':
      return {
        ...state,
        roomUsers: action.payload
      };
    case 'REMOVE_ROOM_USER':
      return {
        ...state,
        roomUsers: state.roomUsers.filter(f => f.userId !== action.payload)
      };
    case 'ADD_DONE_USER':
      return {
        ...state,
        doneUsers: state.doneUsers.filter(f => f !== action.payload).concat([action.payload])
      };
    case 'REMOVE_DONE_USER':
      return {
        ...state,
        doneUsers: state.doneUsers.filter(f => f !== action.payload)
      };
    case 'CLEAR_DONE_USER':
      return {
        ...state,
        doneUsers: []
      };
    case 'NOTE_ZINDEX':
      return{
        ...state,
        noteZindex: ++state.noteZindex
      };
    default:
      return state;
  }
};

export const NoteContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <NoteContext.Provider value={[state, dispatch]}>
    {children}
  </NoteContext.Provider>
};