import { TextField, Typography } from "@mui/material";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Button with Draggable Text Inside
      </Typography>
      {props.buttons}
      <TextField
        id="text"
        label="Text"
        value={props.data.children}
        onChange={(event) =>
          props.updateComponent("children", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
        type: "PageDragTextButton",
        props: {
            fieldKey:
                authorId + "-txtdrag-" + Math.random().toString(36).substr(2, 6),
            children: "",
      },
    },
  };
}

export const PageDragTextButton = { createNew, Form, name: "Draggable Text Field" };

export default PageDragTextButton;
