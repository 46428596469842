import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core/";
import Button from "@mui/material/Button";
// import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

export function PageResultTable(props) {

  const { activeLab } = props;

  //Hooks
  const [simData, setData] = useState(props.answers?.answer || []);
  const [rowsToDelete, setRowsToDelete] = useState({});
  // const [deleteReason, setReason] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    // setReason("");
    setOpen(false);
  };

  const handleClose = () => {
    // if (deleteReason.length > 0) {
    //delete functionality here
    let trialIndex = rowsToDelete.data[0].index;
    let newData = [...simData];
    newData.splice(trialIndex, 1);
    setData(newData);
    props.returnData({ answer: newData }, props.fieldKey);
    setOpen(false);
    // } else {
    //   setOpen(false);
    // }
  };

  //in the future, these can be included in the page creator if wanted
  const options = {
    //filterType: 'checkbox',
    selectableRows: "single",
    download: false,
    print: false,
    search: false,
    filter: true,
    pagination: false,
    onRowsDelete: async (rowsDeleted) => {
      //console.log(rowsDeleted);
      setRowsToDelete(rowsDeleted);
      await handleClickOpen();
    },
  };

  return (
    <React.Fragment>
      <Button color="primary" variant="contained" style={{marginLeft: '80%', marginTop: -75}} component={Link} to={"/notebook/" + activeLab + "/simulation"}>Back to Simulation</Button>
      <MUIDataTable
        key={props.fieldKey}
        title={props.title}
        data={simData}
        columns={props.options}
        options={options}
      />
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Delete one of your trials.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete one of your trials?{" "}
            {/* If so, please
            enter the reason why you want to get rid of the trial you selected. */}
          </DialogContentText>
          {/* <TextField
            id="delreason"
            onChange={(e) => {
              setReason(e.target.value);
            }}
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default PageResultTable;
