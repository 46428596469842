import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Input
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
      <br/>
      <br/>
      <FormControl>
        <FormLabel id="input-length">Input Length</FormLabel>
        <br/>
        <RadioGroup
          row
          aria-labelledby="input-length"
          name="input-length-group"
          value={props.data.inputLength}
          onChange={(event) =>
            props.updateComponent("inputLength", event.target.value)
          }
        >
          <FormControlLabel style={{paddingLeft: 10}} value="short" control={<Radio />} label="Short" />
          <FormControlLabel style={{paddingLeft: 5}} value="medium" control={<Radio />} label="Medium" />
          <FormControlLabel style={{paddingLeft: 5}} value="long" control={<Radio />} label="Long" />
        </RadioGroup>
    </FormControl>
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: "",
    },
    comp: {
      type: "PageInput",
      props: {
        fieldKey:
          authorId + "-numinput-" + Math.random().toString(36).substr(2, 6),
        question: "",
        inputLength: "short",
      },
    },
  };
}

export const PageInput = { createNew, Form, name: "Input" };

export default PageInput;
