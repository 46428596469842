import { SET_CURRENT_NAVIGATION, SET_ACTIVE_LAB, SET_ACTIVE_TABLE, SET_TABLE_KEY } from "../actions/types";

const initialState = {
    navigation: {}
};
  
// eslint-disable-next-line
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_NAVIGATION:
            return {
            ...state,
            navigation: action.payload
            };
        case SET_ACTIVE_LAB:
            return {
                ...state,
                Active_Lab: action.payload
            };
        case SET_ACTIVE_TABLE:
            return {
                ...state,
                Active_Table: action.payload
            };
        case SET_TABLE_KEY:
            return {
                ...state,
                Table_Key: action.payload
            }
        default:
            return state;
    }
  }
