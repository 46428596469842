/*

    TODO: TEST THIS WITH MULTIPLE ESSAYS!!

    The positioning of the speed dial and draggable are related to the size of the essay component.
    If it changes it's sizes, you'll need to change the coordinates in this file too.

*/

import React, { useEffect } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
//Speed dial imports
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
//Draggable components
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import PageSimpleResultTable from "./PageSimpleResultTable";
//Styling
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { ReactComponent as TableIcon } from "../../../../assets/Data.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    StaticTooltipLabel: {
      whiteSpace: "nowrap",
      maxWidth: "none",
      paddingLeft: 50,
    },
  })
);

export function PageTableButtons(props) {
  let studentId = props.navigation.Student_Id;

  //used to generate actions
  const [labs, setLabs] = React.useState(props.navigation.Labs);
  const [currentPosition, setPosition] = React.useState(props.activeLab);
  const [actions, setActions] = React.useState([]);
  const [table, setTable] = React.useState(false);
  const [result, setResultTable] = React.useState("");

  //used for speed dial
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();

  useEffect(() => {
    //get the pages that have result tables
    if (actions.length === 0) {
      for (var i = currentPosition - 1; i >= 0; i--) {
        if (i === 0 && labs[i].type === "lab") {
          let item = {
            icon: <FileCopyIcon />,
            name: labs[i].labName,
            tableLoc: labs[i].pages.data,
          };
          if (item.tableLoc !== "") {
            actions.push(item);
            setActions(actions.reverse());
          }
          break;
        }
        //another essay was found
        else if (labs[i].type === "lab") {
          let item = {
            icon: <FileCopyIcon />,
            name: labs[i].labName,
            tableLoc: labs[i].pages.data,
          };
          if (item.tableLoc !== "") {
            actions.push(item);
            setActions(actions);
          }
        } else {
          setActions(actions.reverse());
          break;
        }
      }
    }
  }, [actions, currentPosition, labs]);

  function generateSpeedDial() {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 120,
          right: 45,
          height: 1,
          transform: "translateZ(0px)",
          flexGrow: 1,
        }}
      >
        <SpeedDial
          ariaLabel="Result Tables"
          icon={
            <SpeedDialIcon
              sx={{ position: "absolute", top: 8, left: 8 }}
              icon={
                //<IconButton>
                //" "
                <TableIcon width={40} height={40} />
                //</IconButton>
              }
            />
          } // use "width = ? " in img to scale
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="down"
        >
          {actions.map((action) => (
            <SpeedDialAction
              classes={{ staticTooltipLabel: classes.StaticTooltipLabel }}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => {
                setTable(true);
                setResultTable(action.tableLoc);
              }}
            />
          ))}
        </SpeedDial>
      </Box>
    );
  }

  function generateDraggable() {
    if (table) {
      return (
        <Draggable defaultPosition={{ x: 200, y: -500 }}>
          <Paper
            elevation={5}
            style={{ maxWidth: 600, position: "absolute", zIndex: 1 }}
          >
            <IconButton
              style={{
                float: "right",
                paddingTop: 5,
                paddingBottom: 0,
                paddingRight: 5,
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setTable(false);
                setResultTable("");
              }}
            >
              <CloseIcon style={{ color: "red" }} />
            </IconButton>
            <br />
            <PageSimpleResultTable
              page={result}
              studentid={studentId}
              classroom={props.navigation.Classroom}
            />
          </Paper>
        </Draggable>
      );
    }
  }

  return (
    <div>
      {generateSpeedDial()}
      {generateDraggable()}
    </div>
  );
}

export default PageTableButtons;
