export const UserTypeMap = {
    "1": "Student",
    "2": "Teacher",
    "3": "Researcher"
}

// returns ['2050-2051','2049-2050','2048-2049',...] where the first element is the current academic year
export const getAcademicYears = () => {

    const today = new Date();
        
    let endYear = null;
    let academicYears = [];

    // Months are 0-indexed 
    // If the current month is July or later
    if(today.getMonth() > 6)
    {
        //currentAcademicYear = today.getFullYear().toString() + '-' + (today.getFullYear() + 1).toString()
        endYear = today.getFullYear() + 1;
    }
    else
    {
        //currentAcademicYear =  (today.getFullYear() -1 ).toString() + '-' + today.getFullYear().toString()
        endYear = today.getFullYear()
    }

    // First year in the database is 2022-2023
    for(let i = 2022; i < endYear; i++)
    {
        academicYears.push(i.toString() + '-' + (i+1).toString());
    }

    academicYears.reverse();

    return academicYears;

}