import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Chart from 'react-apexcharts';
import { Grid, Item } from '@mui/material';

export function BarGraph(props) {
    //analysis is a dict with counts
    //question is the string of the question

    let values = [];
    for (const [key, value] of Object.entries(props.values.analysis)) {
        values.push({'name': key, 'count': value});
    }
    if (values.length === 0) { return null; }

    let question = props.values.question.split(".")[0]

  let options = {
    chart: {
      type: 'boxPlot',
      height: 350
    },
    title: {
      text: 'Time Spent on Question',
      align: 'left'
    }};

    let plotOptions = {
      boxPlot: {
        colors: {
          upper: '#5C4742',
          lower: '#A5978B'
        }
      }
    };

    // sort array ascending
    const asc = arr => arr.sort((a, b) => a - b);

    const sum = arr => arr.reduce((a, b) => a + b, 0);

    const mean = arr => sum(arr) / arr.length;

    const quantile = (arr, q) => {
        const sorted = asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    const q25 = arr => quantile(arr, .25);

    const q50 = arr => quantile(arr, .50);

    const q75 = arr => quantile(arr, .75);

    const median = arr => q50(arr);

    function timeChart() {
        let timeValues = props.values.time
        if (timeValues.length > 0) {
            let minimumValue = Math.min(...timeValues);
            let maximumValue = Math.max(...timeValues);
            let medianValue = median(timeValues);
            let firstQuartile = q25(timeValues);
            let thirdQuartile = q75(timeValues);
            let series = [{type: 'boxPlot',
                  data: [
                    {
                      x: 'Question ' + question,
                      y: [minimumValue, firstQuartile, medianValue, thirdQuartile, maximumValue]
                    },
                  ]
                }
              ];
            return <Chart options={options} series={series} type="boxPlot" width={500} height={320} />;
        } else {
            return null;
        }
    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <p>{props.values.question}</p>
            </Grid>
            <Grid item xs={6}>
        <BarChart width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5, }} data={values}>
            <Bar dataKey="count" fill="green" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
        </BarChart>
        </Grid>
        <Grid item xs={6}>
        {timeChart()}
        </Grid>
        </Grid>
    );

}

export default BarGraph;