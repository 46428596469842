import React from "react";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as TableIcon } from "../../../assets/Data.svg";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import PageSimpleResultTable from "./DesignPage/PageSimpleResultTable";

export function PageDataTableButton(props) {
  const [showTable, setShowTable] = React.useState(false);

  let activeLab = props.activeLab;
  let dataPage;
  if (props.activeLab >= 0) {
    dataPage = props.navigation.Labs[activeLab].pages.data;
  }
  let studentId = props.navigation.Student_Id;

  function generateTableButton() {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 165,
          right: 45,
          height: 1,
          transform: "translateZ(0px)",
          flexGrow: 1,
        }}
      >
        <Fab
          color="primary"
          aria-label="data"
          onClick={() => setShowTable(!showTable)}
        >
          <TableIcon width={40} height={40} />
        </Fab>
      </Box>
    );
  }

  function generateDraggable() {
    if (showTable)
      return (
        <Draggable>
          <Paper
            elevation={1}
            style={{ maxWidth: 600, position: "absolute", zIndex: 1 }}
          >
            <IconButton
              style={{
                float: "right",
                paddingTop: 5,
                paddingBottom: 0,
                paddingRight: 5,
                backgroundColor: "transparent",
              }}
              onClick={() => setShowTable(false)}
            >
              <CloseIcon style={{ color: "red" }} />
            </IconButton>
            <br />
            <PageSimpleResultTable
              page={dataPage}
              studentid={studentId}
              classroom={props.navigation.Classroom}
            />
          </Paper>
        </Draggable>
      );
  }

  return (
    <div>
      {dataPage !== "" ? generateTableButton() : null}
      {generateDraggable()}
    </div>
  );
}

export default PageDataTableButton;
