import axios from "axios";
import jwt_decode from "jwt-decode";

import setAuthToken from "../utils/setAuthToken";

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
    SET_THEME
} from "./types";

import { createTheme } from "@material-ui/core/styles";
import { createTheme as createMuiTheme } from "@mui/material/styles";
import { adminTheme, adminMuiTheme } from "../ildlTheme";

//Action 1: Registering a user
export const registerUser = (userData, history) => dispatch => {
    axios
        .post("/api/users/register", userData)
        .then(res => history.push("/login")) //login page if successful
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

//Action 2: Login and get user token
export const loginUser = userData => dispatch => {
    axios
        .post("/api/users/login", userData)
        .then(res => {
            //Store the token locally if successful login
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            //Set token to Auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set the current user
            dispatch(setCurrentUser(decoded));
            //Use admin theme if user is not a student
            const userTheme = decoded?.access !== 1 ? adminTheme : createTheme({});
            const userMuiTheme = decoded?.access !== 1 ? adminMuiTheme : createMuiTheme({});
            dispatch(setTheme({material: userTheme, mui: userMuiTheme}));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload:  err.response.data
            })
        );
};

//Action 3: Set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

//Action 4: Loading user
export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

//Action 5: Logging user out
export const logoutUser = () => dispatch => {
    //Remove token from local storage
    localStorage.removeItem("jwtToken");
    //Remove auth header for future requests
    setAuthToken(false);
    //Setting isAuthenticated to false
    dispatch(setCurrentUser({}));
};

//Action 6: Set theme
export const setTheme = theme => {
    return {
        type: SET_THEME,
        payload: theme
    }
};