import { Link } from "react-router-dom";
import { Tabs, Tab, Box } from "@material-ui/core";

const TabNavigation = (props) => {
  const { classes, activeLab, activeTab, baseUrl, navigation } = props;

  const tabs = [
    {
      Title: navigation.Labs[activeLab].tabTitles.experiment,
      url: "experiment",
      pageId: navigation.Labs[activeLab].pages.experiment,
    },
    {
      Title: navigation.Labs[activeLab].tabTitles.data,
      url: "data",
      pageId: navigation.Labs[activeLab].pages.data,
    },
    {
      Title: navigation.Labs[activeLab].tabTitles.analysis,
      url: "analysis",
      pageId: navigation.Labs[activeLab].pages.analysis,
    },
  ];

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={activeTab}
          textColor="primary"
          indicatorColor="primary"
          aria-label="notebook tabs"
          variant="fullWidth"
        >
          {tabs.map(function (tab) {
            if (tab.Title != "") {
              return (
                <Tab
                  key={"tabitem" + tab.Id + tab.url}
                  component={Link}
                  selected={activeTab === tab.url}
                  to={`${baseUrl}/${activeLab}/${tab.url}`}
                  value={tab.url}
                  label={tab.Title}
                  className={classes.default_tab}
                />
              );
            }
          })}
        </Tabs>
      </Box>
    </div>
  );
};

export default TabNavigation;
