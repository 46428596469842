import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Paper,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  IconButton,
  Badge,
  Box,
  TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import FaceIcon from '@mui/icons-material/Face';
import axios from 'axios';
import './animate-character.css';
import NavAppBar from './common/NavAppBar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  modal: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 4,
    backgroundColor: '#ffffff',
    border: '2px solid #000',
    boxShadow: 24,
    textAlign: 'center'
  },
  cardHeader: {
     textAlign: 'center',
  },
  noOverLay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

const groups = ['Corn', 'Green Bean', 'Kale', 'Lettuce', 'Potato', 'Radish', 'Soybean', 'Squash', 'Sunflower']

export default function SeatingChart() {
  const classes = useStyles();

  // move to room modal
  const [openMoveModal, setMoveModalOpen] = useState(false);
  const handleMoveModalOpen = () => setMoveModalOpen(true);
  const handleMoveModalClose = () => setMoveModalOpen(false);
  const [targetGroup, setTargetGroup] = useState('');
  const [configurations, setConfigurations] = useState([]);
  const [selectedConfigurationStudents, setSelectedConfigurationStudents] = useState([]);
  const [selectedConfigurationId, setSelectedConfigurationId] = useState('');

  const [openConfigModal, setConfigModalOpen] = useState(false);
  const handleConfigModalOpen = () => setConfigModalOpen(true);
  const handleConfigModalClose = () => setConfigModalOpen(false);
  const [newConfigurationName, setNewConfigurationName] = useState('');

  // selection model
  const [selectionModel, setSelectionModel] = useState([]);
  const handleDeselectAll = () => setSelectionModel([]);

  const [error, setError] = useState(null)
  const isInitialMount = useRef(true);


  useEffect(() => {
    document.title = `Seating Chart`;

    (async () => {
      try {
        const res = await axios.get('/api/discussionGroups');
        setConfigurations(res.data);
      } catch (err) { setError(err.response.data) };

      // let tempStudents = [];
      // try {
      //   const res2 = await axios.get('/api/users/getUsersForUserManagement');
      //   //sets student names as first / last and initial room to waiting
      //   res2.data.map((user) => {
      //     tempStudents.push({
      //       fullName: `${user.first_name} ${user.last_name}`,
      //       room: '/waiting',
      //       id: user._id
      //     });
      //   });
      // } catch (err) { setError(err.response.data) };

      //sets current config users to proper rooms
      // tempStudents.map((index) => {
      //   tempCurrent.map((index2) => {
      //     index2.userIds.map((index3)=> {
      //       if (index3===index.id) {
      //         index.room=index2.groupNumber
      //       };
      //     });
      //   });
      // });
      //   setStudentInfo(tempStudents);
    })();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      (async () => {
        try {
          await axios.put('/api/discussionGroups', {
            discussionGroupId: selectedConfigurationId,
            students: selectedConfigurationStudents
          });
        } catch (err) { setError(err.response.data) };
      })();
    }
  }, [selectedConfigurationStudents]);


  //Changes display on room card when no students are in room
  function CustomNoRowsOverlay() {
    return (
      <Typography component='div' className={classes.noOverLay}>No students in room</Typography>
    );
  };

  function StudentDataGrid({group}) {
    let rows = {};
    if (selectedConfigurationStudents) {
      rows = selectedConfigurationStudents.filter(obj => {
        return obj.group === group
      })
    }
    return(
      <DataGrid
        rows={rows}
        columns={[{ field: 'fullName', headerName: 'Name', width: 250 }]}
        checkboxSelection
        getRowId={(r) => r.id}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        keepNonExistentRowsSelected
        disableSelectionOnClick
        hideFooterRowCount
        hideFooter
        components={{
          NoRowsOverlay: CustomNoRowsOverlay
        }}
      />
    )
  }

  const handleCreateConfiguration = async () => {
    let students = [];
    try {
      const res = await axios.get('/api/users/getUsersForUserManagement');
      for (let student of res.data) {
        students.push({
          fullName: `${student.first_name} ${student.last_name}`,
          id: student._id,
          group: 'unassigned'
        });
      }
    } catch (err) { setError(err.response.data) };
    try {
      const res = await axios.post('/api/discussionGroups', {
        name: newConfigurationName,
        students
      });
      setConfigurations([...configurations, res.data]);
    } catch (err) { setError(err.response.data) };

    handleConfigModalClose();
    setNewConfigurationName('');
  };

  const handleMoveStudentToGroup = () => {
    setSelectedConfigurationStudents(selectedConfigurationStudents.map(student => {
      if (selectionModel.includes(student.id)) {
        return { ...student, group: targetGroup };
      } else {
        return student;
      }
    }));
    setTargetGroup('');
    setSelectionModel([]);
    handleMoveModalClose();
  }

  const handleSelectConfiguration = e => {
    let configurationId = e.target.value;
    setSelectedConfigurationId(configurationId)
    if (configurationId) {
      let configuration = configurations.find(obj => {
        return obj._id === configurationId
      });
      setSelectedConfigurationStudents(configuration.students);
    }
  }

  return (
    <Container maxWidth="xl">
      <NavAppBar
        to='/roomview'
        toLabel='Go To Room View'
      >
          {/* stub flexgrow to justify icons right*/}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
          { selectionModel.length > 0 && <>
          <Tooltip title="Move Selected">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleMoveModalOpen}
              // disabled={currentConfig.length === 0}
            >
              <TransferWithinAStationIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Deselect All">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleDeselectAll}
            >
              <RemoveDoneIcon />
            </IconButton>
          </Tooltip>
          </> }
          <Tooltip title="Number of Selected Students" sx={{mr:2}}>
            <Badge color="secondary" badgeContent={selectionModel.length} showZero>
              <FaceIcon />
            </Badge>
          </Tooltip>
          <Tooltip title="Create New Seating Chart">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleConfigModalOpen}
            >
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
        </NavAppBar>
      <Grid container spacing={1} style={{ marginTop: 75 }}>
        <Grid container item spacing={2} sm={12} lg={3} xl={3} sx={{ pr: 2, height: '75%'}}>
          <Grid item xs={12} md={12} xl={12} sx = {{ height: '10%', mb: { sm: 0, xl: 10}}}>
            <Card sx={{  border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
              <FormControl fullWidth variant="filled" >
                <InputLabel id="configuration-select-label">Current Group Seating Chart</InputLabel>
                <Select
                  labelId="configuration-select-label"
                  id="configuration-select"
                  value={selectedConfigurationId}
                  onChange={handleSelectConfiguration}
                >
                { configurations.map(({name, _id}, index) => (
                  <MenuItem key={index} value={_id}>
                    <Typography variant='inherit' noWrap>{name}</Typography>
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} xl={12} sx={{ height: '65%'}} >
            <Card sx={{border : 3, borderColor:'primary.main', borderRadius: '10px'}}>
              <CardHeader sx={{borderBottom : 3, borderColor:'primary.main', borderRadius: '5px'}}
                className={classes.cardHeader}
                component={'div'}
                title={'Unassigned Students'}
              />
              <Typography component="div" style={{ height: 500, width: "100%" }}>
                <StudentDataGrid group={'unassigned'} />
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={3} item sm={12} lg={9} xl={9}>
          {groups.map((group, index) =>
            <Grid key={index} item xs={6} sm={6} md={6} lg={4} xl={4}>
              <Card sx={{border: 3, borderColor:'primary.main', borderRadius: '10px'}}>
                <CardHeader sx={{borderBottom : 3, borderColor:'primary.main', borderRadius: '5px'}}
                  className={classes.cardHeader}
                  component={'div'}
                  title={group}
                />
                <Typography component="div" style={{ height: 200, width: "100%" }}>
                  <StudentDataGrid group={group} />
                </Typography>
              </Card>
          </Grid>)}
        </Grid>
      </Grid>
      <Modal
        open={openMoveModal}
        onClose={handleMoveModalClose}
        aria-labelledby="move-modal-title"
        aria-describedby="move-modal-description"
      >
        <Box className={classes.modal}>
          <Typography variant='h4'>
            Move student(s) into groups
          </Typography>
          <Typography variant='subtitle1' sx={{ mt: 2 }}>
            {selectionModel.length} student(s) currently selected
          </Typography>
          <FormControl fullWidth className={classes.formControl} sx={{ mt: 2 }}>
            <InputLabel id="move-select-label">select group</InputLabel>
            <Select
              labelId="move-select-label"
              id="move-select"
              label="Move Rooms"
              value={targetGroup}
              onChange={(e) => setTargetGroup(e.target.value)}
            >
              { groups.map((value, index) => {
                return(
                  <MenuItem key={index} value={value} className={classes.menuItem}>
                    {value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleMoveStudentToGroup}
          >send to group</Button>
        </Box>
      </Modal>
      <Modal
        open={openConfigModal}
        // onClose={handleConfigModalClose}
        aria-labelledby="configuration-modal-title"
        aria-describedby="configuation-modal-description"
      >
        <Box className={classes.modal}>
          <Typography variant='h4'>
            Create New Seating Chart
          </Typography>
          <TextField
            id="standard-configuration-name"
            label="Seating Chart Name"
            variant="standard"
            value={newConfigurationName}
            onChange={(e) => setNewConfigurationName(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateConfiguration}
            sx={{ mt: 2 }}
          >Create Seating Chart</Button>
        </Box>
      </Modal>
    </Container>
  )
}