import axios from "axios";
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
//Teacher/Classroom Select
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
//checkboxes for classroom unlocking
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

/*

    TODO:
        1. Handle multiple labs.

*/

/*

    WARNING: THIS COMPONENT IS PROBLEMATIC. DUE TO CHANGES IN CLASSROOM DATA STRUCTURE, IT WILL NO LONGER WORK. THE CLASSROOM PARTS OF THIS COMPONENT WOULD
    NEED TO BE CHANGED FOR IT TO BE USED PROPERLY.

*/

export default function LabUnlock() {

    const [teachers, setTeachers] = React.useState([]);
    const [classrooms, setClassrooms] = React.useState([]);
    const [labs, setLabs] = React.useState([]);
    const [selectedId, setId] = React.useState("");
    const [selectedDisplay, setDisplay] = React.useState(0);
    const [activeLab, setActiveLab] = React.useState({});

    //get all the teachers and classrooms
    useEffect(() => {
        var lessons;
        axios.post("/api/navigation/getTeachersAndClassroom").then(response => {
            setTeachers(response.data[0]);
            setClassrooms(response.data[1]);
            lessons = [...new Set(response.data[1].map(item => item.lessons))];
            var filteredLessons = lessons.filter(function (element) {
                return element !== undefined;
            })
            axios.post("/api/navigation/getAllLabs", {
                lessons: filteredLessons,
            }).then(labResponse => {
                /*
                var realLabs = labResponse[0].filter(obj => {
                    return obj.unlocked === true
                  });
                */
                setLabs(labResponse.data);
            })
        });
    }, []);

    //updates the value when select changes
    const handleClassChange = (event) => {
        setId(event.target.value);
        var selectedClass = classrooms.filter(obj => {
            return obj._id === event.target.value;
        });
        var selectedLabs = labs.filter(obj => {
            return obj.code === selectedClass[0].lessons;
        });
        setActiveLab(selectedLabs[0]);
        //setLab from labs
    }

    const handleChange = (event) => {
        setId(event.target.value);
    }

    //update the checked boxes
    const handleCheck = (val) => {
        var newLabs = activeLab;
        newLabs.labs[val].unlocked = !newLabs.labs[val].unlocked;
        setActiveLab(newLabs);
    }

    //unlock all the labs for a given user (teacher)
    function unlockAllLabs(id) {
        axios.post("/api/navigation/unlockTeacher", {
            teacherId: id
        }).then(response => {
            setId("");
            setDisplay(0);
        })
    }

    //unlock only the unlocked labs
    function unlockLabs() {
        //filter only the unlocked = true labs
        var unlockedLabs = activeLab.labs.filter(obj => {
            return obj.unlocked === true
          });
        const indexes = activeLab.labs.map((lab, index) => {
            if (lab.unlocked === true) {
                return index;
            }
        }).filter(lab => lab >= 0);
        axios.post("/api/navigation/unlockClassroom", {
            labs: unlockedLabs,
            labIndexes: indexes,
            classroom: selectedId
        }).then(response => {
            //console.log(response);
            setId("");
            setDisplay(0);
            activeLab.labs.map((lab, index) => {
                activeLab.labs[index].unlocked = false;
            });
            setActiveLab(activeLab);
        })
    }

    //display the select dropdowns for teachers and classrooms
    function showContent() {
        if (selectedDisplay === 0) {
            return (<div></div>)
        }
        //show teacher if 1, else show classrooms
        if (selectedDisplay === 1) {
            return (
                <div>
                    <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                        <InputLabel id="teacher-filter-label">Teachers</InputLabel>
                        <Select
                            labelId="teacher-filter-label"
                            id="teacher-filter"
                            label="Teachers"
                            value={selectedId}
                            onChange={handleChange}
                        >
                            {teachers.map(teacher => {
                                return (<MenuItem key={teacher.first_name + "mi"} value={teacher._id}>{teacher.first_name + " " + teacher.last_name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    <br/>
                    <br/>
                    <Button variant="contained" color="primary" sx={{marginLeft: 5}} onClick={() => unlockAllLabs(selectedId)}>Unlock All Labs</Button>
                </div>
            )
        } else {
            return(
                <div>
                    <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                        <InputLabel id="classroom-filter-label">Classrooms</InputLabel>
                        <Select
                            labelId="classroom-filter-label"
                            id="teacher-filter"
                            label="Classrooms"
                            value={selectedId}
                            onChange={handleClassChange}
                        >
                            {classrooms.map(classroom => {
                                return (<MenuItem key={classroom._id + "mi"} value={classroom._id}>{classroom._id}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    <br/>
                    <br/>
                </div>
            )
        }
    }

    function showLabs() {
        if (selectedDisplay === 2) {
            if (selectedId === "") { return (<div></div>) }
            else {
                return (
                    <div>
                        <FormGroup>
                            {activeLab.labs.map(function(lab, index) {
                                return (<FormControlLabel key={lab.labName} control={<Checkbox />} label={lab.labName} onChange={() => handleCheck(index)}/>)
                            })}
                        </FormGroup>
                        <Button variant="contained" color="primary" onClick={() => unlockLabs()}>Unlock</Button>
                    </div>
                )
            }
        }
    }

    return (
        <Grid container alignItems="center" justifyContent="center" direction="column">
            <Grid item xs={6}>
                <br/>
                <Button variant="contained" color="primary" sx={{marginRight: 15}} onClick={() => setDisplay(1)}>Teacher</Button>
            </Grid>
            <Grid item xs={6}>
                <br/>
                <br/>
                {showContent()}
            </Grid>
        </Grid>
    )
}