import Button from "@mui/material/Button";
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";

export function PageExternalRedirectButton(props) {
    return (
        <Box textAlign="center">
            <br/>
            <Button variant="contained" color="primary" key={props.fieldKey} component={Link} to={{ pathname: props.url }} target="_blank">
                {props.buttonText}
            </Button>
            <br/>
        </Box>
    )
}

export default PageExternalRedirectButton;