import React, { createRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link as RouterLink } from 'react-router-dom';

import { Button, TableContainer, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Papa from "papaparse"; 
import axios from 'axios';

const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    blockMargin: {
        marginBottom: 20,
    },
    button: {
      marginRight: 15,
      marginLeft: 10,
    },
    errorText: {
      color: 'red'
    }
});

class UploadUsers extends React.Component{
    
    constructor(props) {
        super(props);

        this.ref = createRef();

        this.state = {
            fileLoaded: false,
            isParseError: false,
            parseErrors: [],
            validationErrors: [],
            uploadedStudents: []
        }
    }

    handleFileUpload = (event) => {
        if (!event.target.files) {
          return;
        }

        //reset state of results
        this.setState({
          validationErrors: [],
          uploadedStudents: []
        });

        const file = event.target.files[0];    
        const reader = new FileReader();
        reader.onload = (evt) => {
          if (!evt?.target?.result) {
            return;
          }
          const { result } = evt.target;
          this.setState({fileLoaded: true});
          const csv = Papa.parse(result, { header: true, skipEmptyLines: true });
          
          //were there any errors parsing?
          let isParseError = csv.errors.length > 0;
          let errors = [];
          csv.errors.forEach(err => {
            errors.push(err.message + " on row " + (err.row+1));
          });

          //does header match?
          if (csv.meta.fields.length !== 10 || csv.meta.fields[0] !== 'First name'|| csv.meta.fields[1] !== 'Last name'|| csv.meta.fields[2] !== 'Email'
           || csv.meta.fields[3] !== 'Gender' || csv.meta.fields[4] !== 'School' ||  csv.meta.fields[5] !== 'Teacher first name'
           || csv.meta.fields[6] !== 'Teacher last name' || csv.meta.fields[7] !== 'Academic year' || csv.meta.fields[8] !== 'Implementation term' 
           || csv.meta.fields[9] !== 'Class number') {
            isParseError = true;
            errors.push("Header does not match template");
          }

          //do we have any actual students in list
          if (csv.data.length < 1) {
            isParseError = true;
            errors.push("No students in upload");
          }

          if (isParseError) {
            this.setState({isParseError: isParseError});
            this.setState({parseErrors: errors});
          }
          else {
            this.setState({isParseError: false});
            this.setState({parseErrors: []});

            //submit users for validation and saving
            this.importUsers(csv.data);
          }

        };
        reader.readAsBinaryString(file);
    };

    //after successfully parsing csv, submit users to backend for verification and upload
    // 'usersFromCsv' - array of users from csv format
    importUsers = (usersFromCsv) => {

      let users = [];

      //convert each row to save model 
      for (let i=0; i < usersFromCsv.length; i++) {
        const newStudent =  { 
          first_name: usersFromCsv[i]["First name"],
          last_name: usersFromCsv[i]["Last name"],
          email: usersFromCsv[i]["Email"],
          gender: usersFromCsv[i]["Gender"],
          school: usersFromCsv[i]["School"],  
          teacher_first_name: usersFromCsv[i]["Teacher first name"],
          teacher_last_name: usersFromCsv[i]["Teacher last name"],
          academic_year: usersFromCsv[i]["Academic year"],  
          implementation_term: usersFromCsv[i]["Implementation term"], 
          class_number: usersFromCsv[i]["Class number"],
          status: true,
          consent: true
        }

        users.push(newStudent);
      }

      //submit to validation function
      axios.post('/api/users/importStudents', {
        users  
      }).then((response) => {

        //display validation errors
        if (response.data.success === false) {
          this.setState({validationErrors: response.data.results});
        }
        else {
          this.setState({uploadedStudents: response.data.results });
        }

      }).catch((error) => {
          console.log(error);
      });
    };

    reset = () => {

      this.ref.current.value = "";

      this.setState({
          fileLoaded: false,
          isParseError: false,
          parseErrors: [],
          validationErrors: [],
          uploadedStudents: []
      });
    }

   render(){
        const { classes } = this.props;
        return (
            <div className={classes.pageMargin}>
                <Typography variant="subtitle1" component="h2" color="primary">Student Batch Upload</Typography>
                <div className={classes.blockMargin}>
                    <ArrowDownwardIcon /><Link component={RouterLink} to={"/files/User_Upload_Template.csv"} target="_blank" download>DOWNLOAD TEMPLATE</Link>
                </div>
                <div className={classes.blockMargin}>
                    <Button
                        component="label"
                        variant="outlined"
                        color="primary"
                        startIcon={<UploadFileIcon />}
                        sx={{ marginRight: "1rem" }}
                    >
                        Upload CSV
                        <input type="file" accept=".csv" hidden onChange={this.handleFileUpload}  ref={this.ref} />
                    </Button>
                </div>
                
                {
                   this.state.isParseError &&
                   <div className={classes.blockMargin}>
                     {this.state.parseErrors.map(function(err, index) { return <Alert severity="error" key={"alert"+index}>{err}</Alert> })}
                   </div>
                }

                {/*list validation errors here*/}
                {
                  this.state.validationErrors.length > 0 &&
                  <div className={classes.blockMargin}>
                    <h3>There were validation error(s).  No students were uploaded.</h3>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Errors</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.validationErrors.map(function(err, i) {
                            return (
                              <TableRow key={"userRow" + i}>
                                <TableCell>{err.user.first_name} { err.user.last_name}</TableCell>
                                <TableCell>{err.user.email}</TableCell>
                                <TableCell><span className={classes.errorText}>{err.errors.join(', ')}</span></TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                }
                {/*list successfully saved students here */}
                {
                  this.state.uploadedStudents.length >0 &&
                  <div className={classes.blockMargin}>
                    <h3>Students successfully uploaded. </h3>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.uploadedStudents.map(function(student, i) {
                            return (
                              <TableRow key={"userRow" + i}>
                                <TableCell>{student.first_name} { student.last_name}</TableCell>
                                <TableCell>{student.email}</TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </div>
                }
                {
                  this.state.fileLoaded &&
                  <div className={classes.blockMargin}>
                      <Button className={classes.button} variant="contained" color="primary" onClick={() => this.reset()}>Reset</Button>                
                  </div>
                }
            </div>
        )
   }
}

export default withStyles(styles)(UploadUsers);