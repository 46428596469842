/*

    TODO:
    (1) Ensure password is correct by having two fields and checking they are the same,
        if we star passwords.

*/

import React, { useEffect } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function GuestCreator() {

    const columns = [
        { field: 'email', headerName: 'Email', width: 450 },
        { field: 'delete', headerName: '', width: 100, renderCell: (params) => {
            return (
              <Button
                onClick={(e) => deleteGuest(e, params.row)}
                variant="contained"
                style={{backgroundColor: "red", color: "white"}}
              >
                Delete
              </Button>
            );
        } },
    ];

    const [userEmail, setUserEmail] = React.useState('');
    const [tableRows, setRows] = React.useState([]);
    const [changedUsers, setChanged] = React.useState(false);
    //all units
    const [units, setUnits] = React.useState([]);
    const [selectedUnit, setSelected] = React.useState({});
    //Pyreval url
    const pyrurl_delete = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/guest_deleted");

    useEffect(() => {
          axios
          .post("/api/users/get_guests", {})
          .then(result => {
            //if length 0, no guests
            if (result.data.length === 0) {
                setRows([]);
            } else {
                let rows = [];
                result.data.forEach(guest => {
                    let newEntry = {id: rows.length,  email: guest.email, sid: guest._id};
                    rows.push(newEntry);
                });
                setRows(rows);
            }
        });
    }, [changedUsers]);

    useEffect(() => {
        axios
        .post("/api/navigation/getallunits", {})
        .then(result => {
            setUnits(result.data);
        })
        .catch(error => {
            console.log(error);
        })
    }, []);

    //user email setter
    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    }

    //delete functionality
    //TODO: handle pyreval deletion
    const deleteGuest = (e, row) => {
        e.stopPropagation();
        //do whatever you want with the row
        axios
          .post("/api/users/delete_guest", {
            email: row.email,
          })
          .then(result => {
            console.log(result);
          });
        if (pyrurl_delete) {
           axios
            .post(pyrurl_delete, {
                student_id: row.sid,
            })
            .then(result => {
                //console.log(result);
            });
        }
        setChanged(!changedUsers);
    };

    //clear all user inputs
    function reset() {
        setUserEmail('');
    }

    //save the user to the database
    function save() {
        if(Object.is(selectedUnit, null)) {
            //selectedUnit is null -- happens when you clear the value
        } else if (Object.keys(selectedUnit).length == 0) {
            //selectedUnit is an empty object -- happens on the initial load
        } else {
            //selectedUnit is correctly selected
            try {
                axios.post('/api/users/register_guest', {
                    email: userEmail,
                    lessonId: selectedUnit.Lesson_Id
                }).then(result => {
                    //console.log(result);
                    setChanged(!changedUsers);
                });
            }
            catch {}
            //clear inputs
            reset();
        }
    }

    //show all units
    function generateUnits() {
        if (units != []) {
            return(
                <div>
                    <Autocomplete
                        key="unit-list"
                        id="auto-complete-classes"
                        options={units}
                        getOptionLabel={(option) => option.Lesson_Name}
                        style={{ width: 250, marginLeft: 10}}
                        renderInput={(params) => <TextField {...params} label="Units" variant="outlined" size="small" />}
                        onChange={(event, value) => setSelected(value)}
                    />
                </div>
            )
        }
    }

    return (
        <div>
            <br/>
            <br/>
            <div style={{display: "flex"}}>
            <TextField style={{marginLeft: 300, width: 450}} value={userEmail} id="user-email" label="Email Address" variant="outlined" size="small" onChange={handleEmailChange} />
            {generateUnits()}
            </div>
            <br />
            <br />
            <Button style={{marginLeft: 300}} variant="contained" color="primary" onClick={() => save()}>Save</Button>
            <Button style={{marginLeft: 300}} variant="contained" color="secondary" onClick={() => reset()}>Reset</Button>
            <br/>
            <br/>
            <div style={{ height: 400, width: '50%', marginLeft: 300 }}>
                <DataGrid
                    rows={tableRows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                />
            </div>
        </div>
    )
}