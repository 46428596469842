/*

    This is the child component of PageSelectDrop, and is required for Select functionality.

    Required Props:
    answer: For pre-setting the value.
    iLabel: The label that will appear on the select, and then moved aboved it after an option is selected.
    label: Just needed to prevent HTML warnings.
    options: This is a required component for showing the select values/names.
    callback: Callback function to pass the value.
    callbackId: An index ID for updating the parent answer.

    Refer to PageSelectDrop.js for a better understanding of the props.

*/

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 150,
  },
}));

export function SelectDrop(props) {
  const classes = useStyles();

  return (
    <div>
      <FormControl>
        <InputLabel>{props.data.iLabel}</InputLabel>
        <Select
          className={classes.select}
          id={props.data.label}
          value={props.answer}
          onChange={(event) => props.callback(event.target.value)}
        >
          {props.data.option.map((object, i) => {
            return (
              <MenuItem key={props.data.label + i} value={object.value}>
                {object.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectDrop;
