import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  const updateCount = (event) => {
    const newLength = parseInt(event.target.value);
    const length = props.data.checkoptions.length;
    let newArr;
    if (newLength < length) {
      newArr = props.data.checkoptions.slice(0, length - 1);
    } else {
      newArr = [...props.data.checkoptions];
      newArr[length] = "";
    }
    props.updateComponent("checkoptions", newArr);
  };

  function updateOptions(index, value) {
    const checks = [...props.data.checkoptions];
    checks[index] = value;
    props.updateComponent("checkoptions", checks);
  }

  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Checkboxes
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        placeholder=""
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        multiline
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <Typography style={{ display: "inline-block" }}>
        How many options?
      </Typography>
      <TextField
        id="count"
        type="number"
        value={props.data.checkoptions.length}
        style={{ width: 50, marginTop: -6, marginLeft: 10 }}
        onChange={updateCount}
      />
      <br />
      {props.data.checkoptions.map((option, index) => (
        <div key={index}>
          <TextField
            id={`check${index}`}
            label="Option"
            value={option}
            onChange={(event) => updateOptions(index, event.target.value)}
            placeholder=""
          />
        </div>
      ))}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: [],
    },
    comp: {
      type: "PageCheckbox",
      props: {
        checkoptions: [],
        fieldKey:
          authorId + "-checkbox-" + Math.random().toString(36).substr(2, 6),
        question: "",
      },
    },
  };
}

export const PageCheckbox = { createNew, Form, name: "Checkboxes" };

export default PageCheckbox;
