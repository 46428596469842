import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

// TODO add input checking to prevent duplicates
function Form(props) {
  const updateCount = (event) => {
    const newLength = parseInt(event.target.value);
    const length = props.data.option.length;
    let newArr;
    if (newLength < length) {
      newArr = props.data.option.slice(0, length - 1);
    } else {
      newArr = [...props.data.option];
      newArr[length] = { name: "", value: "" };
    }
    props.updateComponent("option", newArr);
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateOption(index, optionData) {
    const option = deepCopy(props.data.option);
    option[index] = optionData;
    props.updateComponent("option", option);
  }

  return (
    <div>
      <Typography>What is this input field?</Typography>
      <TextField
        required
        id="label"
        label="Label"
        value={props.data.iLabel}
        onChange={(event) => {
          props.updateComponent("iLabel", event.target.value);
        }}
        variant="outlined"
      />
      <br />
      <br />
      <Typography style={{ display: "inline-block" }}>
        How many options?
      </Typography>
      <TextField
        id="count"
        type="number"
        value={props.data.option.length}
        style={{ width: 50, marginTop: -6, marginLeft: 10 }}
        onChange={updateCount}
      />
      <br />
      {props.data.option.map((option, index) => (
        <div key={index}>
          <TextField
            style={{ width: "48%" }}
            id={`selectoption${index}`}
            label="Name"
            value={option.name}
            onChange={(event) =>
              updateOption(index, { ...option, name: event.target.value })
            }
            placeholder=""
          />
          <TextField
            style={{ marginLeft: 10, width: "48%" }}
            id={`selectoption${index}label`}
            label="Value"
            value={option.value}
            onChange={(event) =>
              updateOption(index, { ...option, value: event.target.value })
            }
          />
        </div>
      ))}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: "",
    },
    comp: {
      type: "PageSelect",
      props: {
        fieldKey:
          authorId + "-select-" + Math.random().toString(36).substr(2, 6),
        iLabel: "",
        label: "select0",
        option: [],
      },
    },
  };
}

export const PageSelect = { createNew, Form, name: "Select" };

export default PageSelect;
