export function PageEmbeddedWebsite(props) {

  return (
    <div key="externalWebsite">
        <iframe style={{height: 600, width: 800}} src={props.url} />
    </div>
  );

}

export default PageEmbeddedWebsite;