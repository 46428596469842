import {
    SET_CURRENT_USER,
    USER_LOADING,
    SET_THEME
  } from "../actions/types";
  import { createTheme } from "@material-ui/core/styles";
  import { createTheme as createMuiTheme } from "@mui/material/styles";

const isEmpty = require("is-empty");

const initialState = {
    isAuthenticated: false,
    user: {},
    loading: false,
    theme: {
      material: createTheme({}),
      mui: createMuiTheme({})
    }
  };
  
// eslint-disable-next-line
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
            ...state,
            isAuthenticated: !isEmpty(action.payload),
            user: action.payload
            };
        case USER_LOADING:
            return {
            ...state,
            loading: true
            };
        case SET_THEME:
            return {
              ...state,
              theme: action.payload
            };
        default:
            return state;
    }
  }