import React, { useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Tooltip, Grid, Typography } from "@mui/material";


const ideas = [
    "Height and PE",
    "Relation between PE and KE",
    "Total energy",
    "Energy transformation and Law of Conservation of Energy",
    "Relation between initial drop and hill height",
    "Mass and energy",
    "Drop height and speed",
    "Mass and speed",
]

function generateCUSummary(ideaLength, data, version) {
    if (Object.keys(data).length === 0) {
        return "No Data";
    }
    let summaryData = [];
    let selectedData;
    if (version === 'Original') {
        selectedData = data['original'];
    } else {
        selectedData = data['revised'];
    }
    //generate empty dict
    let summary = {}
    for(let i = 0; i < ideaLength; i++) {
        summary[i] = 0;
    }
    Object.keys(selectedData).forEach(key => {
        selectedData[key].feedback.map(function(value, index) {
            summary[index] = summary[index] + value;
        });
    });
    let totalStudents = Object.keys(selectedData).length;
    Object.keys(summary).forEach(key => {
        let percentage = Math.ceil((summary[key] / totalStudents) * 100);
        let idea = ideas[key];
        let values = summary[key].toString() + "/" + totalStudents.toString();
        summaryData.push({idea: idea, percentage: percentage, value: values});
    });
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell key='idea'>Idea</TableCell>
                    <TableCell key='ideanumber'>#</TableCell>
                    <TableCell key='ideapercent'>%</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {summaryData.map((item) => {
                    return ([
                        <TableRow>
                            <TableCell key={item['idea']}>{item['idea']}</TableCell>
                            <TableCell key={item['idea'] + 'value'}>{item['value']}</TableCell>
                            <TableCell key={item['idea'] + 'percent'}>{item['percentage']}</TableCell>
                        </TableRow>
                    ])
                })}
            </TableBody>
        </Table>
    )
}

export default function PyrEvalFeedback(props) {

    const roster = props.roster;
    const data = props.data;
    const version = props.version;

    //essay ideas displayed
    const [displayIdeas, setDisplayIdeas] = React.useState([]);
    //dialog with essay
    const [open, setOpen] = React.useState(false);
    const [studentName, setName] = React.useState("");
    const [essay, setEssay] = React.useState("");
    const [cuLength, setCULength] = React.useState(0);
    //summary info
    const [summary, setSummary] = React.useState([]);
    const [summaryOpen, setSummaryOpen] = React.useState(false);

    useEffect(() => {
        let essayIdeas = [];
        let ideaLength = 0;
        //this assumes the previous page is still passing data in the format of '1 --- Essay 1'
        //where the first 1 is the class, followed by a delimiter of ---, then the page name
        if(props.essayNumber.split(" ")[3] === '1') {
            ideaLength = 6;
        } else {
            ideaLength = 8;
        }
        for(let i = 0; i < ideaLength; i++) {
            essayIdeas.push(ideas[i]);
        }
        setDisplayIdeas(essayIdeas);
        setCULength(ideaLength);
    }, []);

    //dialog control
    const handleClickOpen = (name, id) => {
        setName(name);
        if (Object.keys(data).length === 0) {
            setEssay("This student has not submitted their essay yet.");
        } else {
            let undefineCheck;
            if (version === "Original") {
                undefineCheck = typeof data.original[id];
            } else {
                undefineCheck = typeof data.revised[id];
            }
            if (undefineCheck !== 'undefined') {
                let studentEssay;
                if (version === "Original") {
                    studentEssay = data.original[id].essay;
                } else {
                    studentEssay = data.revised[id].essay;
                }
                setEssay(studentEssay);
            } else {
                setEssay("This student has not submitted their essay yet.");
            }
        }
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleSummaryClose = () => {
        setSummaryOpen(false);
    }

    //generate header row
    const generateHeader = () => {
        let headerValues = [];
        displayIdeas.map(function(disIdea, index) {
            headerValues.push(<Tooltip placement="top" title={disIdea}><TableCell>CU{index + 1}</TableCell></Tooltip>)
        });
        return headerValues;
    }

    //generating cells for original
    const generateCells = (value) => {
        if (value === 1) {
            return (<TableCell sx={{backgroundColor: "#99F19E"}}>1</TableCell>)
        } else {
            return (<TableCell sx={{backgroundColor: "#E77760"}}>0</TableCell>)
        }
    }

    //generating cells for revised
    const generateRevisedCells = (revisedValue, originalValue) => {
        //three potential outcomes
        //values stayed the same
        if (originalValue === revisedValue) {
            if (revisedValue === 1) {
                return (<TableCell sx={{backgroundColor: "#99F19E"}}>1</TableCell>)
            } else {
                return (<TableCell sx={{backgroundColor: "#E77760"}}>0</TableCell>)
            }
        //found the idea originally, but missing in revision
        } else if (originalValue === 1 && revisedValue === 0) {
            if (revisedValue === 1) {
                return (<TableCell sx={{backgroundColor: "#99F19E", boxShadow: "0px 0px 0px 4px #C70039 inset"}}>1</TableCell>)
            } else {
                return (<TableCell sx={{backgroundColor: "#E77760", boxShadow: "0px 0px 0px 4px #C70039 inset"}}>0</TableCell>)
            }
        //didn't find the idea originally, but covered in revision
        } else {
            if (revisedValue === 1) {
                return (<TableCell sx={{backgroundColor: "#99F19E", boxShadow: "0px 0px 0px 4px #268C22 inset"}}>1</TableCell>)
            } else {
                return (<TableCell sx={{backgroundColor: "#E77760", boxShadow: "0px 0px 0px 4px #268C22 inset"}}>0</TableCell>)
            }
        }
    }

    const generateFeedback = (studentId, name) => {
        if (Object.keys(data).length === 0) {
            let studentRow = [<TableCell onClick={() => {handleClickOpen(name, studentId)}}>{name}</TableCell>];
            for(let i = 0; i < cuLength; i++) {
                studentRow.push(<TableCell></TableCell>);
            }
            return(studentRow);
        }
        else if (version === "Original" && typeof data.original[studentId] === 'undefined') {
            let studentRow = [<TableCell onClick={() => {handleClickOpen(name, studentId)}}>{name}</TableCell>];
            for(let i = 0; i < cuLength; i++) {
                studentRow.push(<TableCell></TableCell>);
            }
            return(studentRow);
        }
        else if (version === "Revised" && typeof data.revised[studentId] === 'undefined') {
            let studentRow = [<TableCell onClick={() => {handleClickOpen(name, studentId)}}>{name}</TableCell>];
            for(let i = 0; i < cuLength; i++) {
                studentRow.push(<TableCell></TableCell>);
            }
            return(studentRow);
        } else {
            //Original cells
            if (version === "Original") {
                let cellArray = [];
                cellArray.push(<TableCell onClick={() => {handleClickOpen(name, studentId)}}>{name}</TableCell>);
                data.original[studentId].feedback.forEach(result => {
                    cellArray.push(generateCells(result));
                })
                return cellArray;
            } else {
                //Revised cells
                let cellArray = [];
                cellArray.push(<TableCell onClick={() => {handleClickOpen(name, studentId)}}>{name}</TableCell>);
                data.revised[studentId].feedback.forEach(function(result, index) {
                    cellArray.push(generateRevisedCells(result, data.original[studentId].feedback[index]));
                })
                return cellArray;
            }
        }
    }

    if (displayIdeas.length === 0) {
        <div>
            <p>Could not find data.</p>
        </div>
    }

    return(
        <div>
            <Grid container spacing={1}>
            <Grid item xs={8}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {generateHeader()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roster.map((student) => {
                            return (
                                <TableRow>
                                {generateFeedback(student.id, student.name)}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            <Grid item xs={3}>
                <Table onClick={() => setSummaryOpen(true)}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan={2}>CU Definitions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayIdeas.map(function(idea, index) {
                            return(
                                <TableRow>
                                    <TableCell>CU{index + 1}</TableCell>
                                    <TableCell>{idea}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {studentName + "'s Essay"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {essay}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={summaryOpen}
                onClose={handleSummaryClose}
                aria-labelledby="summary-dialog-title"
                aria-describedby="summary-dialog-description"
            >
                <DialogTitle id="summary-dialog-title">
                    {"Content Unit Summary"}
                </DialogTitle>
                <DialogContent>
                    {generateCUSummary(cuLength, data, version)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSummaryClose}>Close</Button>
                </DialogActions>
            </Dialog>
            <br/>
            <br/>
        </div>
    )
    
}