import { Link } from "react-router-dom";
import { Stepper, Step, StepButton, StepLabel, Box } from "@material-ui/core";

const StepperNavigation = (props) => {
  const { classes, navigation, baseUrl, rerender } = props;
  const activeLab = props.activeLab ? parseInt(props.activeLab) : -1;
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        nonLinear
        activeStep={activeLab}
        style={{
          padding: 10,
          marginLeft: 90,
          backgroundColor: "transparent",
        }}
      >
        {navigation.Labs.map((lab, index) => (
          <Step
            className={classes.step_root}
            key={index}
            completed={lab.completed}
            disabled={!lab.unlocked}
          >
            <StepButton
              color="inherit"
              component={Link}
              to={`${baseUrl}/${index}`}
            >
              <StepLabel
                classes={{
                  label: classes.step_label_root,
                  disabled: classes.step_label_root,
                }}
              >
                {lab.labName}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperNavigation;
