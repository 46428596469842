import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { useEffect } from "react";
//Close
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
//show text
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default function Note(props) {

    //detect double click to open edit
    const handleClick = (event) => {
        if (event.detail == 2) {
          var values = {title: props.title, body: props.body, background: props.color, keyVal: props.keyVal, id: props.id};
          props.edit(values);
        }
      }

    return(
        <React.Fragment key={props.keyVal}>
            <Draggable defaultPosition={{x: 150, y: 150 }}>
            <Paper onClick={handleClick} sx={{position: "absolute", "zIndex": 1000, width: 255, minHeight: 160, height: 'flex', paddingLeft: 1, paddingTop: .5, paddingBottom: 0, paddingRight: 1, backgroundColor: props.color}}>
                <IconButton sx={{'zIndex': 3, position:'absolute', left: '88%', top: '-2%', "&.MuiButtonBase-root:hover": {bgcolor: 'transparent'}}} onClick={() => props.close(props.keyVal)}>
                    <Close />
                </IconButton>
                <TextField 
                    multiline
                    variant="standard"
                    fullWidth
                    value={props.body} 
                    sx={{width: 250, height: 'flex', minHeight: 160}}
                    inputProps={{ style: { textAlign: 'left', overflow: 'hidden' }, maxLength: 50}}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}  
                />
            </Paper>
            </Draggable>
        </React.Fragment>
    )
}