/*

    TODO:
    (1) Have the ability to handle a boolean prop for NLP feedback
        -> True: NLP check button / NLP feedback handler
    (2) Save button, helps students feel at ease

    This is a page component for short answers.

    When this component is called, it is expected that props will be passed
    to it. The props are necessary to populate the element.


    *Required Props*

    questiontitle: A header that will appear above the question text
    question: The text body of the question, or what is being asked.
    answer: The response to the 'question' prop.
        -> Default answer will be assumed to be "", or blank.
        -> If a student answer exists, it will override the default value.


    Example props.

    questiontitle="Question 1."
    question="This is a test!"
    answer="This is an example answer."

*/

import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";

export function PageEssayAnswer(props) {
  const {navigation, question, fieldKey} = props;

  const [value, setValue] = useState(props.answers?.answer || "");
  const [submitEnable, setSubmitEnableness] = useState(false);
  const [classNumber, setClassNumber] = useState("");
  const [teacherId, setTeacher] = useState("");
  //Pyreval urls
  const pyrurl_check = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/check_student_essay");
  const pyrurl_submit = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/submit_student_essay");

  //Function to handle when the user types in the answer
  const onChangeHandler = (event) => {
    setValue(event.target.value);
    props.returnData({ answer: event.target.value }, fieldKey);
  };

  useEffect(() => {
    axios
      .post("/api/navigation/getclassroominfo", {
        classId: navigation.Classroom,
      })
      .then((result) => {
        let values = result.data[0];
        setClassNumber(values["class_number"]);
        setTeacher(values["teachers"]);
        if (pyrurl_check) {
          axios
            .post(pyrurl_check, {
              student_id: navigation.Student_Id,
              class_id: values["class_number"],
              teacher_id: values["teachers"],
              essay_number: "2",
              essay_version: "1",
            })
            .then((result) => {
              if (result.data === "Not Submitted") {
                setSubmitEnableness(false);
              } else {
                //it is submitted, so don't let them submit again
                setSubmitEnableness(true);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          //no PyrEval in this environment; don't submit again
          setSubmitEnableness(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const submitEssay = () => {
    let studentId = navigation.Student_Id;
    let essayNum = "2";
    let essayV = "1";
    if (pyrurl_submit) {
      axios
        .post(pyrurl_submit, {
          student_id: studentId,
          class_id: classNumber,
          teacher_id: teacherId,
          essay_number: essayNum,
          essay_version: essayV,
          essay: value,
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="essayans">
      <br />
      <h6> </h6>
      <Typography>{question}</Typography>
      <br />
      <form>
        <TextField
          id="standard-textarea"
          label="Your response"
          placeholder=""
          multiline
          rows={20}
          style={{ width: 800 }}
          value={value}
          onChange={onChangeHandler}
        />
      </form>
      <br />
      <br />
      <Button
        disabled={submitEnable}
        variant="contained"
        color="primary"
        onClick={() => submitEssay()}
      >
        Submit
      </Button>
    </div>
  );
}

export default PageEssayAnswer;
