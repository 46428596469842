import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';

const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    inputName: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    button: {
        marginRight: 15,
        marginLeft: 10,
    },
});

class SchoolEditor extends React.Component{
    
    constructor(props) {
        
        super(props);
       
        // List of fields in the form, for use with validation
        this.allFormFieldNames = ['school_name'];

        this.schoolId = props.match.params.schoolId ?? null; // The :schoolId parameter from the url path

        this.maximumTextFieldLength = 100;

        this.state = {

            // Tracks which form fields have validation errors, and the helper message to display
            formErrors: {
                school_name: { error: false, helperText: '' },
                save_button: { error: false, helperText: '' }
            },
            school: {
                _id: '',
                school_name: '',
            },
            successMessage: ''
        };

    }

    // Event handler for when school name change, so we can update state
    handleNameChange = (event => {
        let school = this.state.school;
        school['school_name'] = event.target.value;
        this.setState({school: school});
    });

    // Checks if the given value is valid for the given form field name,
    // and updates the state of formErrors to set/clear an error for the field
    isValueValidForField = (value, fieldName) => {

        let formErrors = this.state.formErrors;
        let fieldValue = value;

        let hasError = false;

        switch(fieldName)
        {
            case 'school_name':

               if(fieldValue === '')
               {
                    formErrors[fieldName].error = true;
                    formErrors[fieldName].helperText = "This field is required";
                    hasError = true;
               }
               else
               {
                    formErrors[fieldName].error = false;
                    formErrors[fieldName].helperText = "";
               }

               break;
            default:
                break;
        }

        this.setState({formErrors: formErrors});

        // Return false if we had an error
        return !hasError;

    }

    validateAllFormFields = (event) => {

        let allAreValid = true;

        this.allFormFieldNames.forEach((fieldName) => {
            if(!this.isValueValidForField(this.state.school[fieldName], fieldName))
            {
                allAreValid = false;
            }
        });
        
        return allAreValid;

    }

    // Event handler for when a form field has changed/blurred and should be validated
    blurOrChangeValidate = (event) => {
        this.isValueValidForField(event.target.value, event.target.id);
    }

    componentDidMount(){
        // If we were given a school id
        if(this.schoolId !== null)
        {
            // fetch the school from the backend
            this.fetchSchool(this.schoolId);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.hideSuccessMessageTimeout);
    }

    fetchSchool = (schoolId) => {
        this.setState({...this.state, isFetching: true});

        axios.get("/api/schools/getSchool/" + schoolId).then(response => {
            this.setState({
                school: {
                    _id: response.data._id,
                    school_name: response.data.name,
                }, 
                isFetching: false});
        })
        .catch(e =>{
            console.log(e);
            this.setState({...this.state, isFetching: false});
        });

    }
  
    applyFormErrors = (errorsToApply) => {

        let formErrors = this.state.formErrors;
        console.log(formErrors);
        console.log(errorsToApply);
                    
        if(errorsToApply) {
            Object.keys(errorsToApply).forEach(key => {
                if(formErrors[key] !== undefined)
                {
                    formErrors[key].error = true;
                    formErrors[key].helperText = errorsToApply[key];
                }
                else
                {
                    console.log('Missing expected form field: ' + key + ' for error: ' + errorsToApply[key]);
                }             
            });
        }

        this.setState({formErrors: formErrors});
    }

    save = () => {

        // If the form isn't valid
        if(!this.validateAllFormFields())
        {
            return;
        }

        // If we're editing an existing school
        if(this.state.school._id)
        {
            axios.post('/api/schools/update', {
                _id: this.state.school._id,
                school_name: this.state.school.school_name,
            }).then((response) => {
                if(!response.data.success)
                {
                    this.applyFormErrors(response.data.errors);
                }
                else
                {
                    // Show a success message that will disappear after 10 second
                    this.setState({successMessage: 'School successfully updated'});
                    this.hideSuccessMessageTimeout = setTimeout(() => this.setState({successMessage: ''}), 10000);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        else // We're creating a new school
        {
            axios.post('/api/schools/create', {
                _id: this.state.school._id,
                school_name: this.state.school.school_name,
            }).then((response) => {
                
                if(!response.data.success)
                {
                    this.applyFormErrors(response.data.errors);
                }
                else
                {
                    // Show a success message that disappears after 10 second
                    this.setState({successMessage: 'New school created.'});
                    this.hideSuccessMessageTimeout = setTimeout(() => this.setState({successMessage: ''}), 10000);
                    // Clear the school name
                    let tempSchool = this.state.school;
                    tempSchool.school_name = '';
                    this.setState({school: tempSchool});
                }

            }).catch((error) => {
                console.log(error);
            });
        }
    }

   render(){
        const { classes } = this.props;
        const isExistingSchool = this.state.school._id === "" ? false : true;
        return (
            <div className={classes.pageMargin}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        { isExistingSchool ? 
                            <Typography variant="subtitle1" component="h2" color="primary">Edit School</Typography> 
                          : 
                          <Typography variant="subtitle1" component="h2" color="primary">Create School</Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <TextField required className={classes.inputName} value={this.state.school.school_name} id="school_name" 
                            label="School Name" variant="outlined" size="small" onChange={this.handleNameChange} 
                            error={this.state.formErrors.school_name.error} helperText={this.state.formErrors.school_name.helperText}
                            onBlur={this.blurOrChangeValidate}
                            inputProps={{ maxLength: this.maximumTextFieldLength }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl error={this.state.formErrors.save_button.error}>
                            <FormHelperText>{this.state.formErrors.save_button.helperText}</FormHelperText>
                            <FormHelperText>{this.state.successMessage}</FormHelperText>
                            <Button className={classes.button} variant="contained" color="primary" onClick={() => this.save()}>Save</Button>
                        </FormControl>                   
                    </Grid>
                </Grid>    
            </div>
        )
   }
}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (SchoolEditor));

