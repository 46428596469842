import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
//lab filter
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import Note from "./Notes/OLD Note";


export function Notes(props) {

    /*

    This is janky code because the component is being re-rendered when the user navigates away from the page.
    When the page is simulation, it makes 'answers' === undefined and crashes this component.

    */
    let pToc;
    let pContent;
    let pNotesTaken;

    if (props.answers !== undefined) {
        pToc = props.answers.toc;
        pContent = props.answers.content;
        pNotesTaken = props.answers.notes_taken;
    } else {
        pToc = [];
        pContent = [];
        pNotesTaken = 0;
    }

    const [activeStepContent, setActiveStep] = React.useState(0);
    const [toc, setToc] = React.useState(pToc);
    const [content, setContent] = React.useState(pContent);
    const [noteCount, setNoteCount] = React.useState(pNotesTaken);
    const [labNames, setLabs] = React.useState([]);
    const [labFilter, setFilter] = React.useState(-1);
    const [newLab, setNewLab] = React.useState("");

    useEffect(() => {
        let activeLab = props.activeLab;
        let lNames = props.navigation.Labs.map(lab => lab.labName);
        if (activeLab === -1) {
            setNewLab(lNames[0]);
        } else {
            setNewLab(lNames[activeLab]);
        }
        setLabs(lNames);
        if (content.length !== 0) {
            if (props.answers.notes_taken === content[content.length-1].id) {
                var newTotal = props.answers.notes_taken + 1;
                setNoteCount(newTotal);
            }
        }
    }, [props, content]);

    function generateTableOfContents(data) {
        var header = <Box textAlign="center"><h2>Table of Contents</h2></Box>
        var body = [];
        data.forEach(function(item, i) {
            var title = item.title;
            var date = item.date;
            var lab = item.lab;
            var newEntry = lab + " --- " + date + " --- " + title;
            body.push(<Typography onClick={() => setActiveStep(i + 1)}>{newEntry}</Typography>);
            body.push(<br/>);
            body.push(<br/>);
        })
        var tableOfContents = {title: header, body: body}
        return tableOfContents;
    }

    const updateValue = (target, value, noteId) => {
        var updateCount;
        if (noteId > noteCount) {
            updateCount = noteId;
        } else {
            updateCount = noteCount;
        }
        const index = toc.findIndex(item => item.id === noteId);
        if (target === "lab") {
            //update table of contents lab value
            let tempToc = toc;
            tempToc[index].lab = value;
            setToc(tempToc);
            let newToc = generateTableOfContents(toc);
            let newSteps = [newToc];
            //update content lab
            let tempContent = content;
            tempContent[index].lab = value;
            setContent(tempContent);
            content.forEach(function(step, i) {
                newSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} nav={props.navigation}/>});
            })
            setSteps(newSteps);
            setMax(newSteps.length);
            setFilter(-1);
            setActiveStep(0);
        }
        else if (target === "title") {
            //update table of contents value
            let tempToc = toc;
            tempToc[index].title = value;
            setToc(tempToc);
            /*
            if (labFilter != -1) {
                var tempToc = toc.filter(function (item) {
                    return item.lab == labNames[labFilter]
                });
            }
            else {
                var tempToc = toc;
            }
            */
            //var newToc = generateTableOfContents(tempToc);
            //var newSteps = [newToc];
            //update content values
            let tempContent = content;
            tempContent[index].title = value;
            setContent(tempContent);
            /*
            content.forEach(function(step, i) {
                if (step.lab != labNames[labFilter] && labFilter != -1) { return; }
                newSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} />});
            })
            setSteps(newSteps);
            */
        } else {
            let newToc = generateTableOfContents(toc);
            //let newSteps = [newToc];
            //update content body
            let tempContent = content;
            tempContent[index].body = value;
            setContent(tempContent);
            /*
            content.forEach(function(step, i) {
                newSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} />});
            })
            setSteps(newSteps);
            */
        }
        props.returnData({ toc: toc, content: content, notes_taken: updateCount }, props.fieldKey);
    }

    var tabContents = generateTableOfContents(toc);
    let newSteps = [tabContents];
    content.forEach(function(step, i) {
        newSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} nav={props.navigation}/>});
    })

    const theme = useTheme();
    const [steps, setSteps] = React.useState(newSteps)
    //const maxSteps = steps.length;
    const [maxSteps, setMax] = React.useState(steps.length);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    //remove a note
    function deleteContent(noteIndex) {
        var noteId = steps[noteIndex].id;
        const index = toc.findIndex(item => item.id === noteId);
        //table of contents removal
        var tocData = toc;
        tocData.splice(index, 1);
        setToc(tocData);
        let newToc = generateTableOfContents(tocData);
        var tempSteps = [newToc];
        //content removal
        var contentData = content;
        contentData.splice(index, 1);
        setContent(contentData);
        contentData.forEach(function(step, i) {
            tempSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} nav={props.navigation}/>});
        });
        //reset filter
        setFilter(-1);
        //go back to table of contents
        setActiveStep(0);
        //update steps
        setSteps(tempSteps);
        //update the max steps, so dots match
        setMax(tempSteps.length);
        props.returnData({ toc: toc, content: content, notes_taken: noteCount }, props.fieldKey);
    }

    //show the delete button when they're on a note page
    function showDelete() {
        if (activeStepContent > 0) {
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <Box textAlign="center">
                        <Button variant="contained" style={{ backgroundColor: 'red', color: 'white'}} onClick={() => deleteContent(activeStepContent)}>Delete</Button>
                    </Box>
                </div>
            )
        }
    }

    //show home button to return to table of contents
    function showHome() {
        if (activeStepContent > 0) {
            return (
                <Button variant="contained" color="secondary" onClick={() => {
                    let newToc = generateTableOfContents(toc);
                    let newSteps = [newToc];
                    content.forEach(function(step, i) {
                        newSteps.push({id: step.id, title: step.title, body: <Note key={"note" + i} title={step.title} body={step.body} lab={step.lab} id={step.id} updateValue={updateValue} nav={props.navigation}/>});
                    })
                    setSteps(newSteps);
                    setActiveStep(0);
                    props.returnData({ toc: toc, content: content, notes_taken: noteCount }, props.fieldKey);}}>Save</Button>
            )
        }
    }

    //only show title on table of contents page
    function showTitle() {
        if (activeStepContent === 0) {
            return (steps[activeStepContent].title)
        }
    }

    //only show create note on table of contents page
    function showCreate() {
        if (activeStepContent === 0) {
            return (
                <Button variant="contained" onClick={() => createNote()}>New Note</Button>
            )
        }
    }

    //create a new note
    function createNote() {
        //if (labFilter != -1) { setFilter(props.activeLab) }
        var name = "New Note";
        //generate date
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = mm + "/" + dd + "/" + yyyy;
        var date = today;
        var body = "";
        //id for notes
        var noteId = noteCount;
        //add to table of contents
        let tempToc = toc;
        var newTocEntry = {title: name, date: date, lab: newLab, id: noteId};
        tempToc.push(newTocEntry);
        setToc(tempToc);
        let newToc = generateTableOfContents(tempToc);
        //add to content
        let tempContent = content;
        var newContent = {title: name, body: body, lab: newLab, id: noteId};
        tempContent.push(newContent);
        setContent(tempContent);
        let newSteps = [newToc];
        tempContent.forEach(function(item, i) {
            //if (item.lab != labNames[props.activeLab] && labFilter != -1) { return; }
            newSteps.push({id: item.id, title: item.title, body: <Note key={"note" + i} title={item.title} body={item.body} lab={item.lab} id={item.id} updateValue={updateValue} nav={props.navigation}/>});
        })
        noteId = noteId + 1;
        setNoteCount(noteId);
        setSteps(newSteps);
        setMax(newSteps.length);
        setActiveStep(maxSteps);
        props.returnData({ toc: toc, content: content, notes_taken: noteCount }, props.fieldKey);
    }

    const changeFilter = (event) => {
        setFilter(event.target.value);
        var newFilter = event.target.value;
        let tempToc = toc;
        if (newFilter !== -1) {
            tempToc = toc.filter(function (item) {
                return item.lab === labNames[newFilter]
            });
        }
        else {
            tempToc = toc;
        }
        let newToc = generateTableOfContents(tempToc);
        let newSteps = [newToc];
        content.forEach(function(item, i) {
            if (item.lab !== labNames[newFilter] && newFilter !== -1) { return; }
            newSteps.push({id: item.id, title: item.title, body: <Note key={"note" + i} title={item.title} body={item.body} lab={item.lab} id={item.id} updateValue={updateValue} nav={props.navigation}/>});
        })
        setSteps(newSteps);
        setMax(newSteps.length);
        setActiveStep(0);
    }

    function showFilter() {
        if (activeStepContent === 0) {
            return (
                <FormControl sx={{ m: 1, minWidth: 240 }} size="small">
                    <InputLabel id="lab-filter-small">Filter</InputLabel>
                    <Select
                        labelId="lab-filter-small"
                        id="lab-filter"
                        label="Filter"
                        value={labFilter}
                        onChange={changeFilter}
                    >
                        <MenuItem value={-1}>Show all labs</MenuItem>
                        {labNames.map((name, index) => {
                            return(<MenuItem value={index}>{name}</MenuItem>)
                        })}
                </Select>
                </FormControl>
            )
        }
    }


    return (
        <div style={{ width: '100%' }}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    {showFilter()}
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center" alignItems="center">
                        <Box sx={{ maxWidth: 800, width: '100%', p: 2 }}>
                            {showTitle()}
                            <br/>
                            <br/>
                            {steps[activeStepContent].body}
                            {showDelete()}
                            <MobileStepper
                                variant="dots"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStepContent}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStepContent === maxSteps - 1}
                                    >
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStepContent === 0}>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </Button>
                                }
                            />
                        </Box>
                    </Box>
                    <Box textAlign="center">
                            {showCreate()}
                            {showHome()}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default Notes;