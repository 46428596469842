import { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import axios from "axios";

export default function PyrEvalStatus() {

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [productionCount, setProductionCount] = useState(0);
    const [guestCount, setGuestCount] = useState(0);

    const MINUTE_MS = 60000;
    const pyrurl_status = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/status");
    const pyrurl_process = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/process_essays");
    const pyrurl_process_guest = process.env.REACT_APP_PYREVAL_URL && process.env.REACT_APP_PYREVAL_URL.concat("/process_guests");

    useEffect(() => {
        if (pyrurl_status) {
            axios
            .get(pyrurl_status)
            .then(result => {
                setButtonEnabled(result.data.lock);
                setProductionCount(result.data.production);
                setGuestCount(result.data.guest);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [])


    useEffect(() => {
        if (pyrurl_status) {
            const interval = setInterval(() => {
                    axios
                    .get(pyrurl_status)
                    .then(result => {
                        setButtonEnabled(result.data.lock);
                        setProductionCount(result.data.production);
                        setGuestCount(result.data.guest);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }, MINUTE_MS);

            return () => clearInterval(interval);   
        }
    }, [])

    function callProductionPyrEval() {
        //disable button
        setButtonEnabled(true);
        //call the processing url
        axios
        .get(pyrurl_process)
        .then(result => { 
            //console.log(result) 
        })
        .catch(error => {
            console.log(error);
        })
    }

    function callGuestPyrEval() {
        //disable button
        setButtonEnabled(true);
        //call the processing url
        axios
        .get(pyrurl_process_guest)
        .then(result => {
            //console.log(result);
        })
        .catch(error => {
            console.log(error);
        })
    }

    return(
        <div style={{marginLeft: 250, marginTop: 12}}>
            <br/>
            <br/>
            <Typography>Number of production essays waiting to be processed: {productionCount}</Typography>
            <Button variant="contained" color="primary" disabled={buttonEnabled} onClick={() => callProductionPyrEval()}>Run PyrEval (Production)</Button>
            <br/>
            <br/>
            <Typography>Number of guest essays waiting to be processed: {guestCount}</Typography>
            <Button variant="contained" color="primary" disabled={buttonEnabled} onClick={() => callGuestPyrEval()}>Run PyrEval (Guests)</Button>
        </div>
    )
}