import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import Button from '@material-ui/core/Button';

import store from "../../store";

class Test extends Component {

    /*
    Check user access levels
                        <div className="col s12 center-align">
                        <h4>
                            <b>Checking your access level,</b> {user.name.split(" ")[0]}
                            <p className="flow-text grey-text text-darken-1">
                                You are currently marked as: {user.access}
                            </p>
                        </h4>
                    </div>
    */

    render() {
        return(
            <div>
                Test!
            </div>
        )
    }
}

Test.propTypes = {
    auth: PropTypes.object.isRequired,
    lesson: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    lesson: state.lesson,
    navigation: state.navigation
});

export default connect(
    mapStateToProps
) (Test);