import React from 'react';
import {
  Typography,
  Box,
  Modal,
  TextField,
  Button
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function EditUnitModal ({ order, name, setName, prompt, setPrompt, open, handleClose, handleSubmit }) {
  return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Unit
          </Typography>
          <TextField
            id={`standard-basic-name-${order}`}
            variant="standard"
            label="Discussion Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            sx={{ m: 2 }}
          />
          <TextField
            id={`standard-basic-unit-${order}`}
            variant="standard"
            label="Unit Number"
            placeholder="0-100"
            value={order}
            disabled
            sx={{ m: 2 }}
          />
          <TextField
            id={`standard-basic-prompt-${order}`}
            variant="standard"
            label="Question Prompt"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            fullWidth
            required
            sx={{ m: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ mt: 2 }}
          >Edit</Button>
        </Box>
      </Modal>
  )
}