import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Link, Redirect } from "react-router-dom";

export function PageContentSlider(props) {
  const { steps, baseUrl, activeLab, activeTab, activeStep } = props;

  const theme = useTheme();

  // If no active step or step invalid, redirect to step 0
  if (activeStep === undefined || !(activeStep in steps)) {
    return <Redirect to={`${baseUrl}/${activeLab}/${activeTab}/0`} />;
  }

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{ flexGrow: 1 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
        <Box sx={{ maxWidth: 800, width: "100%", p: 2 }}>
          {steps[activeStep].description}
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={parseInt(activeStep)}
            nextButton={
              <Button
                component={Link}
                size="small"
                to={`${baseUrl}/${activeLab}/${activeTab}/${
                  parseInt(activeStep) + 1
                }`}
                //onClick={handleNext}
                disabled={parseInt(activeStep) === steps.length - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                component={Link}
                size="small"
                to={`${baseUrl}/${activeLab}/${activeTab}/${
                  parseInt(activeStep) - 1
                }`}
                disabled={parseInt(activeStep) === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </Box>
    </div>
  );
}

export default PageContentSlider;
