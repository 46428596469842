import CheckIcon from '@mui/icons-material/Check';
import IconButton from "@mui/material/IconButton";
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import Icon from "@mui/material/Icon";

import TextField from '@mui/material/TextField';
//For making border blank
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import React, { useState } from "react";

const StyledTextFieldTitle = styled(TextField)({
    [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "white",
    },
    [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "white",
    },
    [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
      borderColor: "white", 
    },
    [`& input::placeholder`]: {
        fontWeight: "bold",
        fontSize: "20px"
    },
});

export function EditNote(props) {

    const noteId = props.values.id;
    const [selectedColor, setColor] = React.useState(props.values.background);
    const [title, setTitle] = React.useState(props.values.title);
    const [body, setBody] = React.useState(props.values.body);
    const [allowSave, setSave] = React.useState(true);

    const [originalTitle, setOTitle] = React.useState(props.values.title);
    const [originalBody, setOBody] = React.useState(props.values.body);
    const [contentChanged, setContentChange] = React.useState(false);
    const [colorChanged, setColorChange] = React.useState(false);

    const titleChange = (event) => {
        setTitle(event.target.value);
        if (body != "" && event.target.value != "") {
            if (body == originalBody && event.target.value == originalTitle) {
                setContentChange(false);
                if (!allowSave && !colorChanged) { setSave(true); }
            } else {
                setContentChange(true);
                setSave(false);
            }
        } else {
            //if title is blank, no saving allowed
            setSave(true);
            //flag content issue so color doesn't override the save
            setContentChange(false);
        }
    }
    
    const bodyChange = (event) => {
        setBody(event.target.value);
        if (title != "" && event.target.value != "") {
            if (title == originalTitle && event.target.value == originalBody) {
                setContentChange(false);
                if (!allowSave && !colorChanged) { setSave(true) }
            } else {
                setContentChange(true);
                setSave(false);
            }
        } else {
            //if body is blank, no saving allowed
            setSave(true);
            //flag content issue so color doesn't override the save
            setContentChange(false);
        }
    }

    const changeColor = (selectedColor) => {
        setColor(selectedColor);
        if (selectedColor != props.values.background && title != "" && body != "") {
            //background color is different, so enable saving
            setSave(false);
            setColorChange(true);
        } else {
            //background colors are the same
            setColorChange(false);
            //check if save is enabled
            if (!allowSave) { 
                //saving is enabled
                //check if content has changed, if so, do nothing. otherwise, disable saving.
                if (!contentChanged) {
                    setSave(true);
                }
            }
        }
    }

    const saveNote = () => {
        props.save(title, body, selectedColor, props.values.keyVal, noteId);
    }

    var colorList = ['#EF9971', '#EFDA71', '#71EFB6', '#71C7EF', '#FFB8D2'] 
    //var colorList = ['#EF9971', '#EFDA71', '#BDEF71', '#71EFB6', '#71C7EF', '#EACCFF', '#ED71EF']

    return (
        <div>
            <StyledTextFieldTitle 
                sx={{paddingTop: 1}}
                placeholder="Add title here" 
                variant="outlined"
                value={title}
                onChange={titleChange} 
            />
            {colorList.map(color => {
                return color == selectedColor ? 
                    <IconButton key={color} sx={{backgroundColor: color, maxWidth: "25px", maxHeight: "25px", marginTop: 2.2}} onClick={() => changeColor(color)}>
                        <CheckIcon />
                    </IconButton>
                :
                    <IconButton key={color} sx={{backgroundColor: color, maxWidth: "25px", maxHeight: "25px", marginTop: 2.2}} onClick={() => changeColor(color)}>
                        <Icon />
                    </IconButton>
            })}
            <IconButton key={'default'} sx={{backgroundColor: '#FFFFFF', marginTop: 2.2}} size="small" onClick={() => changeColor('#FFFFFF')}>
                <FormatColorResetIcon />
            </IconButton>
            <br/>
            <TextField 
                placeholder='Add text here'
                variant="standard"
                multiline
                rows={6}
                sx={{backgroundColor: selectedColor, width: '100%', border: "black", paddingLeft: 1, paddingRight: 1}}
                InputProps={{
                    disableUnderline: true
                }}
                value={body}
                onChange={bodyChange}
            />
            <br/>
            <div>
                <Button key="cancel-button" variant="contained" sx={{backgroundColor: "white", color: "black", marginTop: 2.5, marginLeft: 35, '&:hover': {backgroundColor: '#FFFFFF'}}} onClick={() => props.cancel()}>Cancel</Button>
                <Button key="save-button" variant="contained" color="primary" disabled={allowSave} sx={{marginTop: 2.5, marginLeft: 5, marginRight: 1}} onClick={() => saveNote()}>Save</Button>
            </div>
            <br/>
        </div>
    )
}

export default EditNote;