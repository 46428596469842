// eslint-disable-next-line
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// It might make sense to merge ResearcherRoute with PrivateRoute,
// and accomodate an array of allowed access levels to be passed in as a property
const ResearcherRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            // If not authenticated with the Researcher access level, redirect back to login.
            auth.isAuthenticated === true && auth.user.access === 3 ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" />
            )
        }
    />
);

ResearcherRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps) (ResearcherRoute);