/*

    This is a Select Drop down component. This is the parent portion of the component
    which controls the children which have the actual select functionality.

    Required Props:
    question: The question that is being asked and requires drop down selects to answer.
    selects: The example below will explain more, but it's the needed information to correctly
        display a select.
    answers: For pre-setting the selects.


    Example Props:

    These are the options that will appear in the drop downs.

    let opt = [{name: "None", value: ""}, {name:"Ten", value: 10}, {name: "Twenty", value: 20}, {name: "Thirty", value: 30}]
    let opt2 = [{name: "None", value: ""}, {name:"One", value: 1}, {name: "Two", value: 2}, {name: "Three", value: 3}]
    let opt3 = [{name: "None", value: ""}, {name:"Cat", value: "cat"}, {name: "Dog", value: "dog"}, {name: "Bird", value: "bird"}]
    
    This is the variable that holds the needed information for the drop down.
    let dropTest = [{iLabel: "First", label: "Variable1", option: opt}, {iLabel: "Second", label: "Variable2", option: opt2}, {iLabel: "Third", label: "Variable3", option: opt3}]
    
    This is an example answer array. The size needs to match the number of items in the dropTest variable.
    They could also be blank. ['', '', '']
    let dropAnswer = [10, 2, 'bird']

    question="This is an example drop test."
    selects={dropTest}
    answers={dropAnswer}

*/

import { useState } from "react";
import Typography from "@material-ui/core/Typography";

import SelectDrop from "./SelectDrop";

export function PageSelectDrop(props) {
  // Storing all the answers in an array
  // Default to array of empty strings if there is no stored answer
  const [answers, setAnswers] = useState(
    props.answers?.answer ||
      Array.from(Array(props.options.length)).map(() => "")
  );

  //Callback function that is passed to the children
  //This allows the individual selects to send back their values
  //and update the proper index in the array
  const handleChange = (selectId, value) => {
    const newArr = [...answers];
    newArr[selectId] = value;
    setAnswers(newArr);
    props.returnData({ answer: newArr }, props.fieldKey);
  };

  return (
    <div>
      <Typography>{props.question}</Typography>
      {props.options.map((object, i) => (
        <SelectDrop
          key={i}
          data={object}
          answer={answers[i]}
          callback={(value) => handleChange(i, value)}
        />
      ))}
      <br />
    </div>
  );
}

export default PageSelectDrop;
