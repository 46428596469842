import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Button with Data Table
      </Typography>
      {props.buttons}
      <Typography>Do not use this component in an essay or place it inside of a content slider.</Typography>
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "PageDataTableButton",
      props: {
        fieldKey:
          authorId + "-databutton-" + Math.random().toString(36).substr(2, 6),
      },
    },
  };
}

export const PageDataTableButton = { createNew, Form, name: "Data Table Button (Lab)" };

export default PageDataTableButton;
