import { Component } from "react";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class Landing extends Component {

  render() {
    return (
      <div>
      <br />
      <br />
      <br />
      <Typography style={{fontSize: 34}} align="center">Welcome to the Science Notebook!
      <br />
      <br />
        <Button variant="contained" color="secondary" component={Link}
            to="/login"
            style={{
              width: "140px",
              borderRadius: "3px",
              letterSpacing: "1.5px"
            }}
          >
            Log In
        </Button>
      </Typography>
      </div>
    );
  }
}
export default Landing;