//This component will be clickable and expand to show information/text, and clicked again to collapse it.
//There needs to be 2 props
//1. Persistent Text
//2. Embedded Text
//Eventually, it should take additional components other than text.

import React from "react";
import Button from "@material-ui/core/Button";
import PageText from "../elements/PageText";

export function PageExpandCollapse(props) {
    //props.header = Always visible element
    //props.content = Expandable/Collapsable Information


    const [visible, setVisible] = React.useState(false);

    //Display the always persistent text.
    function displayHeader() {
        //possibly allowing big/regular selection in the future
        //h4 - Big
        //h6 - Regular
        return (
            //<Typography variant="h6" onClick={() => {setVisible(!visible)}}>{props.header}</Typography>
            <Button size="medium" variant="contained" color="primary" onClick={() => setVisible(!visible)}>{props.header}</Button>
        )
    }

    //Check if the content should be visible, and display if it should be.
    function displayContent() {
        if (visible) {
            return(
                <div>
                    <br />
                    <PageText children={props.content} />
                    <br />
                    <Button size="small" variant="contained" color="secondary" onClick={() => setVisible(false)}>Hide</Button>
                </div>
            )
        }
        else {
            <div></div>
        }
    }

    return(
        <div>
            {displayHeader()}
            {displayContent()}
        </div>
    )
}

export default PageExpandCollapse;