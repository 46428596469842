import { SET_CURRENT_LESSON } from "../actions/types";

const initialState = {
    lesson: {}
};
  
// eslint-disable-next-line
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_LESSON:
            return {
            ...state,
            lesson: action.payload
            };
        default:
            return state;
    }
  }