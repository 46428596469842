import * as Components from "../components/notebook/elements";
import { Typography } from "@mui/material";

//these should not appear in the editor
const editPreventComponents = ["PageDataTableButton", "PageTableButtons", "PagePyrEvalEssay", "PagePyrEvalFeedback", "PageDragTextButton"];
const stepperPreventComponents = ["AnalysisSummary"];

const ComponentCombiner = (props) => {
  const generateComponent = (
    component,
    fieldKey = component.props.fieldKey
  ) => {
    const Component = Components[component.type];
    const answers = props.pageAnswers[fieldKey];

    return (
      <Component
        navigation={props.navigation}
        baseUrl={props.baseUrl}
        activeLab={props.activeLab}
        tableHeaders={props.tableHeaders}
        answers={answers}
        key={fieldKey}
        returnData={props.updateAnswer}
        studentRequest={props.studentRequest}
        setRender={props.setRender}
        unlockLab={props.unlockLab}
        {...component.props}
      />
    );
  };

  return (
    <div>
      {props.collection.map((component) => {
        if (props.editor && editPreventComponents.includes(component.type)) {
          return <Typography>{component.type} Created! Since this component needs other information to work, it cannot be displayed here.</Typography>;
        }
        if (component.type == "PageContentSlider" && props.editor) {
          //due to the url re-write, it would take more time than I have to make the regular component editor friendly
          var stepProps = [];
          let steps = component.props.steps;
          steps.map((step) => {
            let compHolder = [];
            //step will include a label and description which contains all the components
            step.description.map((item) => {
              //check if AnalysisSummary and editor to prevent generation
              //if there is an AnalysisSummary, update the answer data here
              if (item.type === "AnalysisSummary") {
                if (props.editor) {
                  let analysisHolder = <Typography>Analysis Summary Created! Since this component needs other information to work, it cannot be displayed here.</Typography>;
                  compHolder.push(analysisHolder);
                } else {
                  props.collection.forEach((value) => {
                    if (value.type === "PageContentSlider") {
                      item.props.questions = value.props.steps;
                    }
                  });
                  item.props.answers = props.pageAnswers;
                  let tempComp = generateComponent(item);
                  compHolder.push(tempComp);
                }
              }
              //item is a component contained in the content stepper
              else {
                let tempComp = generateComponent(item);
                compHolder.push(tempComp);
              }
            });
            //add the label and the corresponding components for the given step
            let stepData = { label: step.label, description: compHolder };
            stepProps.push(stepData);
          });
          return (
            <Components.PageContentSliderEditor
              key={component.props.fieldKey}
              steps={stepProps}
              baseUrl={props.baseUrl}
              activeLab={props.activeLab}
              activeTab={props.activeTab}
              activeStep={props.activeStep}
            />
          );
        }
        else if (component.type === "PageContentSlider") {
          var stepProps = [];
          let steps = component.props.steps;
          steps.map((step) => {
            let compHolder = [];
            //step will include a label and description which contains all the components
            step.description.map((item) => {
              //check if AnalysisSummary and editor to prevent generation
              //if there is an AnalysisSummary, update the answer data here
              if (item.type === "AnalysisSummary") {
                if (props.editor) {
                  return null;
                }
                props.collection.forEach((value) => {
                  if (value.type === "PageContentSlider") {
                    item.props.questions = value.props.steps;
                  }
                });
                item.props.answers = props.pageAnswers;
                let tempComp = generateComponent(item);
                compHolder.push(tempComp);
              }
              //item is a component contained in the content stepper
              else {
                let tempComp = generateComponent(item);
                compHolder.push(tempComp);
              }
            });
            //add the label and the corresponding components for the given step
            let stepData = { label: step.label, description: compHolder };
            stepProps.push(stepData);
          });
          return (
            <Components.PageContentSlider
              key={component.props.fieldKey}
              steps={stepProps}
              baseUrl={props.baseUrl}
              activeLab={props.activeLab}
              activeTab={props.activeTab}
              activeStep={props.activeStep}
            />
          );
        } else if (component.type === "PageSimpleTable") {
          // let table = ContainerComponent(component.props.tableContents);
          // console.log("this table is undefined for some reason")
          // console.log(table)
          // console.log(component.props.tableContents);
          // return (<ContainerComponent props={component.props.tableContents} />)
        } else if (component.type === "PageRollercoasterSim") {
          return generateComponent(component, props.tableKey);
        } else if (component.type in Components) {
          return generateComponent(component);
        } else {
          console.log(
            component.type +
              " not found in list of components but requested from database"
          );
        }
      })}
    </div>
  );
};

export default ComponentCombiner;
