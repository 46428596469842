/*

    This is a page component for a radio button grouping.

    When this component is called, it is expected that props will be passed
    to it. The props are necessary to populate the element.


    *Required Props*

    question: The text body of the question, or what is being asked.
    data: The data contains the radio button information including the value
          and the label for the button.


    *Additional Props*

    answer: If a value has been selected and retrieved from the database,
              it can be passed with 'selected' to have it marked.


    Example props.

    question="This is an example radio button question."
    data={radioData}
    answer=""

    where radioData = [{value: "yes", label: "Yes"},
                       {value: "no", label: "No"}, 
                       {value: "maybe", label: "Maybe"}]


*/

import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";

export function PageRadioButton(props) {
  const [value, setValue] = React.useState(props.answers?.answer || "");

  //Function to handle when the user types in the answer
  const handleChange = (event) => {
    setValue(event.target.value);
    props.returnData({ answer: event.target.value }, props.fieldKey);
  };

  return (
    <div>
      <br />
      <Typography>{props.question}</Typography>
      <FormControl>
        <RadioGroup onChange={handleChange} value={value}>
          {props.data.map((object) => (
            <FormControlLabel
              key={props.fieldKey + object.value}
              value={object.value}
              control={<Radio />}
              label={object.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <br />
    </div>
  );
}

export default PageRadioButton;
