import { useState} from 'react';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Fab,
  FormHelperText,
  Box,
  Chip
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddIcon from '@material-ui/icons/Add';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(tag, selectedTags, theme) {
  return {
    fontWeight:
    selectedTags.indexOf(tag) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullClassAuthoring({
  classes, handleSetMessage, handleModalOpen, classInfo
}) {
  const theme = useTheme();

  const [discussionName, setDiscussionName] = useState('');
  const [prompt, setPrompt] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [order, setOrder] = useState('');
  const [classId, setClassId] = useState('')

  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);

  const handleTagChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleNewTag = (e) => {
    e.preventDefault();
    if (newTag && selectedTags.indexOf(newTag) === -1) {
      setTags(tags.concat(newTag));
      setSelectedTags(selectedTags => [...selectedTags, newTag]);
    }
    setNewTag('');
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const discussion = await axios.get('/api/discussion/retrieve', {
        params: { unit: order, groupNumber: 'full' }
      });
      const unit = await axios.get('/api/unit/retrieve/order', {
        params: { order }
      });
      if (!discussion.data) {
        const res = await axios.post('/api/discussion/save', {
          name: discussionName,
          groupNumber: 'full',
          prompt,
          unit: order,
          classId
        });
        // unit creation
        if (!unit.data) {
          await axios.post('/api/unit/create', {
            name: discussionName,
            prompt,
            order,
            classId,
            tags: selectedTags
          });
        } else {
          // append new tags
          await axios.put('/api/unit/tags', {
            tags: selectedTags
          });
        }
        const win = window.open(`/fulldiscussion/${res.data._id}`, '_blank');
        win.focus();
        handleSetMessage('Full discussion creation was successful!');
      } else {
        handleSetMessage('A full discussion already exists for this unit.');
      }
      handleModalOpen();
    } catch (err) { console.log(err.response.data); setError(err.response.data) }
  }

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            id="standard-basic-full-name"
            variant="standard"
            value={discussionName}
            onChange={(event) => setDiscussionName(event.target.value)}
            label="Discussion Name"
            required
          />
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            id="standard-basic-full-unit"
            variant="standard"
            label="Unit Number"
            placeholder="0-100"
            value={order}
            onChange={(event) => setOrder(event.target.value)}
            required
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            id="standard-basic-full-prompt"
            variant="standard"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            label="Question Prompt"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl variant="standard" sx={{ m: 1, width: 300 }}>
            <InputLabel id="class-select-label">Class *</InputLabel>
            <Select
              labelId="class-select-label"
              id="class-select-full"
              value={classId}
              onChange={(e) => setClassId(e.target.value)}
              label="Class *"
            >
              {classInfo.map(({_id, info}, index) => (
                <MenuItem key={index} value={_id} className={classes.menuItem}>
                  {info}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
            <InputLabel id="tag-select-label">Tags</InputLabel>
            <Select
              labelId="tag-select-label"
              id="tag-select-full"
              multiple
              value={selectedTags}
              onChange={handleTagChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {tags.map((tag) => (
                <MenuItem key={tag} value={tag} style={getStyles(tag, selectedTags, theme)}>
                  {tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <TextField
            id="standard-basic-full-tag"
            variant="standard"
            value={newTag}
            onChange={(event) => setNewTag(event.target.value)}
            placeholder="New Tag"
          />
          <Fab
            size="small"
            color="secondary"
            aria-label="add"
            onClick={handleNewTag}
          >
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >create full class discussion</Button>
        </Grid>
      </Grid>
    </form>
  )
}