import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  const updateCount = (event) => {
    const newLength = parseInt(event.target.value);
    const length = props.data.data.length;
    let newArr;
    if (newLength < length) {
      newArr = props.data.data.slice(0, length - 1);
    } else {
      newArr = [...props.data.data];
      newArr[length] = { value: "", label: "" };
    }
    props.updateComponent("data", newArr);
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateRadio(index, optionData) {
    const options = deepCopy(props.data.data);
    options[index] = optionData;
    props.updateComponent("data", options);
  }

  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Radio Buttons
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        placeholder=""
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        multiline
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <Typography style={{ display: "inline-block" }}>
        How many radio buttons?
      </Typography>
      <TextField
        id="count"
        type="number"
        value={props.data.data.length}
        style={{ width: 50, marginTop: -6, marginLeft: 10 }}
        onChange={updateCount}
      />
      <br />
      {props.data.data.map((option, index) => (
        <div key={index}>
          <TextField
            id={`radio${index}`}
            label="Value"
            value={option.value}
            onChange={(event) =>
              updateRadio(index, { ...option, value: event.target.value })
            }
            placeholder=""
          />
          <TextField
            style={{ marginLeft: 10 }}
            id={`radio${index}label`}
            label="Label"
            value={option.label}
            onChange={(event) =>
              updateRadio(index, { ...option, label: event.target.value })
            }
          />
        </div>
      ))}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      selected: "",
    },
    comp: {
      type: "PageRadioButton",
      props: {
        data: [],
        fieldKey:
          authorId + "-radio-" + Math.random().toString(36).substr(2, 6),
        question: "",
      },
    },
  };
}

export const PageRadioButton = { createNew, Form, name: "Radio Buttons" };

export default PageRadioButton;
