import styled from 'styled-components';

export const Note = styled.div`
  background-color: #ffffff;
  border: 7px solid ${props => props.groupBorderColor ? props.groupBorderColor : "white"};
  border-radius: 23px;
  border-color: #c0ce6d;
  box-shadow: 0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25), 0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25);
  height: 146px;
  width: 217px;
  overflow: scroll;
  margin: 0 18px 18px;

  & .text-wrapper {
    color: #000000;
    font-family: "Lato-Bold", Helvetica;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin-top: 10px;
  }
`