import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import PageSelect from "./SelectForm";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectForm: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
}));

function Form(props) {
  const classes = useStyles();

  const updateCount = (event) => {
    const newLength = parseInt(event.target.value);
    const length = props.data.options.length;
    let newOptions, newAns;
    if (newLength < length) {
      newOptions = props.data.options.slice(0, length - 1);
      newAns = { answer: props.answer.answer.slice(0, length - 1) };
    } else {
      newOptions = [
        ...props.data.options,
        { iLabel: "", label: `select${length}`, option: [] },
      ];
      newAns = { answer: [...props.answer.answer, ""] };
    }
    props.updateComponent("options", newOptions);
    props.updateAnswer(newAns);
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateOption(index, optionData) {
    const options = deepCopy(props.data.options);
    options[index] = optionData;
    props.updateComponent("options", options);
  }

  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        MultiSelect
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        placeholder=""
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        multiline
        style={{ width: '100%' }}
      />
      <br />
      <br />
      <Typography style={{ display: "inline-block" }}>
        How many selects?
      </Typography>
      <TextField
        id="count"
        type="number"
        value={props.data.options.length}
        style={{ width: 50, marginTop: -6, marginLeft: 10 }}
        onChange={updateCount}
      />
      {props.data.options.map((option, index) => (
        <Paper elevation={2} className={classes.selectForm} key={index}>
          <PageSelect.Form
            updateComponent={(propName, value) =>
              updateOption(index, { ...option, [propName]: value })
            }
            updateAnswer={() => {}}
            data={option}
          />
        </Paper>
      ))}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: [],
    },
    comp: {
      type: "PageSelectDrop",
      props: {
        fieldKey:
          authorId + "-selectdrop-" + Math.random().toString(36).substr(2, 6),
        options: [],
        question: "",
      },
    },
  };
}

export const PageSelectDrop = { createNew, Form, name: "MultiSelect" };
