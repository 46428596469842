import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./access.css"

import { logoutUser } from "../../actions/authActions";
import { changeAccess } from "../../actions/accessChangeActions";

class Access extends Component {
    //Constructor to hold access code
    constructor() {
        super();
        this.state = {
            code: "",
            errors: {}
        };
    }

    //Redux code
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    //Detect changes and update the state
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const codeData = {
            code: this.state.code,
            userid: this.props.auth.user.id,
            name: this.props.auth.user.name
        };
        this.props.changeAccess(codeData, this.props.history);
    };

    //If the user doesn't have a code, they should just logout until they have it.
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    
    //If the user has already gotten access changed, redirect to the dashboard.
    componentDidMount() {
        const { user } = this.props.auth;
        if (user.access !== 0) {
            this.props.history.push("/dashboard");
        }
    }

    //When the state updates, see if the user access level has updated as well
    componentDidUpdate() {
        const { user } = this.props.auth;
        if (user.access !== 0) {
            this.props.history.push("/dashboard");
        }
    }

    render() {
        const { user } = this.props.auth;

        return (
            <div style={{ height: "75vh" }} className="container valign-wrapper">
                <div className="row">
                    <div className="col s12 center-align">
                        <h4>
                            <b>Hey there,</b> {user.name.split(" ")[0]}
                            <p className="flow-text grey-text text-darken-1">
                                To access material relevant to you, please enter 
                                the access code you were given.
                            </p>
                        </h4>
                        <form noValidate onSubmit={this.onSubmit}>
                            <div className="input-field col s12">
                                <input 
                                    onChange={this.onChange} 
                                    value={this.state.code} 
                                    id="code" 
                                    type="text"
                                    style={{
                                        width: "150px",
                                    }}
                                    placeholder="Access Code"
                                />
                            </div>
                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <button 
                                    style={{
                                        width: "300px",
                                        borderRadius: "3px",
                                        letterSpacing: "1.5px",
                                        marginTop: "1rem"
                                    }}
                                    type="submit" 
                                    className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                                >
                                    Request Access
                                </button>
                            </div>
                        </form>
                        <button
                            style={{
                                width: "150px",
                                borderRadius: "3px",
                                letterSpacing: "1.5px",
                                marginTop: "1rem"
                            }}
                            onClick={this.onLogoutClick}
                            className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

Access.propTypes = {
    changeAccess: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { logoutUser, changeAccess }
) (Access);