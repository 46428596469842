import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";


const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    tableHeader: {
        fontWeight: "bold",
    },
    iconColumn: {
        width: 63,
    },
});

class DeleteData extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            students: [],  // array of students
            deleteAllConfirm: false,
        };
    }

    componentDidMount(){
        this.fetchStudentsConsentNo();
    }

    fetchStudentsConsentNo = () => {
        // Get all the students who did not consent to their data being used for research
        axios.get("/api/users/getStudentsNoConsent").then(response => {
            this.setState({
                students: response.data
            });
        })
        .catch(e =>{
            console.log(e);
        });
    }

    setDeleteAllConfirm(value) {
        this.setState({deleteAllConfirm: value});
    }

    deleteAllNonConsentingData() {
        axios.post("/api/users/deleteAllNonConsentingStudents", {students: this.state.students}).then(response => {
            this.setState({
                students: response.data,
                deleteAllConfirm: false
            });
        })
        .catch(e =>{
            this.setDeleteAllConfirm(false);
            console.log(e);
        });
    }

    deleteOneNonConsentingData(email) {
        axios.post("/api/users/deleteStudent", {email: email}).then(response => {
            if(response.data.success) {
                // delete the student from students array
                let students = this.state.students;
                let index = students.findIndex((student) => {return student.email === email});
                students.splice(index, 1);
                this.setState({
                    students: students
                });
            }
        })
        .catch(e =>{
            console.log(e);
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.pageMargin}>
                <Grid justifyContent="space-between" container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" component="h2" color="primary">Delete Student Data - Non-Consenting Students</Typography>
                        <Alert severity="warning">Deletion of students and their data is not reversible.</Alert>
                    </Grid>
                    {this.state.students.length > 0 ?
                        <React.Fragment key="students">
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" onClick={() => this.setDeleteAllConfirm(true)}>Delete All Data From Non-Consenting Students</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.iconColumn}>&nbsp;</TableCell>
                                                <TableCell className={classes.tableHeader}>Name</TableCell>
                                                <TableCell className={classes.tableHeader}>Email</TableCell>
                                                <TableCell className={classes.tableHeader}>School</TableCell>
                                                <TableCell className={classes.tableHeader}>Term</TableCell>
                                                <TableCell className={classes.tableHeader}>Academic Year</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.students.map((student, i) => (
                                                <TableRow key={student.email}> 
                                                    <TableCell className={classes.iconColumn}><Button variant="text" onClick={() => this.deleteOneNonConsentingData(student.email)}><DeleteIcon /></Button></TableCell>
                                                    <TableCell>{student.first_name + " " + student.last_name}</TableCell>
                                                    <TableCell>{student.email}</TableCell>
                                                    <TableCell>{student.school}</TableCell>
                                                    <TableCell>{student.implementationTerm}</TableCell>
                                                    <TableCell>{student.academicYear}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </React.Fragment>
                    : <Grid item xs={12}>No non-consenting students</Grid> }
                </Grid>
                <Dialog
                    open={this.state.deleteAllConfirm}
                    onClose={() => this.setDeleteAllConfirm(false)}
                    aria-labelledby="alert-delete-all-title"
                    aria-describedby="alert-delete-all"
                >
                    <DialogTitle id="alert-delete-all-title">
                        Delete Data
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-delete-all">
                            <Typography component="span">Are you sure you want to delete all data for non-consenting students?</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => this.setDeleteAllConfirm(false)}>Cancel</Button>
                        <Button variant="contained" onClick={() => this.deleteAllNonConsentingData()}>Delete All</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (DeleteData));

