import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';

import { createClass } from "../../actions/accessChangeActions";

class CreateClass extends Component {
    //Constructor to hold class ID
    constructor() {
        super();
        this.state = {
            code: "",
            title: "",
            errors: {}
        };
    }

    //Redux code
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    //If the user is not a teacher (access level 2), redirect to the dashboard
    componentDidMount() {
        const { user } = this.props.auth;
        if (user.access !== 2) {
            this.props.history.push("/dashboard");
        }
    }

    //Detect changes and update the state
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    //Function to generate a unique access code.
    /*
    1. Generate code
    2. Check if code is unique
    3a. If unique, update the access code input
    3b. If not unique, back to step 1.
    */
    generateCode = () => {
        var newCode = "";
        axios.get("/api/access/generate").then(res => {
            newCode = res.data;
            document.getElementById("code").value = newCode;
            this.state.code = newCode;
        })
    }

    onSubmit = e => {
        e.preventDefault();

        const { user } = this.props.auth;
        
        const codeData = {
            code: this.state.code,
            level: user.access,
            title: this.state.title,
            teacherid: user.id
        };

        //This redirect is handled within this component, so it does not need
        //to be passed to this.props.history as a parameter.
        this.props.createClass(codeData, this.props.history);
    };

    render() {

        return (
            <div style={{ height: "75vh" }} className="container valign-wrapper">
                <div className="row">
                    <div className="col s12 center-align">
                        <h4>
                            <p className="flow-text grey-text text-darken-1">
                                You can create classes here. Enter a unique <b>six</b> character code and a course title.
                                <br/>
                                Once students use this code, you will be able to see them in your 
                                classroom management tools.
                            </p>
                        </h4>
                        <form noValidate onSubmit={this.onSubmit}>
                            <div className="input-field col s12">
                                <input 
                                    value={this.state.code} 
                                    onChange={this.onChange} 
                                    id="code" 
                                    type="text"
                                    maxLength="6"
                                    style={{
                                        width: "150px",
                                    }}
                                    placeholder="Access Code"
                                />
                                <button 
                                    style={{
                                        width: "125px",
                                        borderRadius: "3px",
                                        letterSpacing: "1.5px",
                                        marginTop: "0.5rem",
                                        marginLeft: "20px"
                                    }}
                                    type="button" 
                                    onClick={this.generateCode}
                                    className="btn btn-medium waves-effect waves-light hoverable green accent-3"
                                >
                                    Generate
                                </button>
                            </div>
                            <div className="input-field col s12">
                                <input 
                                    onChange={this.onChange} 
                                    value={this.state.title} 
                                    id="title" 
                                    type="text"
                                    style={{
                                        width: "300px",
                                    }}
                                    placeholder="Class Title"
                                />
                            </div>
                            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                                <button 
                                    style={{
                                        width: "300px",
                                        borderRadius: "3px",
                                        letterSpacing: "1.5px",
                                        marginTop: "1rem"
                                    }}
                                    type="submit" 
                                    className="btn btn-large waves-effect waves-light hoverable blue accent-3"
                                >
                                    Create New Class
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

CreateClass.propTypes = {
    createClass: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { createClass }
) (CreateClass);