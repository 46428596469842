import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    img: {
        maxWidth: "90%",
        maxHeight: "60%"
        }
});


export function PageImage(props) {
    
    const classes = useStyles();
    
    return (
        <div>
            <img className={classes.img} src={props.imageloc} alt={""} />
        </div>
    )
}

export default PageImage;