import { TextField, Typography } from "@mui/material";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        PyrEval Essay
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
      <br/>
      <br/>
      <TextField
        type="number"
        id="essay number"
        label="Essay Number"
        value={props.data.essay_number}
        onChange={(event) =>
          props.updateComponent("essay_number", event.target.value)
        }
        placeholder=""
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answers: {
        answer: "",
        feedback: {
            1: "",
            2: "",
        },
        essays: {
            1: "",
            2: "",
        }
      }
    },
    comp: {
        type: "PagePyrEvalEssay",
        props: {
            fieldKey:
                authorId + "-pyressay-" + Math.random().toString(36).substr(2, 6),
            essay_number: 0,
            question: "",
      },
    },
  };
}

export const PagePyrEvalEssay = { createNew, Form, name: "PyrEval Essay" };

export default PagePyrEvalEssay;
