import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useGridRegisterStrategyProcessor } from '@mui/x-data-grid/hooks/core/strategyProcessing';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { StyledSpeedDial } from '../../ildlTheme';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';



const styles  = (theme) => ({
    pageMargin: {
        marginLeft: 275,
        marginTop: theme.spacing(3),
    },
    tableHeader: {
        fontWeight: "bold",
    },
});

class SchoolList extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            schools: [],  // array of school objects with the name and _id
            open: false
        };

    }

    componentDidMount(){
        this.fetchSchools();
    }

    fetchSchools = () => {
        // Get all the schools from the backend
        axios.get("/api/schools/getSchools").then(response => {
            this.setState({
                schools: response.data
            });
        })
        .catch(e =>{
            console.log(e);
        });
    }

    setOpen = () => {
        this.setState({ open: !this.state.open});
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.pageMargin}>
                <Grid justifyContent="space-between" container spacing={4}>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" component="h2" color="primary">Manage Schools</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <StyledSpeedDial
                            ariaLabel="Manage schools actions"
                            open={this.state.open}
                            onClose={this.setOpen}
                            onOpen={this.setOpen}
                            icon={<SpeedDialIcon />}
                            direction="down"
                        >
                            <SpeedDialAction
                                key="school"
                                icon={<Link to={"/school/"} component={RouterLink}><AddIcon /></Link>}
                                tooltipTitle="Add&nbsp;School"
                                tooltipOpen
                                onClick={this.setOpen}
                            />
                        </StyledSpeedDial>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeader}>School Name</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.schools.map((school, i) => (
                                <TableRow key={"schoolRow" + i}> 
                                    <TableCell>{school.name}</TableCell>
                                    <TableCell><Link to={"/school/" + school._id} component={RouterLink}>Edit</Link></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

useGridRegisterStrategyProcessor.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
   ) (withStyles(styles) (SchoolList));

