import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    buttons: {
        marginRight: 15,
        fontSize: '27px',
        minWidth: '15em',
        maxWidth: '15em',
        minHeight: '4em',
        maxHeight: '4em',
        width: '15em',
        height: '4em'
    },
    link: {
        textDecoration: 'none',
        color: '#fff',
        '&:visited': {
            textDecoration: 'none',
            color: '#fff'
        }
    }
}))

export default function ResearchDashboard({userLevel}) {

    const classes = useStyles();
    let links = [
        {
            route: '/authoring',
            label: 'Discussion Authoring'
        },
        {
            route: '/roomview',
            label: 'Room View'
        },
        {
            route: '/datadownload',
            label: 'Data Download'
        },
        {
            route: '/notebook',
            label: 'Student View'
        },
        {
            route: '/users',
            label: 'User Management'
        },
        {
            route: '/seatingchart',
            label: 'Room View Manager'
        }
    ]

    let adminLinks = [
        {
            route: '/assignlesson',
            label: 'Assign Lesson'
        },
        {
            route: '/createunit',
            label: 'Unit Info'
        }
    ];

    // Two additional admin-only links
    if (userLevel === 3) {
        links = links.concat(adminLinks)
    }
    return (
        <Grid container spacing={1}>
            {links.map((link, index) => <Grid item xs={12} md={6} lg={4}>
                <Link key={index} className={classes.link} to={link.route}>
                    <Button key={index} className={classes.buttons} variant="contained" color="primary">{link.label}</Button>
                </Link>
            </Grid>)}
        </Grid>
    )
}