import axios from "axios";
import jwt_decode from "jwt-decode";

import setAuthToken from "../utils/setAuthToken";

import {
    GET_ERRORS,
    SET_CURRENT_USER
} from "./types";

//Action 1: Get auth level from access code.
export const changeAccess = (accessCode, history) => dispatch => {
    axios
        .post("/api/access/request", accessCode)
        .then(res => {
           //This is replicating the user login logic
           //Eventually this could be changed, but might not be needed
           //Store the token locally if successful login
           const { token } = res.data;
           localStorage.setItem("jwtToken", token);
           //Set token to Auth header
           setAuthToken(token);
           //Decode token to get user data
           const decoded = jwt_decode(token);
           //Set the current user
           dispatch(setCurrentUser(decoded));
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.message
            })
        );
};

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

//Action 2: Create new class
export const createClass = (courseData, history) => dispatch => {
    axios
        .post("/api/access/create", courseData)
        .then(res => history.push("/dashboard"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.message
            })
        );
};