import UnitTree from "./UnitTree"
import Editor from "../Editor";
import SimulationContent from "./SimulationContent";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import classNames from "classnames/bind";

const useStyles = makeStyles((theme) => ({
    editor: {
        position: "absolute",
        top: 0,
        backgroundColor: theme.palette.background.default,
        minHeight: "75vh",
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
    },
    dNone: {
        display: "none",
    }
}));


export default function ContentCreator() {

    const cx = classNames.bind(useStyles());
    const { lessonName } = useParams();

    const [pageChanged, setPageChanged] = useState(false);
    //page information
    const [pageID, setPageID] = useState("");
    const [pageObjs, setPageObjs] = useState([]);
    const [ansObjs, setAnswerObjs] = useState({});
    const [editorTest, setEditorTest] = useState([]);
    const [hideEditor, setHideEditor] = useState(true);

    //lab information for publishing
    const [labIndex, setLabIndex] = useState(-1);
    const [tabType, setTabType] = useState("");
    const [refreshComponent, setRefresh] = useState(false);

    //editor container css classes
    const className = cx({
        editor: true,
        dNone: hideEditor
    });

    useEffect(() => {
        //if page is blank, don't attempt to retrieve anything
        if (pageID != "" && !pageID.includes("-Sim-")) {
            axios
            .post("/api/pagecreate/getpagedata", {
                page: pageID
            })
            .then(result => {
                setPageObjs(result.data.pageObjs);
                setAnswerObjs(result.data.ansObjs);
                let newEditor = <Editor
                    key={pageID}
                    pageObjects={result.data.pageObjs}
                    answerObjects={result.data.ansObjs}
                    updatePage={updatePage}
                    setChanged={() => setPageChanged(true)}
                    publishPage={publishPage}
                    cancelEdit={cancelEdit}
                />
                // Show editor container
                setHideEditor(false);
                // Set container content
                setEditorTest([newEditor]);
            })
            .catch(error => {
                console.log(error);
            })
        } else if (pageID.includes("-Sim-")) {
            axios
            .post("/api/pagecreate/getsim", {
                page: pageID
            })
            .then(result => {
                setPageObjs(result.data.pageObjs);
                setAnswerObjs(result.data.ansObjs);
                let simEditor = <SimulationContent pageObjs={result.data.pageObjs} ansObjs={result.data.ansObjs} updatePage={updateSimulation} publishPage={publishSimulation} cancelEdit={cancelEdit} />
                // Show editor container
                setHideEditor(false);
                // Set container content
                setEditorTest([simEditor])
            })
            .catch(error => {
                console.log(error);
            })
        } else {
            // Hide editor container
            setHideEditor(true);
            // Remove container content
            setEditorTest();
        }
    }, [pageID])

    const updatePage = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/updatepagedata", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns
        })
        .then(result => {
            //console.log(result);
            // Reset pageID (and hide editor container)
            setPageID("");
        })
        .catch(error => {
            console.log(error);
        })
    }

    const publishPage = (newPageObjs, newPageAns) => {
        let tab = tabType.toLowerCase();
        if (tab.trim().length == 0) {
            tab = "home";
        }
        axios
        .post("/api/pagecreate/publishpage", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
            labIndex: labIndex,
            tabType: tab,
            lesson: lessonName,
        })
        .then(result => {
            //console.log(result);
            setRefresh(!refreshComponent);
            // Reset pageID (and hide editor container)
            setPageID("");
        })
        .catch(error => {
            console.log(error);
        });
    }

    const updateSimulation = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/updatesimulation", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
        })
        .then(result => {
            //console.log(result);
            // Reset pageID (and hide editor container)
            setPageID("");
        })
        .catch(error => {
            console.log(error);
        })
    }

    const publishSimulation = (newPageObjs, newPageAns) => {
        axios
        .post("/api/pagecreate/publishsimulation", {
            pageID: pageID,
            newObjs: newPageObjs,
            newAnsObjs: newPageAns,
            labIndex: labIndex,
            lesson: lessonName,
        })
        .then(result => {
            //console.log(result);
            setRefresh(!refreshComponent);
            // Reset pageID (and hide editor container)
            setPageID("");
        })
        .catch(error => {
            console.log(error);
        });
    }

    const cancelEdit = () => {
        // Reset pageID (and hide editor container)
        setPageID("");
    }

    return (
        <div style={{marginLeft: 250, marginTop: 12, position: "relative"}}>
            <UnitTree setPage={setPageID} setLabIndex={setLabIndex} setTabType={setTabType} refresh={refreshComponent} lesson={lessonName} />
            <div id="editor-container" className={className}>
                {editorTest}
            </div>
        </div>
    )
}