import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
    selectLesson: {
        marginLeft: 10,
        marginRight: 15,
    },
    selectClass: {
        marginLeft: 13,
        marginRight: 24,
    },
    controlButtons: {
        marginLeft: 250,
    },
});

export default function AssignLesson() {

    const classes = useStyles();

    const [lessonData, setLessonData] = React.useState([]);
    const [classesData, setClassesData] = React.useState([]);
    const [visibleLesson, setVisibleLesson] = React.useState(false);
    const [visibleClass, setVisibleClass] = React.useState(false);
    const [lessonRender, setLessonRender] = React.useState(true);
    const [classRender, setClassRender] = React.useState(true);
    const [selectedLesson, setSelectedLesson] = React.useState("");
    const [selectedClass, setSelectedClass] = React.useState("");
    const [assignButton, setButton] = React.useState(false);

    //get all of the lessons and classes
    useEffect(() => {
        axios.post('/api/pagecreate/getnav')
        .then((response) => {
            setLessonData(response.data);
            setVisibleLesson(true);
        })
        .catch(error => {
            console.log(error);
        });

        axios.post('/api/pagecreate/getclass')
        .then((response) => {
            setClassesData(response.data);
            setVisibleClass(true);
        })
        .catch(error => {
            console.log(error);
        });

    }, []);

    //just a hacky way to show the lessons
    function showLessons() {
        if (visibleLesson) {
            return (
                <div>
                    <Autocomplete
                        key={lessonRender}
                        id="auto-complete-lesson"
                        options={lessonData}
                        getOptionLabel={(option) => (option.Lesson_Name + " (" + option.Lesson_Id + ")")}
                        style={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label="Lessons" variant="outlined" size="small" />}
                        onChange={(event, value) => setSelectedLesson(value)}
                    />
                </div>
            )
        }
    }

    //hacky way to show the classes
    function showClasses() {
        if (visibleClass) {
            return (
                <div>
                    <Autocomplete
                        key={classRender}
                        id="auto-complete-classes"
                        options={classesData}
                        getOptionLabel={(option) => option.info}
                        style={{ width: 500 }}
                        renderInput={(params) => <TextField {...params} label="Classes" variant="outlined" size="small" />}
                        onChange={(event, value) => setSelectedClass(value)}
                    />
                </div>
            )
        }
    }

    //reset all the inputs
    function reset() {
        setSelectedLesson("");
        setLessonRender(!lessonRender);
        setSelectedClass("");
        setClassRender(!classRender);
    }

    //this needs to be redone, likely LessonAnswerTemplate needs to be redone
    //to store all the page names and answer objects in one document. Currently
    //it's calling too many queries which isn't good.
    /* HAS TO BE CHANGED */
    function save() {
        /* hacky solution
        axios.post('/api/pagecreate/generateanswertemplates', {
            lessonId: selectedLesson.lessonId,
        })
        .then((response) => {
            console.log(response);
        }, (error) => {
            console.log(error);
        });
        */
        if (selectedClass !== "" && selectedLesson !== "") {
            setButton(true);
            axios.post('/api/pagecreate/createanswerpages', {
                    lessonName: selectedLesson.Lesson_Name,
                    students: selectedClass.students,
                    teachers: selectedClass.teachers,
                    classroom: selectedClass._id,
                    navId: selectedLesson.Lesson_Id

            })
            .then(response => {
                setButton(false);
            })
            .catch(error => {
                console.log(error);
            });

            reset();
        }
    }

    return (
        <div>
            <br />
            <div style={{display: 'flex', alignItems: 'center', paddingLeft: 250}}>
                <Typography className={classes.selectLesson}>Selected Lesson</Typography>
                {showLessons()}
            </div>
            <br />
            <div style={{display: 'flex', alignItems: 'center', paddingLeft: 250}}>
                <Typography className={classes.selectClass}>Selected Class</Typography>
                {showClasses()}
            </div>
            <br />
            <div>
                <Button className={classes.controlButtons} disabled={assignButton} variant="contained" color="primary" onClick={() => save()}>Assign</Button>
                <Button className={classes.controlButtons} variant="contained" color="secondary" onClick={() => reset()}>Reset</Button>
            </div>
        </div>
    )
}