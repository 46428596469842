import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

/*

props:
    url -- relative url
    buttonText -- text on the button

The button has -20px style added because the body of the notebook has an additional 40px 
added to prevent stuff from being hidden by the nav bar.

*/

export function PageRedirectButton(props) {
  const { buttonText, url } = props;

  return (
    <Box textAlign="center">
      <br />
      <Button
        sx={{ marginLeft: "-40px" }}
        key={props.fieldKey}
        component={Link}
        to={url}
        variant="contained"
      >
        {buttonText}
      </Button>
    </Box>
  );
}

export default PageRedirectButton;
