import { useState, useRef, useEffect, useContext } from 'react';
import { Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import axios from 'axios';
import store from '../../store';
import { useMutationObserver } from "rooks";
import socket from '../../utils/socket';
import { Button } from './common/Button';
import throttle from "../../utils/Throttle";
import { FullClassContext } from '../../reducers/fullClassContext';
import { Chip, IconButton } from '@mui/material';
import { Note } from './common/Note';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';

const useStyles = makeStyles((theme) => ({
  noPointerEvents: {
    pointerEvents: 'none'
  },
  ideaColor: {
    borderRadius: '36px',
    border: '16.17px solid #E3A920',
    background: '#E3A920',
    boxShadow: '0px 3.23401px 3.23401px 0px rgba(0, 0, 0, 0.25)',
    color: '#fff',
    fontSize: '20px',
    margin: '10px',
    padding: '10px',
    // resize: 'both',
    overflow: 'hidden',
    position: "absolute",
    zIndex: 2
  },
  ideaText: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    textDecorationLine: 'underline',
    fontWeight: '800'
  },
  dragHandle: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: '0 auto',
    bottom: '5px',
    width: '70px',
    height: '15px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    opacity: 0.5,
  }
}));

export default function IdeaSpace({ idea, discussionId, isStudentUserInFullDiscussion, room }) {
  const ideaRef = useRef();
  const classes = useStyles();
  const didMountRef = useRef(false);
  const userId = store.getState().auth.user.id;
  const [state, setState] = useState({
    activeDrags: 0,
    x: idea.x,
    y: idea.y,
    width: idea.width,
    height: idea.height
  });
  const [error, setError] = useState(null);
  const [{ notes }, dispatch] = useContext(FullClassContext);
  const [isMinimized, setIsMinimized] = useState(false);

  const onStart = () => {
    setState({...state, activeDrags: ++state.activeDrags});
  };

  const onDrop = (e, position) => {
    e.preventDefault();
    if (!state.activeDrags) { return }
    const {x, y} = position;
    setState({...state, activeDrags: 0, x, y});
  };

  const observeMutation = mutation => {
    if (mutation[0].type === 'attributes' && mutation[0].attributeName === 'style') {
      let { width, height } = ideaRef.current.style;
      setState({...state, width, height});
    }
  };

  useMutationObserver(ideaRef, throttle(observeMutation, 250));

  useEffect(() => {
    if (didMountRef.current) {
      try {
        axios.post('/api/ideaPositions/save', {
          userId,
          ideaId: idea.ideaId,
          discussionId,
          width: state.width,
          height: state.height,
          x: state.x,
          y: state.y
        });
      } catch (err) { setError(err.response.data) }
    }
    didMountRef.current = true;
    // eslint-disable-next-line
  }, [state.x, state.y, state.width, state.height])

  const handlePopNote = ({x, y, noteId, combined, activeDrags}) => {
    socket.emit(room, {x, y, localId: noteId, vote: '', combined, activeDrags});
    dispatch({
      type: 'UPDATE_NOTE_IDEAID',
      id: noteId,
      payload: ''
    });
    axios.post('/api/notePositions/save', {
      userId,
      noteId,
      discussionId,
      x,
      y,
      ideaId: ''
    });
  }

  return (
    <Draggable
      defaultPosition={{x: idea.x, y: idea.y}}
      onStart={onStart}
      onStop={onDrop}
      handle={'strong'}
      nodeRef={ideaRef}
      disabled={isStudentUserInFullDiscussion}
    >
      <div
        className={`${classes.ideaColor} idea-target ${state.activeDrags ? classes.noPointerEvents : ""}`}
        ref={ideaRef}
        style={{ width: isMinimized ? '200px' : idea.width, height: isMinimized ? '100px' : idea.height }}
        data-id={idea.ideaId}
      >
        <strong className={classes.dragHandle}></strong>
        <IconButton
          sx={{right: '0', top: '0', margin:'0 auto', position: 'absolute', color: "white"}}
          onClick={() => setIsMinimized(min => !min)}
        >
          { isMinimized ?  <MaximizeIcon /> : <MinimizeIcon /> }
        </IconButton>
        <Typography className={classes.ideaText}>theme: {idea.content}</Typography>
        { !isMinimized && <Box sx={{margin: '15px 0', height: '80%', position: 'absolute', overflow: 'scroll'}}>
          <Grid container spacing={1}>
            { notes.filter(note => note.ideaId === idea.ideaId).map((note, index) =>
              <Grid key={index} item xs={12} md={12} xl={12}>
                <Note key={index} onClick={() => handlePopNote(note)}>
                  <div className="text-wrapper">{note.content}</div>
                </Note>
              </Grid>
            )}
          </Grid>
        </Box> }
      </div>
    </Draggable>
  )
}