/*

    TODO:
        (1) Batch adding students
        (2) Ensure only unique entries on table
        (3) Delete from table
        (4) Check for errors during save (unique values)

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';

//column headers for data grid
const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'lname', headerName: 'Last Name', width: 130 },
    { field: 'fname', headerName: 'First Name', width: 130 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'role', headerName: 'Role', width: 130 },
];

const useStyles = makeStyles({
    inputName: {
        marginLeft: 10,
        marginRight: 15,
    },
    inputID: {
        marginLeft: 15,
        marginRight: 33,
    },
    inputTeacher: {
        marginLeft: 18,
        marginRight: 35,
    },
    inputStudent: {
        marginLeft: 15,
        marginRight: 33,
    },
    addButton: {
        marginLeft: 10,
        maxWidth: '30px',
        maxHeight: '30px',
        minWidth: '30px',
        minHeight: '30px',
        backgroundColor: '#275fd9',
        color: '#ffffff',
    },
    controlButtons: {
        marginLeft: 10,
    }
});

export default function ClassroomCreator() {

    const classes = useStyles();

    const [classname, setClassname] = React.useState('');
    const [classId, setClassID] = React.useState('');
    const [teacherList, setTeacherList] = React.useState([]);
    const [studentList, setStudentList] = React.useState([]);
    const [visibleTeacher, setVisibleTeacher] = React.useState(false);
    const [visibleStudent, setVisibleStudent] = React.useState(false);
    const [activeTeacher, setTeacher] = React.useState("");
    const [activeStudent, setStudent] = React.useState("");
    const [teacherRender, setTeacherRender] = React.useState(true);
    const [studentRender, setStudentRender] = React.useState(true);
    const [roster, setRoster] = React.useState([]);
    const [userCount, setUserCount] = React.useState(0);

    //useEffect get teacher list and students
    useEffect(() => {
        axios.post('/api/pagecreate/teacherlist')
        .then((response) => {
            setTeacherList(response.data)
            setVisibleTeacher(true);
        }, (error) => {
            console.log(error);
        });

        axios.post('/api/pagecreate/studentlist')
        .then((response) => {
            setStudentList(response.data);
            setVisibleStudent(true);
        }, (error) => {
            console.log(error);
        });
    }, []);

    //set class name
    const handleClassnameChange = (event) => {
        setClassname(event.target.value);
    }

    //set class id
    const handleClassIDChange = (event) => {
        setClassID(event.target.value);
    }

    //show the autocomplete for teachers
    function showTeachers() {
        if (visibleTeacher) {
            return (
                <div>
                    <Autocomplete
                        key={teacherRender}
                        id="auto-complete-teacher"
                        options={teacherList}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label="Teachers" variant="outlined" size="small" />}
                        onChange={(event, value) => setTeacher(value)}
                    />
                </div>
            )
        }
    }

    //show the autocomplete for students
    function showStudents() {
        if (visibleStudent) {
            return (
                <div>
                    <Autocomplete
                        key={studentRender}
                        id="auto-complete-student"
                        options={studentList}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 250 }}
                        renderInput={(params) => <TextField {...params} label="Students" variant="outlined" size="small" />}
                        onChange={(event, value) => setStudent(value)}
                    />
                </div>
            )
        }
    }

    //add a teacher to the data table
    function addTeacher(value) {
        if (value.name === undefined) {  }
        else {
            var name = value.name.split(" ");
            setTeacherRender(!teacherRender);
            setTeacher("");
            var teacher = createRow(userCount + 1, name[1], name[0], value.email, "Teacher", value._id);
            setRoster([...roster, teacher]);
            setUserCount(userCount + 1);
        }
    }

    //add a student to the data table
    function addStudent(value) {
        if (value.name === undefined) { }
        else {
            var name = value.name.split(" ");
            setStudentRender(!studentRender);
            setStudent("");
            var student = createRow(userCount + 1, name[1], name[0], value.email, "Student", value._id);
            setRoster([...roster, student]);
            setUserCount(userCount + 1);
        }
    }

    //create row for datagrid
    function createRow(studentClassId, last_name, first_name, email_add, user_role, realId) {
        return { id: studentClassId, name: first_name + " " + last_name, lname: last_name, fname: first_name, email: email_add, role: user_role, user_id: realId}
    }

    //reset the values on the page
    function reset() {
        setClassname("");
        setClassID("");
        setUserCount(0);
        setRoster([]);
        setStudentRender(!studentRender);
        setStudent("");
        setTeacherRender(!teacherRender);
        setTeacher("");
    }

    //save all the values on the page
    function save() {
        //filter teachers out
        var allTeachers = roster.filter(x => x.role === "Teacher");
        //remove teacher role since it's redundant at this point
        var cleanTeachers = allTeachers.map(({role, ...item}) => item);
        //filter students out
        var allStudents = roster.filter(x => x.role === "Student");
        //remove student role since it's redundant at this point
        var cleanStudents = allStudents.map(({role, ...item}) => item);
        //save it
        axios.post('/api/pagecreate/savetheclass', {
            name: classname,
            code: classId,
            teachers: cleanTeachers,
            students: cleanStudents
        })
        .then((response) => {
            //console.log(response);
        }, (error) => {
            console.log(error);
        });
        reset();
    }

    return (
        <div>
            <br />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.inputName}>Class Name</Typography>
                <TextField value={classname} id="class-name" label="Class Name" variant="outlined" size="small" onChange={handleClassnameChange} />
            </div>
            <br />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.inputID}>Class ID</Typography>
                <TextField value={classId} id="class-id" label="Class ID" variant="outlined" size="small" onChange={handleClassIDChange} />
            </div>
            <br />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.inputTeacher}>Teacher</Typography>
                {showTeachers()}
                <Button className={classes.addButton} onClick={() => addTeacher(activeTeacher)}>+</Button>
            </div>
            <br />
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Typography className={classes.inputStudent}>Students</Typography>
                {showStudents()}
                <Button className={classes.addButton} onClick={() => addStudent(activeStudent)}>+</Button>
            </div>
            <br />
            <div style={{ height: 375, width: '75%' }}>
                <DataGrid rows={roster} columns={columns} pageSize={5} checkboxSelection />
            </div>
            <br />
            <div>
                <Button className={classes.controlButtons} variant="contained" color="primary" onClick={() => save()}>Save</Button>
                <Button className={classes.controlButtons} variant="contained" color="secondary" onClick={() => reset()}>Reset</Button>
            </div>
        </div>
    )
}