import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Short Answer
      </Typography>
      {props.buttons}
      <TextField
        id="question"
        label="Question"
        value={props.data.question}
        onChange={(event) =>
          props.updateComponent("question", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: {
      answer: "",
    },
    comp: {
      type: "PageShortAnswer",
      props: {
        fieldKey:
          authorId + "-shortans-" + Math.random().toString(36).substr(2, 6),
        question: "",
      },
    },
  };
}

export const PageShortAnswer = { createNew, Form, name: "Short Answer" };

export default PageShortAnswer;
