import Typography from "@material-ui/core/Typography";

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Button with Data Tables
      </Typography>
      {props.buttons}
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "PageTableButtons",
      props: {
        fieldKey:
          authorId + "-tablebuttons-" + Math.random().toString(36).substr(2, 6),
      },
    },
  };
}

export const PageTableButtons = { createNew, Form, name: "Button of Tables (Essay)" };

export default PageTableButtons;
