import styled from 'styled-components';

export const Backpack = styled.div`
  height: ${props => props.open ? "50%" : "20%"};
  width: ${props => props.open ? "270px" : "200px"};
  position: fixed;
  background-color: #66822B;
  border-radius: 57.4px;
  bottom: 25px;
  right: 75px;

  & .sub-title {
    color: #ffffff;
    font-family: "Inter-Bold", Helvetica;
    font-size: 25.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
    margin: 15px 0;
  }
}
`;