import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { withStyles } from '@material-ui/core/styles';

import { loginUser } from "../../actions/authActions";
import { getNavigation } from "../../actions/lessonActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const styles = theme => ({
    loginForm: {
        justifyContent: "center",
        minHeight: "90vh",
    },
    loginBackground: {
        justifyContent: "center",
        minHeight: "30vh",
        padding: 50
    },
    submitButton: {
        width: "100%"
    }
});

class Login extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
        };
    }

    updateEmail = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    updatePassword = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            //this.props.getNavigation({studentId: this.props.auth.user.id});
            this.props.history.push("/dashboard"); //redirect to dashboard on login
        }
    }

    componentDidUpdate() {
        if (this.props.auth.isAuthenticated) {
            //this.props.getNavigation({studentId: this.props.auth.user.id});
            this.props.history.push("/dashboard"); //redirect to dashboard on login
        }
    }

    handleSubmit = e => {
        e.preventDefault();

        const userData = {
            email: this.state.email,
            password: this.state.password
        };

        //This redirect is handled within this component, so it does not need
        //to be passed to this.props.history as a parameter.
        this.props.loginUser(userData);
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={0} justify="center" alignItems="center" direction="column">
                <Grid item>
                    <Grid container direction="column" justify="center" spacing={2} className={classes.loginForm}>
                        <Paper variant="elevation" elevation={2} className={classes.loginBackground}>
                            <Grid item>
                                <Typography component="h1" variant="h5" style={{textAlign: "center"}}>
                                    Sign in
                                </Typography>
                                <br/>
                            </Grid>
                            <Grid item>
                                <form noValidate onSubmit={this.handleSubmit}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField 
                                                type="email"
                                                placeholder="Email"
                                                fullWidth
                                                name="username"
                                                variant="outlined"
                                                value={this.state.email}
                                                onChange={(event) => this.updateEmail(event)}
                                                required
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField 
                                                type="password"
                                                placeholder="Password"
                                                fullWidth
                                                name="password"
                                                variant="outlined"
                                                value={this.state.password}
                                                onChange={(event) => this.updatePassword(event)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary" type="submit" className={classes.submitButton}>
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>

            </Grid>
        )
}
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { loginUser, getNavigation },
) (withStyles(styles)(Login));