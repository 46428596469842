import axios from "axios";

const setAuthToken = token => {
    if (token) {
        //Applying authorization token to every request when logged in
        axios.defaults.headers.common["Authorization"] = token;
    } else {
        //Removing authorization header
        delete axios.defaults.headers.common["Authorization"];
    }
};

export default setAuthToken;