import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

// required props
// props - contains all component data
// updateComponent - callback to update component with new data
// updateAnswer - callback to update answers with new data

function Form(props) {
  return (
    <div>
      <Typography variant="h6" style={{ display: "inline-block" }}>
        Text
      </Typography>
      {props.buttons}
      <TextField
        id="markdown"
        label="Markdown Text"
        value={props.data.markdownText}
        onChange={(event) =>
          props.updateComponent("markdownText", event.target.value)
        }
        placeholder=""
        multiline
        style={{ width: '100%' }}
      />
    </div>
  );
}

function createNew(authorId) {
  return {
    answer: null,
    comp: {
      type: "PageText",
      props: {
        fieldKey: authorId + "-text-" + Math.random().toString(36).substr(2, 6),
        markdownText: "",
      },
    },
  };
}

export const PageText = { createNew, Form, name: "Text" };

export default PageText;
