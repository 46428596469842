/*

    TODO:
    (1) Have the ability to handle a boolean prop for NLP feedback
        -> True: NLP check button / NLP feedback handler
    (2) Save button, helps students feel at ease

    This is a page component for short answers.

    When this component is called, it is expected that props will be passed
    to it. The props are necessary to populate the element.
    

    *Required Props*

    questiontitle: A header that will appear above the question text
    question: The text body of the question, or what is being asked.
    answer: The response to the 'question' prop.
        -> Default answer will be assumed to be "", or blank.
        -> If a student answer exists, it will override the default value.


    Example props.

    questiontitle="Question 1."
    question="This is a test!"
    answer="This is an example answer."

*/

import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import axios from "axios"; 

export function PageShortAnswer(props) {
  const [value, setValue] = useState(props.answers?.answer || "");

  //Function to handle when the user types in the answer
  const onChangeHandler = (event) => {
    setValue(event.target.value);
    props.returnData({ answer: event.target.value }, props.fieldKey);
  };

  // const shortAnswerFeedback = () => {
  //   axios.get("/api/python/shortanswer").then(
  //     (response) => {
  //       console.log(response);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  return (
    <div className="shortans">
      <h6> </h6>
      <Typography>{props.question}</Typography>
      <br />
      <form>
        <TextField
          id="standard-textarea"
          label="Your response"
          placeholder=""
          multiline
          rows={8}
          style={{ width: 800 }}
          value={value}
          onChange={onChangeHandler}
        />
      </form>
      <br />
      <br />
    </div>
  );
}

export default PageShortAnswer;
