import React from "react";
import Fab from "@mui/material/Fab";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
//displaying text
import PageText from "./PageText";
//updated button icon
import { ReactComponent as HelpIcon } from "../../../assets/Help.svg";

export function PageDragTextButton(props) {
  const [showPrompt, setShowPrompt] = React.useState(false);

  function generateButton() {
    //position is hardcoded for design pages, where this is used to show prompts
    return (
      <Box
        sx={{
          position: "fixed",
          top: 120,
          right: 120,
          height: 1,
          transform: "translateZ(0px)",
          flexGrow: 1,
        }}
      >
        <Fab color="primary" onClick={() => setShowPrompt(!showPrompt)}>
          <HelpIcon width={40} height={40} />
        </Fab>
      </Box>
    );
  }

  function generateDraggable() {
    if (showPrompt) {
      return (
        <Draggable defaultPosition={{ x: 200, y: -500 }}>
          <Paper
            elevation={1}
            style={{ width: 400, position: "absolute", zIndex: 1 }}
          >
            <IconButton
              style={{
                float: "right",
                paddingTop: 5,
                paddingBottom: 0,
                paddingRight: 5,
                backgroundColor: "transparent",
              }}
              onClick={() => setShowPrompt(false)}
            >
              <CloseIcon style={{ color: "red" }} />
            </IconButton>
            <br />
            <PageText children={props.children} />
          </Paper>
        </Draggable>
      );
    }
  }

  return (
    <div>
      {generateButton()}
      {generateDraggable()}
    </div>
  );
}

export default PageDragTextButton;
