import React, { useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function lowerHeaders(tableHeaders) {
  let loweredHeaders = [];
  tableHeaders.forEach((headerItem) => {
    loweredHeaders.push({ id: headerItem.toLowerCase() });
  });
  return loweredHeaders;
}

export function PageSimpleResultTable(props) {
  const [headers, setHeaders] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [headerValues, setHeaderValues] = React.useState([]);

  useEffect(() => {
    //get the result table
    axios
      .post("/api/answers/getTable", {
        studentId: props.studentid,
        pageId: props.page,
        classroom: props.classroom,
      })
      .then((res) => {
        setTitle(res.data[0]);
        let dataHeaders = res.data[1];
        setHeaders(dataHeaders);
        let answers = res.data[2].answer;
        let valueHeaders = lowerHeaders(dataHeaders, answers);
        setHeaderValues(valueHeaders);
        let rowData = [];
        //get the row values
        answers.forEach((answer, i) => {
          //lowercase all answer keys
          var key,
            keys = Object.keys(answer);
          var n = keys.length;
          var newObj = {};
          while (n--) {
            key = keys[n];
            newObj[key.toLocaleLowerCase()] = answer[key];
          }
          rowData.push(newObj);
        });
        setRows(rowData);
      });
  }, []);

  function generateHeader() {
    return (
      <TableHead>
        <TableRow>
          {headers.map((header) => {
            return <TableCell>{header}</TableCell>;
          })}
        </TableRow>
      </TableHead>
    );
  }

  /*
                        {row.map((value) => {
                            return (
                                <TableCell align="center">
                                    {value}
                                </TableCell>
                            )
                        })}
                        */

  function generateBody() {
    return (
      <TableBody>
        {rows.map((row, i) => (
          <TableRow
            key={"row" + i}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {headerValues.map((col) => {
              return <TableCell align="center">{row[col.id]}</TableCell>;
            })}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Typography sx={{ paddingLeft: 2 }}>{title} Data</Typography>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {generateHeader()}
        {generateBody()}
      </Table>
    </TableContainer>
  );
}

export default PageSimpleResultTable;